import * as React from 'react'
import { Box, Grid, Paper, Stack, Tab, Tabs } from '@mui/material'
import ClientGrid from '../ClientGrid'
import PaymentGrid from "../PaymentGrid";
import EffortlessPTPageWrapper from "../EffortlessPTPageWrapper";
import SessionGrid from "../SessionGrid";
import { FilterInput } from "../FilterInput";
import { DashboardOverview } from "../dashboard-overview/DashboardOverview";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


interface ButtonPanelProps {
  children?: React.ReactNode
  filterTextState: string
  filterChanged: (filterText: string) => void
}

export function ButtonPanel(props: ButtonPanelProps) {
  const { children, filterTextState, filterChanged } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("filter")) {
      filterChanged(searchParams.get("filter") ?? "");
    }
  }, [searchParams]);

  return (
    <Paper elevation={1}>
      <Stack direction={{ xs: "column", md: "row" }}>
        <Box display="flex" padding={1}>
          {children}
        </Box>
        <Box display="flex"
          paddingTop={{ xs: 0, md: 1 }}
          paddingLeft={{ xs: 2, md: 1 }}
          paddingRight={{ xs: 2, md: 1 }}
          paddingBottom={{ xs: 2, md: 1 }}
          flexGrow={1}>
          <FilterInput id="filter-input-sessions"
            value={filterTextState}
            onChange={(filterTextChange) => {
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("filter", filterTextChange);
              setSearchParams(newSearchParams);
            }}
          />
        </Box>
      </Stack>
    </Paper>
  );
}

function tabIndexFromName(name?: string) {
  if (name) {
    switch (name) {
      case 'sessions':
        return 0;
      case 'clients':
        return 1;
      case 'payments':
        return 2;
      default:
        return 0;
    }
  } else {
    return 0;
  }
}

export default function Dashboard() {
  const { tabName } = useParams();
  const [selectedTab, setSelectedTab] = React.useState(tabIndexFromName(tabName));

// If we use the navigate function to go to this route with a different tabName, it won't update the tab.
  useEffect(() => {
    setSelectedTab(tabIndexFromName(tabName));
  }, [tabName]);

  const navigate = useNavigate();

  const handleTabChanged = (event: React.SyntheticEvent, tabIndex: number) => {
    switch (tabIndex) {
      case 0: {
        navigate("/dashboard/sessions");
        break;
      }
      case 1: {
        navigate("/dashboard/clients");
        break;
      }
      case 2: {
        navigate("/dashboard/payments");
        break;
      }
      default:
        console.error("Unknown tab index: " + tabIndex);
    }
  };
  function tabAccessibilityProps(index: number) {
    return {
      id: `dashboard-tab-${index}`,
      'aria-controls': `dashboard-tabpanel-${index}`,
    };
  }

  return (
    <EffortlessPTPageWrapper title={'Dashboard'} widget={<DashboardOverview />}>
      <Grid container spacing={{ xs: 1, md: 2 }} padding={{ xs: 1, md: 2 }}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectedTab} onChange={handleTabChanged} aria-label="dashboard sections">
              <Tab label="Sessions" {...tabAccessibilityProps(0)} />
              <Tab label="Clients" {...tabAccessibilityProps(1)} />
              <Tab label="Payments" {...tabAccessibilityProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            <SessionGrid />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <ClientGrid />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <PaymentGrid />
          </TabPanel>
        </Grid>
      </Grid>
    </EffortlessPTPageWrapper>
  )
}
