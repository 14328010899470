import * as React from 'react'
import {Button} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import useTimer from "./UseTimer";
import {useState} from "react";

interface CountDownTimerProps {
  intervalSeconds: number;
  onRefresh: () => void;
}

export default function RefreshTimerButton({intervalSeconds, onRefresh}: CountDownTimerProps) {
  const [refreshInSeconds, setRefreshInSeconds] = useState(intervalSeconds)
  useTimer(1000, () => {
    if (refreshInSeconds > 1) {
      setRefreshInSeconds(refreshInSeconds - 1);
    } else {
      onRefresh();
      setRefreshInSeconds(intervalSeconds);
    }
  });
  return (
    <Button onClick={() => {
      setRefreshInSeconds(intervalSeconds);
      onRefresh();
    }} endIcon={<Refresh/>}>Refreshing in {refreshInSeconds}</Button>
  )
}
