import React, { Fragment, useEffect, useState } from "react";
import { ReconciliationPayment } from "./data";
import {
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableFooter,
  TableBody,
  TableHead,
  Typography,
  Grid, Button, Box
} from "@mui/material";
import { formatDate } from "../date";
import { dineroToScaledNumber, formatCurrency, formatNumber } from "../currency";
import { SessionType } from "../../../data/session";
import { useClients, usePayments, useSettings } from "../EffortlessPTContext";
import {
  Add,
  Cancel,
  Check,
  Error,
  Face,
  HelpOutlined,
  Loyalty,
  QuestionAnswerRounded,
  QuestionMark
} from "@mui/icons-material";
import IconMenu from "../IconMenu";
import { useNavigate } from "react-router-dom";
import { expiryDateFromExpiryDays, getPass, getPassFromType } from "../pages/RegisterPayment";
import usePersistentState from "../PersistentStateContext";
import { Payment, PaymentFor } from "../../../data/payment";
import { Dinero } from "dinero.js";
import { Client } from "../../../data/client";

function matchPaymentWithPaymentType(payment: ReconciliationPayment, sessionTypes: Array<SessionType>): ReconciliationPaymentFinalise {
  const paymentAmountNumber = dineroToScaledNumber(payment.amount)
  for (const sessionType of sessionTypes) {
    if (paymentAmountNumber % sessionType.price === 0) {
      const result: ReconciliationPaymentFinalise = {
        finalise: "Yes",
        date: payment.date,
        matchedClient: payment.matchedClient!,
        description: payment.description,
        amount: payment.amount,
        payment: {
          date: payment.date,
          paymentFor: [
            {
              clientId: payment.matchedClient?.id!,
              sessionCount: paymentAmountNumber / sessionType.price,
              sessionTypeId: sessionType!.id,
            }
          ],
          paymentType: "Session",
          amount: paymentAmountNumber,
          method: "EFT"
        }
      }
      return result
    }
    for (const passType of sessionType.passTypes ?? []) {
      if (paymentAmountNumber === passType.price) {
        const result: ReconciliationPaymentFinalise = {
          finalise: "Yes",
          date: payment.date,
          matchedClient: payment.matchedClient!,
          description: payment.description,
          amount: payment.amount,
          payment: {
            date: payment.date,
            paymentFor: [
              {
                clientId: payment.matchedClient?.id!,
                sessionCount: paymentAmountNumber / sessionType.price,
                sessionTypeId: sessionType!.id,
                expiryDate: expiryDateFromExpiryDays(payment.date, passType)
              }
            ],
            paymentType: "Pass",
            pass: getPassFromType(passType),
            amount: paymentAmountNumber,
            method: "EFT"
          }
        }
        return result
      }
    }
  }
  return {
    date: payment.date,
    matchedClient: payment.matchedClient!,
    description: payment.description,
    amount: payment.amount,
    finalise: "Maybe"
  }
}

const getPaymentForString = (reconciliationPayment: ReconciliationPaymentFinalise, lookupClient: (clientId: string) => (Client | null), lookupSessionType: (sessionTypeId: string) => (SessionType | null)) => {
  let result = `Payment`;
  if (reconciliationPayment.payment && reconciliationPayment.payment.paymentFor.length === 1) {
    //TODO: When we implement split payments, handle more than 1 here
    let index = 0
    const paymentFor: PaymentFor = reconciliationPayment.payment.paymentFor[index];
    let client: Client | null = lookupClient(paymentFor.clientId)
    if (client) {
      result += ` for ${client.name}`;
    }
    if (paymentFor.sessionTypeId && paymentFor.sessionCount) {
      const sessionType = lookupSessionType(paymentFor.sessionTypeId);
      let sessionTypeName
      let passTypeName
      if (sessionType) {
        sessionTypeName = sessionType.name
        if (reconciliationPayment.payment.paymentType === "Pass" && reconciliationPayment.payment.pass && sessionType.passTypes) {
          const pass = reconciliationPayment.payment.pass;
          const passType = sessionType.passTypes.find(passType => passType.id === pass.passTypeId)
          passTypeName = passType?.name ?? "Unknown"
        } else {
          passTypeName = "Unknown"
        }
      } else {
        sessionTypeName = "Unknown"
        passTypeName = "Unknown"
      }
      if (reconciliationPayment.payment.paymentType === "Pass") {
        result += ` for a ${sessionTypeName} ${passTypeName}`;
      } else {
        result += ` for ${paymentFor.sessionCount} ${sessionTypeName} session${paymentFor.sessionCount === 1 ? '' : 's'}`;
      }
    }
  }
  return result;
}

type Finalise = "Yes" | "No" | "Maybe";

export interface ReconciliationPaymentFinalise {
  payment?: Payment,

  date: Date,
  description: string,
  matchedClient: Client,
  amount: Dinero<number>,
  finalise: Finalise
}

function getFinaliseIcon(finalise: Finalise) {
  switch (finalise) {
    case "Yes":
      return <Check color='success' />
    case "No":
      return <Cancel color={'error'} />
    case "Maybe":
      return <HelpOutlined />
  }
}

function getFinaliseTitle(finalise: Finalise) {
  switch (finalise) {
    case "Yes":
      return "Included - this payment will be finalised"
    case "No":
      return "Excluded - this payment will not be recorded"
    case "Maybe":
      return "Select an action to finalise this payment by hand"
  }
}

function ActionMenu({ payment, index, onFinaliseChanged }: { payment: ReconciliationPaymentFinalise, index: number, onFinaliseChanged: (finalise: Finalise) => void }) {
  const navigate = useNavigate();
  return <IconMenu
    icon={getFinaliseIcon(payment.finalise)}
    title={getFinaliseTitle(payment.finalise)}
    uniqueKey={`payment-action-${index}`}
    onSelected={(action) => {
      switch (action) {
        case "session": {
          navigate(`/payment/session/${index}`)
          break
        }
        case "pass": {
          navigate(`/payment/pass/${index}`)
          break
        }
        case "exclude": {
          onFinaliseChanged("No")
          break
        }
      }
    }}
    options={[
      {
        name: "Session Payment",
        value: "session",
        description: "Register a session payment",
        icon: <Add />
      },
      {
        name: "Pass Payment",
        value: "pass",
        description: "Register a pass payment",
        icon: <Loyalty />
      },
      {
        name: "Exclude Payment",
        value: "exclude",
        description: "Do not register this payment",
        icon: getFinaliseIcon("No")
      }
    ]}
  />
}

function countFinalisePayments(paymentsToRegister: ReconciliationPaymentFinalise[], finalise: Finalise) {
  return paymentsToRegister.filter(payment => payment.finalise === finalise).length
}

export function RegisterPayments() {
  const [payments] = usePersistentState<ReconciliationPayment[]>("reconcileBankStatement.payments");
  const [paymentsToRegister, setPaymentsToRegister] = usePersistentState<ReconciliationPaymentFinalise[]>("reconcileBankStatement.paymentsToRegister", [])
  const { settings, lookupSessionType } = useSettings()
  const { lookupClient } = useClients()

  useEffect(() => {
    if (paymentsToRegister && paymentsToRegister.length > 0) {
      //There are already paymentsToRegister. Do not recreate them because they can have state set by the user.
    } else {
      if (settings.sessionTypes.length > 0) {
        const reconciliationPaymentsFiltered = payments
          .filter(payment => {
            return payment.shouldCreatePayment
          })
          .map(payment => matchPaymentWithPaymentType(payment, settings.sessionTypes))

        setPaymentsToRegister(reconciliationPaymentsFiltered)
      }
    }
  }, [payments, settings.sessionTypes])


  return <Grid container>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentsToRegister.map((payment, index) => {
              return <TableRow key={index}>
                <TableCell>{formatDate(payment.date, true)}</TableCell>
                <TableCell>
                  <p>{getPaymentForString(payment, lookupClient, lookupSessionType)}</p>
                  <p><i>{payment.description}</i></p>
                </TableCell>
                <TableCell>{formatCurrency(payment.amount)}</TableCell>
                <TableCell>
                  <ActionMenu payment={payment} index={index} onFinaliseChanged={(finalise) => {
                    const newPayments = [...paymentsToRegister]
                    newPayments[index].finalise = finalise
                    setPaymentsToRegister(newPayments)
                  }} />
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
    <Typography>{`Click Finish to commit ${countFinalisePayments(paymentsToRegister, "Yes")} payment${countFinalisePayments(paymentsToRegister, "Yes") === 1 ? "" : "s"}.`}</Typography>
  </Grid>
}

export function RegisterPaymentsButton({ text, onDone }: { text: string, onDone: () => void }) {
  const { registerPayment } = usePayments()

  const [paymentsToRegister] = usePersistentState<ReconciliationPaymentFinalise[]>("reconcileBankStatement.paymentsToRegister", [])
  const [isRegisteringPayments, setIsRegisteringPayments] = useState(false)
  const [allPaymentsRegistered, setAllPaymentsRegistered] = useState(false)

  function handleRegisterPayments() {
    try {
      setIsRegisteringPayments(true)
      let countToRegister = 0
      for (const reconciliationPaymentFinalise of paymentsToRegister) {
        if (reconciliationPaymentFinalise.finalise === "Yes") {
          if (reconciliationPaymentFinalise.payment) {
            countToRegister++
            registerPayment(reconciliationPaymentFinalise.payment, result => {
              countToRegister--;
              if (countToRegister === 0) {
                setIsRegisteringPayments(false)
                setAllPaymentsRegistered(true)
                onDone();
              }
            }, console.error) //TODO: Handle errors
          } else {
            console.error('Payment set to finalise but had no payment object. Should never happen.', reconciliationPaymentFinalise) //TODO: Handle this better
          }
        }
      }
    } catch (e) {
      setIsRegisteringPayments(false)
    }
  }

  return (
    <Button variant='contained' color='primary'
      disabled={isRegisteringPayments || paymentsToRegister.length === 0 || countFinalisePayments(paymentsToRegister, "Maybe") > 0}
      onClick={handleRegisterPayments}>{text}</Button>
  )
}
