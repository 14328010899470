import {IconButton, TextField} from "@mui/material";
import {ChangeEvent} from "react";
import {FilterAlt} from "@mui/icons-material";
import * as React from "react";

export function FilterInput({id, value, onChange}: {
  id: string
  value: string
  onChange: (filterText: string) => void
}) {
  return <TextField
    fullWidth
    id={id}
    variant="standard"
    value={value}
    placeholder={"Filter"}
    autoComplete={"off"}
    onChange={
      (change: ChangeEvent<HTMLInputElement>) => {
        const filterTextChange = change.target.value as string
        onChange(filterTextChange)
      }
    }
    InputProps={{
      endAdornment: (
        <IconButton aria-hidden={true} disabled={true}>
          <FilterAlt/>
        </IconButton>
      ),
    }}
  />;
}
