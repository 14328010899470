import {useAuth0} from '@auth0/auth0-react'

export interface ApiDescription {
  getAccessToken: () => Promise<string>;
  url: string;
  method: 'GET' | 'POST' | 'DELETE';
}

export function useApi(url: string, method: 'GET' | 'POST' | 'DELETE'): ApiDescription {
  const {getAccessTokenSilently} = useAuth0()
  return {
    getAccessToken: getAccessTokenSilently,
    url,
    method
  }
}

export interface ApiError {
  errorCode: number;
  message: string;
}

export function isApiError(object: any): object is ApiError {
  return object && object.errorCode !== undefined && object.message !== undefined
}

function jsonReviver(key: string, value: any) {
  if (key.endsWith("date") || key.endsWith("Date") || key.startsWith("date") || key.startsWith("Date")) {
    try {
      return new Date(value)
    } catch (err) {
      //TODO: report errors and replace console error
      console.error(err)
      return value
    }
  } else {
    return value
  }
}

const parseResponse = <T>(responseBody: string): T | ApiError => {
  try {
    const result: T = JSON.parse(responseBody, jsonReviver);
    return result;
  } catch (err) {
    //TODO: report errors and replace console error
    console.error(err)
    return {
      errorCode: -1,
      message: 'JSON parsing error.'
    };
  }
}

const delay = async (timeMillis: number) => {
  await new Promise(resolve => setTimeout(resolve, timeMillis));
}

const MAX_RETRIES = 3;
const INITIAL_DELAY_MILLIS = 500;
const BACKOFF_MULTIPLIER = 3;

/**
 *  Call an API and return either the expected result data or an ApiError.
 *
 * @param api The API to call.
 * @param fetchOptions Options for the fetch request.
 * @param queryParams Query params in the form 'a=b&c=d' (I.E. without the ?)
 * @param useAccessToken True to call an authenticated API.
 */
// eslint-disable-next-line no-undef
export async function callApi<T>(api: ApiDescription, fetchOptions: RequestInit = {}, queryParams: string = '', useAccessToken: boolean = true): Promise<T | ApiError> {
  let tries = 0;
  let delayMillis = INITIAL_DELAY_MILLIS;
  while (tries <= MAX_RETRIES) {
    tries++;
    if (tries > 1) {
      //TODO: replace console error with error reporting:
      console.error(`Retrying ${api.url} request in ${delayMillis} milliseconds...`)
      await delay(delayMillis);
      delayMillis *= BACKOFF_MULTIPLIER;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}${api.url}?${queryParams}`, {
        ...fetchOptions,
        method: api.method,
        headers: {
          ...fetchOptions.headers,
          Authorization: useAccessToken ? `Bearer ${await api.getAccessToken()}` : ''
        }
      })
      let responseBody = await response.text();
      if (response.ok) {
        return parseResponse(responseBody);
      } else if (response.status === 401 || (response.status >= 500 && response.status < 600)) {
        //allow retries to occur on a 401 or 5xx
        //TODO: report errors and replace console error
        console.error(JSON.stringify(response));
      } else {
        //TODO: report errors and replace console error
        console.error(JSON.stringify(response));
        return {
          errorCode: response.status,
          message: responseBody
        }
      }
    } catch (err: any) {
      //TODO: report errors and replace console error
      console.error(err);
      return {
        errorCode: err.status as number,
        message: err.message as string
      }
    }
  }
  let message = "Retries exceeded.";
  //TODO: report errors and replace console error
  console.error(message);
  return {
    errorCode: -2,
    message: message
  }
}
