import { Box, Typography } from '@mui/material';
import * as React from 'react';

export default function ReconciliationIntroduction() {
    return (
        <Box>
            <Typography sx={{ mt: 2, mb: 1 }
            }> To export payment transactions from your bank to
                process: </Typography>
            < ul >
                <li>Go to your bank&apos;s Internet Banking application </li>
                <li> Navigate to the account you receive client payments to </li>
                <li > Use the Export feature to download a CSV format file(for example if you use ANZ you can
                    follow these instructions: <a
                        href={"https://help.anz.co.nz/app/answers/detail/a_id/767/~/exporting-transaction-history"}> ANZ
                        Instructions </a>)
                </li>
                <li>Make a note of where you saved the file - you&apos;ll need it in the Next step</li>
            </ul>
        </Box>
    )
}
