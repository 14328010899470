import { ClickAwayListener, Stack, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { EditableDate } from './pages/EditableDate';
import { Session } from '../../data/session';
import { formatDate } from './date';
import { useSettings } from './EffortlessPTContext';

export default function DateHeading({ session, changeDate }: {
    session: Session;
    changeDate: (newDate: Date, success: () => void) => void;
}) {
    const { lookupSessionType } = useSettings();

    const [isEditingDate, setIsEditingDate] = useState(false);
    const [callingEditDateApi, setCallingEditDateApi] = useState(false);

    const [editedDate, setEditedDate] = useState<Date | null>(null);

    return isEditingDate
        ? <ClickAwayListener onClickAway={
            () => {
                if (!callingEditDateApi) {
                    setIsEditingDate(false);
                }
            }
        }>
            <Stack direction="row" spacing={2}>
                <EditableDate autoFocus value={editedDate ?? session.date} onChange={setEditedDate} includeTime onKeyDown={event => {
                    if (event.key === "Escape") {
                        setIsEditingDate(false);
                    }
                }} />
                <Button
                    variant="outlined"
                    onClick={() => setIsEditingDate(false)}>Cancel</Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={callingEditDateApi || !editedDate}
                    onClick={() => {
                        setCallingEditDateApi(true);
                        changeDate(editedDate!, ()=> {
                            setIsEditingDate(false);
                            setCallingEditDateApi(false);
                        });
                    }}>Save</Button>
            </Stack>
        </ClickAwayListener >
        : <h2>
            <Typography
                component="span"
                sx={{
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    cursor: 'pointer',
                    color: 'inherit',
                    textDecoration: 'none',
                    '&:hover': {
                        color: 'blue',
                        textDecoration: 'underline'
                    }
                }}
                onClick={() => {
                    setIsEditingDate(true);
                    setEditedDate(session.date);
                }}
            >{`${formatDate(session.date)}`}</Typography> - {lookupSessionType(session.sessionTypeId)?.name}
        </h2>
}
