import * as React from 'react'
import { Typography } from '@mui/material'

export default function NoPage () {
  return (
        <Typography>
            Page not found!
        </Typography>
  )
}
