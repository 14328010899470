import * as React from 'react'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {Button, Paper, TableBody, TableFooter} from '@mui/material'

export interface TableColumn<T> {
  name: string;
  headGetter?: (context: any) => any;
  fieldName?: string;
  getter?: (row: T, rows: T[], setRows: (rows: T[]) => void, context: any) => any;
  value?: any;
  width?: number;
  removePadding?: boolean;
}

function getCellValue<T extends Object>(column: TableColumn<T>, row: T, rows: T[], rowSetter: (row: T[]) => void, context: any) {
  let result;
  if (column.getter) {
    result = column.getter(row, rows, rowSetter, context);
  } else if (column.fieldName && column.fieldName in row) {
    result = (row as any)[column.fieldName];
  } else if (column.value) {
    result = column.value;
  } else {
    result = `Missing fieldName, getter or value for column ${column.name}`;
  }
  // Handle the Date type using en-AU locale.
  if (result instanceof Date) {
    result = result.toLocaleString();
  }
  return result;
}


function getCellHeadValue<T>(column: TableColumn<T>, context: any) {
  let result;
  if (column.headGetter) {
    result = column.headGetter(context);
  } else {
    result = column.name;
  }
  return result;
}

function defaultRenderTableContents(headerRow: React.ReactNode, bodyRows: React.ReactNode) {
  return <React.Fragment>
    <TableHead>
      {headerRow}
    </TableHead>
    <TableBody>
      {bodyRows}
    </TableBody>
  </React.Fragment>
}

export default function DataTable<T extends Object>(props: {
  name: string;
  columns: TableColumn<T>[];
  rows: T[];
  context?: any;
  onLoad?: (rows: T[], rowSetter: (rows: T[]) => void) => void;
  renderTableContents?: (headerRow: React.ReactNode, bodyRows: React.ReactNode) => React.ReactNode;
  hasMore?: boolean
  onMore?: (filterText?: string)=>void
}) {
  const { name, columns, rows, context, onLoad, renderTableContents = defaultRenderTableContents, hasMore = false, onMore = () => null } = props;
  const [rowState, rowSetter] = React.useState(rows);
  React.useEffect(() => rowSetter(rows), [rows]);
  if (onLoad) {
    onLoad(rowState, rowSetter);
  }
  return (
    <TableContainer component={Paper} style={{ minHeight: '300px', width: '100%' }}>
      <table aria-label={name} style={{ width: '100%' }}>
        {renderTableContents((
          <TableRow>
            {columns.map(column => (
              <TableCell padding={column.removePadding ? 'none' : undefined} width={column.width} key={column.name}>{getCellHeadValue(column, context ?? {})}</TableCell>
            ))}
          </TableRow>
        ), rowState.map((row, index) => (
          // TODO: maybe use an id from the row here instead of the index. The index is not recommended by react.
          <TableRow key={index}>
            {columns.map(column => (
              <TableCell padding={column.removePadding ? 'none' : undefined} width={column.width} key={column.name}>
                {getCellValue(column, row, rowState, rowSetter, context ?? {})}
              </TableCell>
            ))}
          </TableRow>
        ))
        )}
        <TableFooter>
        {hasMore &&
            <TableRow>
            <TableCell colSpan={columns.length} align={"center"}>
                <Button onClick={()=>{
                  onMore()
                }}>Load More</Button>
            </TableCell>
            </TableRow>
        }
        </TableFooter>
      </table>
    </TableContainer>
  )
}
