import * as React from 'react';
import { useState } from 'react';
import { CsvDragDrop } from '../CsvDragDrop';
import { CsvFile } from '../CsvFile';
import usePersistentState from '../PersistentStateContext';

export default function BankStatementCsv() {
    const [csvFile, setCsvFile] = usePersistentState<CsvFile>("reconcileBankStatement.csvFile");
    return (
        < CsvDragDrop csvFile={csvFile} onLoad={setCsvFile} onError={console.error}
            onReset={() => setCsvFile(null)} />
    )
}
