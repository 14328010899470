import React, { ChangeEventHandler, useEffect, useState } from "react";
import {
  Button,
  Box,
  Stack,
  Typography,
  useTheme,
  Grid,
  FormControl,
  InputLabel,
  Input,
  FormHelperText, Autocomplete, TextField, InputAdornment, FormLabel, RadioGroup, Radio, FormControlLabel
} from "@mui/material";
import { useClients, usePayments, useSettings } from "../EffortlessPTContext";
import { SessionType } from "../../../data/session";
import { Client } from "../../../data/client";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentMethod, PaymentType } from "../../../data/payment";
import { ApiError } from "../UseApi";
import EffortlessPTPageWrapper from "../EffortlessPTPageWrapper";
import {
  CURRENCY_REGEX,
  dineroFromScaledNumber,
  dineroFromString,
  dineroToScaledNumber,
  formatCurrency,
  formatNumber
} from "../currency";
import { Dinero, multiply } from "dinero.js";
import { Pass, PassType } from "../../../data/pass";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import usePersistentState from "../PersistentStateContext";
import { ReconciliationPaymentFinalise } from "../reconcile-bank-transactions/RegisterPayments";

const NUMERIC_REGEX = /^\d+$/;

function onChangeValidate<T>(stateSetter: (val: string) => void, regExp: RegExp): ChangeEventHandler<HTMLInputElement> {
  return (event) => {
    const value = event.target.value;
    if (value === "") {
      stateSetter("");
    } else {
      if (!regExp.test(value)) {
        return;
      }
      stateSetter(value);
    }
  };
}

export function expiryDateFromExpiryDays(paymentDate: Date, passType: PassType) {
  return new Date(paymentDate.valueOf() + passType.expiresAfterDays * 24 * 60 * 60 * 1000);
}

export function getPassFromType(passType: PassType): Pass {
  return { passTypeId: passType.id };
}

export function getPass(paymentType: "Session" | "Pass", passType: PassType | null | undefined) {
  return paymentType === "Pass" && passType ? getPassFromType(passType) : undefined;
}

export default function RegisterPayment() {
  const { paymentTypePath, paymentIndexPath } = useParams();
  let theme = useTheme()
  let navigate = useNavigate()
  const { registerPayment } = usePayments()
  const [paymentsToRegister, setPaymentsToRegister] = usePersistentState<ReconciliationPaymentFinalise[]>("reconcileBankStatement.paymentsToRegister", [])

  let paymentType: PaymentType
  if (paymentTypePath) {
    if (paymentTypePath === "pass") {
      paymentType = "Pass"
    } else {
      paymentType = "Session"
    }
  } else {
    paymentType = "Session"
  }
  const { clients } = useClients()

  const { settings } = useSettings()
  const sessionTypes = paymentType === "Session" ? settings.sessionTypes : settings.sessionTypes.filter(sessionType => sessionType.passTypes && sessionType.passTypes.length > 0);
  const [sessionType, setSessionType] = useState<SessionType | null>(null)
  const [sessionTypeHelp, setSessionTypeHelp] = useState<string>("")

  const [passType, setPassType] = useState<PassType | null>(null)
  const [passTypes, setPassTypes] = useState<PassType[]>([])
  const [passTypeHelp, setPassTypeHelp] = useState<string>("select a pass type")


  useEffect(() => {
    if (settings) {
      const initialSessionType = settings.sessionTypes && settings.sessionTypes.length > 1 ? null : settings.sessionTypes[0]
      if (initialSessionType !== undefined) {
        setSessionType(initialSessionType)

        if (paymentType === "Pass" && initialSessionType && initialSessionType.passTypes) {
          if (initialSessionType.passTypes.length === 1) {
            setPassType(initialSessionType.passTypes[0])
          } else {
            setPassType(null)
          }
        }
      }
    }
  }, [settings])

  let bankReconciliationInProgress = false
  let bankReconciliationIndex = -1
  let initialDate: Date = new Date()
  let initialFixedAmount: boolean = false
  let initialAmount: string = ""
  let initialClient: Client | null = null
  let initialPaymentDateDisabled = false
  let initialPaymentMethodDisabled = false
  let initialClientDropdownDisabled = false
  if (paymentIndexPath) {
    try {
      bankReconciliationIndex = Number(paymentIndexPath)
      const reconciliationPaymentFinalise = paymentsToRegister[bankReconciliationIndex];
      initialDate = reconciliationPaymentFinalise.date

      const fixedAmountDinero = reconciliationPaymentFinalise.amount
      initialFixedAmount = true
      initialAmount = formatNumber(fixedAmountDinero)

      initialClient = reconciliationPaymentFinalise.matchedClient

      initialPaymentDateDisabled = true
      initialPaymentMethodDisabled = true
      initialClientDropdownDisabled = true
      bankReconciliationInProgress = true

    } catch (e) {
      //TODO: Handle this better
      console.error(e);
    }
  }

  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [paymentDate, setPaymentDate] = useState<Date>(initialDate);
  useEffect(() => {
    if (paymentDate && passType) {
      setExpiryDate(expiryDateFromExpiryDays(paymentDate, passType))
    }
  }, [passType, paymentDate])

  const [paymentDateDisabled, setPaymentDateDisabled] = useState(initialPaymentDateDisabled)
  const [paymentMethodDisabled, setPaymentMethodDisabled] = useState(initialPaymentMethodDisabled)
  const [clientDropdownDisabled, setClientDropdownDisabled] = useState(initialClientDropdownDisabled)

  const [error, setError] = useState<ApiError | null>(null)
  const [client, setClient] = useState<Client | null>(initialClient)

  const [numberOfSessions, setNumberOfSessions] = useState<string>("")
  const [amount, setAmount] = useState<string>(initialAmount)
  const [fixedAmount, setFixedAmount] = useState<boolean>(initialFixedAmount)

  const [amountCustomized, setAmountCustomized] = useState<boolean>(false)
  const defaultAmountHelpStatement = "the amount that was paid"
  const [amountHelpStatement, setAmountHelpStatement] = useState<string>(defaultAmountHelpStatement)
  const [method, setMethod] = useState<PaymentMethod>("EFT")

  useEffect(() => {
    if (!sessionType) {
      setSessionTypeHelp("select the session type");
    } else {
      if (paymentType === "Session") {
        const sessionPriceDinero = dineroFromScaledNumber(sessionType.price);
        const sessionPriceFormatted = formatCurrency(sessionPriceDinero);
        setSessionTypeHelp(`the client attended a ${sessionType.name} (price ${sessionPriceFormatted})`);
      } else if (paymentType === "Pass" && sessionType.passTypes && sessionType.passTypes.length > 0) {
        setSessionTypeHelp(`the client bought a ${sessionType.name} Pass`);
        setPassTypes(sessionType.passTypes)
        if (sessionType.passTypes.length === 1) {
          setPassType(sessionType.passTypes[0])
        }
      }
    }
  }, [sessionType])

  useEffect(() => {
    function allFieldsFilled() {
      if (paymentType === "Session" && numberOfSessions === "") {
        return false
      } else if (paymentType === "Pass" && passType === null) {
        return false
      }
      return sessionType !== null
    }

    if (!allFieldsFilled()) {
      setAmountHelpStatement(defaultAmountHelpStatement);
      if (!fixedAmount && !amountCustomized) {
        setAmount("")
      }
    } else {
      if (paymentType === "Session") {
        const numberOfSessionsNumeric = Number(numberOfSessions);
        const sessionPriceDinero = dineroFromScaledNumber(sessionType!.price);
        const calculatedAmount = multiply(sessionPriceDinero, numberOfSessionsNumeric);
        const calculatedAmountFormatted = formatNumber(calculatedAmount);
        if (!fixedAmount && !amountCustomized) {
          setAmount(calculatedAmountFormatted)
          setAmountHelpStatement(`amount calculated by session price ${formatCurrency(sessionPriceDinero)} * ${numberOfSessionsNumeric} session${numberOfSessionsNumeric === 1 ? '' : 's'}`);
        } else {
          //set helper text indicating the difference between the calculated amount and the overridden amount
          setAmountHelpStatement(`${fixedAmount ? "fixed" : "manual"} payment amount (calculated session price ${formatCurrency(sessionPriceDinero)} * ${numberOfSessionsNumeric} session${numberOfSessionsNumeric === 1 ? '' : 's'} = ${calculatedAmountFormatted})`);
        }
      } else if (paymentType === "Pass") {
        if (passType && passType.price) {
          const passPriceDinero = dineroFromScaledNumber(passType.price)
          const passPriceFormatted = formatNumber(passPriceDinero)
          if (!fixedAmount && !amountCustomized) {
            setAmount(passPriceFormatted)
            setAmountHelpStatement(`pass price ${formatCurrency(passPriceDinero)}`)
          } else {
            //set helper text indicating the difference between the calculated amount and the overridden amount
            setAmountHelpStatement(`${fixedAmount ? "fixed" : "manual"} payment amount (original pass price ${formatCurrency(passPriceDinero)})`);
          }
        }
      }
    }
  }, [sessionType, numberOfSessions, amountCustomized, amount, fixedAmount, passType]) // <-- here put the parameter to listen

  const [clientError, setClientError] = useState<boolean>(false)
  const [sessionTypeError, setSessionTypeError] = useState<boolean>(false)
  const [numberOfSessionsError, setNumberOfSessionsError] = useState<boolean>(false)
  const [amountError, setAmountError] = useState<boolean>(false)
  const [passTypeError, setPassTypeError] = useState<boolean>(false)
  const [expiryDateError, setExpiryDateError] = useState<boolean>(false)

  function submitForm($event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) {
    $event.preventDefault()
    let errorsPresent = false
    if (!client) {
      setClientError(true)
      errorsPresent = true
    } else {
      setClientError(false)
    }
    if (paymentType === "Session" && !numberOfSessions) {
      setNumberOfSessionsError(true)
      errorsPresent = true
    } else {
      setNumberOfSessionsError(false)
    }
    if (paymentType === "Pass" && !passType) {
      setPassTypeError(true)
      errorsPresent = true
    } else {
      setPassTypeError(false)
    }
    if (paymentType === "Pass" && !expiryDate) {
      setExpiryDateError(true)
      errorsPresent = true
    } else {
      setExpiryDateError(false)
    }
    if (!sessionType) {
      setSessionTypeError(true)
      errorsPresent = true
    } else {
      setSessionTypeError(false)
    }
    if (!amount || amount.endsWith(".")) {
      setAmountError(true)
      errorsPresent = true
    } else {
      setAmountError(false)
    }
    let amountDinero: Dinero<number>;
    try {
      amountDinero = dineroFromString(amount);
      setAmountError(false)
    } catch (e) {
      setAmountError(true)
      errorsPresent = true
    }
    if (errorsPresent) {
      return
    }

    let sessionCount
    if (paymentType == "Pass") {
      sessionCount = Number(passType?.sessionCount)
    } else {
      sessionCount = Number(numberOfSessions)
    }

    const pass: Pass | undefined = getPass(paymentType, passType);
    const payment = {
      paymentFor: [
        {
          clientId: client?.id!,
          sessionCount: sessionCount,
          sessionTypeId: sessionType!.id,
          expiryDate: expiryDate ? expiryDate : undefined
        }
      ],
      paymentType: paymentType,
      pass: pass,
      date: paymentDate,
      amount: dineroToScaledNumber(amountDinero!),
      method: method
    }
    if (bankReconciliationInProgress) {
      setPaymentsToRegister(paymentsToRegister.map((paymentToRegister, index) => {
        if (index === bankReconciliationIndex) {
          let result = paymentToRegister
          result.payment = payment
          result.finalise = "Yes"
          return result
        } else {
          return paymentToRegister
        }
      }))
      navigate(-1)
    } else {
      registerPayment(payment, result => navigate(-1), setError)
    }
  }

  return (
    <EffortlessPTPageWrapper title={`${paymentType} Payment`}>
      <form>
        {error && <Typography>{`An error occurred: \n${JSON.stringify(error, null, 2)}`}</Typography>}
        <Grid container spacing={2} padding={1}>
          <Grid item xs={12}>
            <Typography color={theme.palette.text.secondary}>Enter client and payment details:</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete options={clients}
              disabled={clientDropdownDisabled}
              value={client}
              getOptionLabel={(client) => client.name}
              renderInput={(params) => <TextField
                {...params}
                id="client"
                name="client"
                label="Client"
                aria-describedby="client-text"
                variant="standard"
                error={clientError}
                required
              />}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              onChange={(event, newValue) => {
                setClient(newValue!);
              }}

            />
            <FormHelperText id="client-text">{`the client whose ${paymentType === "Session" ? "sessions are" : "pass is"} being paid for`}</FormHelperText>
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete options={sessionTypes}
              value={sessionType}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField
                {...params}
                id="session-type"
                name="session-type"
                label="Session Type"
                aria-describedby="session-type-text"
                variant="standard"
                error={sessionTypeError}
                required
              />}
              onChange={(event, newValue) => {
                setSessionType(newValue);
              }}
            />
            <FormHelperText id="session-type-text">{sessionTypeHelp}</FormHelperText>
          </Grid>
          {paymentType === "Session" &&
            <Grid item xs={12} md={4}>
              <FormControl fullWidth={true} variant={'standard'}>
                <InputLabel htmlFor="number-of-sessions">Number of Sessions</InputLabel>
                <Input
                  id="number-of-sessions"
                  name="number-of-sessions"
                  aria-describedby="number-of-sessions-text"
                  required
                  autoComplete={"off"}
                  value={numberOfSessions}
                  error={numberOfSessionsError}
                  onChange={onChangeValidate(setNumberOfSessions, NUMERIC_REGEX)}
                />
                <FormHelperText id="number-of-sessions-text">the number of sessions being paid for</FormHelperText>
              </FormControl>
            </Grid>
          }
          {paymentType === "Pass" &&
            <Grid item xs={12} md={4}>
              <Autocomplete options={passTypes}
                value={passType}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField
                  {...params}
                  id="pass-type"
                  name="pass-type"
                  label="Pass Type"
                  aria-describedby="pass-type-text"
                  variant="standard"
                  error={passTypeError}
                  required
                />}
                onChange={(event, newValue) => {
                  setPassType(newValue);
                }}
              />
              <FormHelperText id="pass-type-text">{passTypeHelp}</FormHelperText>
            </Grid>
          }
          <Grid item xs={12}>
            <Typography color={theme.palette.text.secondary}>Enter payment details:</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth={true} variant={'standard'}>
              <InputLabel htmlFor="amount">Amount</InputLabel>
              <Input id="amount"
                name={"amount"}
                aria-describedby="amount-text"
                required={true}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                autoComplete="off"
                value={amount}
                error={amountError}
                disabled={fixedAmount}
                onChange={onChangeValidate((amount) => {
                  setAmount(amount);
                  setAmountCustomized(true);
                }, CURRENCY_REGEX)}
              />
              <FormHelperText id="amount-text">{amountHelpStatement}{amountCustomized && <Box component={'span'}>&nbsp;<a href={"#"} onClick={(event) => { event.preventDefault(); setAmountCustomized(false) }}>reset</a></Box>}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl disabled={paymentMethodDisabled}>
              <FormLabel id="payment-method-label" style={{ fontSize: '12px' }}>Payment Method</FormLabel>
              <RadioGroup
                aria-labelledby="payment-method-label"
                name="payment-method"
                value={method}
                defaultValue={"EFT"}
                onChange={(event, newValue) => {
                  setMethod(newValue as PaymentMethod);
                }}
                row={true}
              >
                <FormControlLabel value="EFT" control={<Radio />} label="EFT" />
                <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                  label="Payment Date"
                  disabled={paymentDateDisabled}
                  openTo="day"
                  format="dd/MM/yyyy"
                  views={['year', 'month', 'day']}
                  value={paymentDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setPaymentDate(newValue);
                    }
                  }}
                  slotProps={{ textField: { variant: 'standard', disabled: paymentDateDisabled } }}
                  enableAccessibleFieldDOMStructure
              />
            </LocalizationProvider>
          </FormControl>
            &nbsp;
            &nbsp;
          {paymentType === "Pass" &&
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Expiry Date"
                  openTo="day"
                  format="dd/MM/yyyy"
                  views={['year', 'month', 'day']}
                  value={expiryDate}
                  onChange={(newValue) => {
                    setExpiryDate(newValue);
                  }}
                  slotProps={{ textField: { variant: 'standard' } }}
                  enableAccessibleFieldDOMStructure
                />
              </LocalizationProvider>
            </FormControl>
          }
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Stack direction={"row"} spacing={1}>
                <Button variant={'outlined'} onClick={() => navigate(-1)}>Cancel</Button>
                <Button type={"submit"} variant={'contained'} onClick={submitForm}>Save</Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </form>
    </EffortlessPTPageWrapper>
  );
}

