import * as React from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TableBody,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import {useClients, usePayments, useSessions, useSettings} from "../EffortlessPTContext";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Fragment, useCallback, useEffect, useState} from "react";
import {ApiError} from "../UseApi";
import {PassPayment} from "../../../data/payment";
import {formatDate, getSessionDate} from '../date';
import EffortlessPTPageWrapper from "../EffortlessPTPageWrapper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { dineroFromScaledNumber, formatCurrency } from "../currency";
import { Client } from "../../../data/client";
import { Settings } from "../../../data/settings";
import {Close, Delete, Done, WarningOutlined} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";
import TrainerLogo from '../TrainerLogo';
import {SessionPayment} from "../../../data/session-payment";
import { EditableDate } from './EditableDate';
import {Session} from "../../../data/session";

const OverdueSession = ({ sessionDate, sessionId }: {sessionDate: Date, sessionId: string}) => {
  const navigate = useNavigate();
  return <Button size="small" color="primary" onClick={() => navigate('/session-details/' + sessionId)}>
          <Avatar sx={{backgroundColor: red[400] }} aria-hidden>
            <Close />
          </Avatar>
          <Typography fontSize={"14px"}>
            &nbsp;{formatDate(sessionDate, true)}
          </Typography>
        </Button>;
};


function PaymentDetailsGrid({
  payment,
  clients,
  settings
}: { payment: PassPayment, clients: Client[], settings: Settings }) {
  return <TableContainer component={Paper} style={{ minHeight: '300px', width: '100%' }}>
    <table aria-label={"Payment Details"} style={{ width: '100%' }}>
      <TableBody>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>{formatDate(payment.date, true)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Amount</TableCell>
          <TableCell>{payment.amount && Number.isInteger(payment.amount) ? formatCurrency(dineroFromScaledNumber(payment.amount)) : ''}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>{payment.paymentType}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={2}>
            <Typography fontWeight={"bold"}>Paying for</Typography>
          </TableCell>
        </TableRow>
        {payment.paymentFor && payment.paymentFor.map((paymentFor, index) => {
          const client = clients.find(client => client.id === paymentFor.clientId)
          let clientSuffix
          if (client) {
            clientSuffix = `, for client ${client.name}`
          } else {
            clientSuffix = ''
          }
          const sessionType = settings.sessionTypes.find(sessionType => sessionType.id === paymentFor.sessionTypeId)
          let sessionSuffix
          if (sessionType) {
            sessionSuffix = `, of session type ${sessionType.name}`
          } else {
            sessionSuffix = ''
          }
          let sessionsAttended = 0
          payment.sessionPayments.forEach(sessionPayment => {
            if (sessionPayment.dateAttended) {
              sessionsAttended++
            }
          })
          return <TableRow key={index}>
            <TableCell colSpan={2}>
              <Typography>{`${paymentFor.sessionCount} sessions${sessionSuffix}${clientSuffix} (attended ${sessionsAttended} session${sessionsAttended === 1 ? '' : 's'} so far)`}</Typography>
            </TableCell>
          </TableRow>
        }
        )}
      </TableBody>
    </table>
  </TableContainer>
}

const PassCardItem = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));


function shrinkNameIfNecessary(name: string) {
    const maxCharacters = 14;
    const nameParts = name.split(' ');
    let totalLength = nameParts.reduce((length, part) => length + part.length, 0) + nameParts.length - 1;
    let nextPartToShrink = nameParts.length - 1;
    while (totalLength > maxCharacters && nextPartToShrink >= 0) {
        // Replace the next part with its first character
        totalLength -= nameParts[nextPartToShrink].length - 1;
        nameParts[nextPartToShrink] = nameParts[nextPartToShrink][0];
        nextPartToShrink--;
    }
    return nameParts.join(' ');
}

function NotYetAttendedItem ({
                               isFirstNotAttended,
                               saveCallback
                             }: {
                                isFirstNotAttended: boolean,
                                saveCallback: (date: Date)=>Promise<void>
                              }) {
  let [saving, setSaving] = useState<boolean>(false);
  let [editingDate, setEditingDate] = useState<boolean>(false);
  let [date, setDate] = useState<Date>(getSessionDate());

    async function save() {
      setSaving(true);
      await saveCallback(date);
      setSaving(false);
      setEditingDate(false);
    }

    return <Fragment>
        {!editingDate && <Button
            disabled={!isFirstNotAttended}
            onClick={() => {setEditingDate(true)}}
            sx={{
                margin: 0,
                padding: 0
            }}
        >
            <Typography variant="h6" textAlign={"center"}>
                NOT YET
                <br/>
                ATTENDED
            </Typography>
        </Button>}
        {editingDate && <Paper elevation={0} sx={{width: '100%'}}>
            <Grid container spacing={2} padding={'5px'} direction={"column"}>
                <Grid item columns={5}>
                    <EditableDate value={date} onChange={(newDate)=>newDate && setDate(newDate)} includeTime={false} />
                </Grid>
                <Grid item columns={12}>
                    <Box display="flex" justifyContent="flex-end">
                        <Stack direction={"row"} spacing={1}>
                            <Button disabled={saving} variant={'outlined'} onClick={() => setEditingDate(false)}>Cancel</Button>
                            <Button disabled={saving} variant={'contained'} onClick={()=>save()}>Save</Button>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
        }
    </Fragment>;
}

function PassCard({payment, clients, settings, sessions, updatePassPaymentCallback}: { settings: Settings, clients: Client[], sessions: Session[], payment: PassPayment, updatePassPaymentCallback: ()=>Promise<void> }) {
  //A pass payment is only ever for one pass
  const paymentForDetails = payment.paymentFor[0]
  const client = clients.find(client => client.id === paymentForDetails.clientId)
  const sessionType = settings.sessionTypes.find(sessionType => sessionType.id === paymentForDetails.sessionTypeId)
  const passType = sessionType?.passTypes?.find(passType => passType.id === payment.pass?.passTypeId)

  let navigate = useNavigate();

  const { registerSession, addClientToClosedSession } = useSessions()

  /**
   * Check the list of sessions to see if that date-time slot already has a session for this pass's session type
   * If not, call /pt/session/register if we need to create a new session with this client payment
   * If so, we'll use /pt/session/add-client-to-closed to add the client to the existing closed session
   */
  async function backfillPassForDate(date: Date) {
    return new Promise<void>((resolve, reject) => {
      let matchedExistingSession = false;
      for (const session of sessions) {
        //check for a session matching the date, session type of the pass and in the Complete state
        if (session.date.getTime() === date.getTime() && session.sessionTypeId === sessionType?.id && session.state === "Complete") {
          //Add this client to the closed session
          addClientToClosedSession(session.id!, {
            clientId: client!.id!,
            paymentMethod: `pass:${passType?.id}`
          }, async ()=> {
            await updatePassPaymentCallback();
            resolve();
          }, error => {
            console.log(`${error} adding session for ${client?.name}`)
            reject(error);
          });
          matchedExistingSession = true;
          break;
        }
      }
      if (!matchedExistingSession) {
        registerSession({
              clients: [
                {
                  clientId: client!.id!,
                  name: client!.name,
                  phone: client!.phone,
                  paymentMethod: `pass:${passType?.id}`,
                  signInId: crypto.randomUUID(),
                  signature: {
                    date: new Date(),
                    device: '',
                    selfSigned: false,
                    ip: ''
                  }
                }
              ],
              date,
              sessionTypeId: sessionType!.id,
              state: "Complete",
              trainerId: ''
            },
            async id => {
              //refresh the pass data
              await updatePassPaymentCallback();
              resolve();
            },
            error => {
              console.log(`${error} adding session for ${client?.name}`)
              reject(error);
            }
        )
      }
    });
  }

  return <Grid container rowGap={2} spacing={0} padding={'5px'} direction={"column"}
    style={{ minWidth: '1258px', backgroundColor: 'white' }}>
    <Grid item columns={12}>
      <Divider></Divider>
    </Grid>
    <Grid item columns={12}>
      <Box display="flex" justifyContent="center">
        <TrainerLogo />
      </Box>
    </Grid>
    <Grid item columns={12}>
      <Box display="flex" justifyContent="center">
        <Typography variant={"h2"}>
          {passType && passType.name}
        </Typography>
      </Box>
    </Grid>
    <Grid item columns={12}>
      <Box display="flex" justifyContent="center">
        <Typography variant={"h2"}>
          {client && client.name}
        </Typography>
      </Box>
    </Grid>
    <Grid item columns={12}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1}></Grid>
          {payment.sessionPayments.map((sessionPayment, index) => {
            let isFirstNotAttended;
            if (!sessionPayment.dateAttended) {
              if (index === 0) {
                isFirstNotAttended = true;
              } else {
                if (payment.sessionPayments[index - 1].dateAttended) {
                  isFirstNotAttended = true;
                } else {
                  isFirstNotAttended = false;
                }
              }
            } else {
              isFirstNotAttended = false;
            }
            return <React.Fragment key={index}>
              <Grid item xs={2}>
                <PassCardItem>
                  <CardHeader
                    avatar={
                      <Avatar sx={sessionPayment.dateAttended ? { bgcolor: green[400] } : { bgcolor: 'gray' }} aria-hidden>
                        {sessionPayment.dateAttended && <Done />}
                      </Avatar>
                    }
                    title={<Typography>{`SESSION ${index + 1}`}</Typography>}
                  />
                  <CardContent>
                    <Box display="flex" justifyContent="center" width={'100%'}>
                      <Typography variant="h6" textAlign={"center"}>
                          {sessionPayment.dateAttended && (sessionPayment.attendeeId
                                  ? <Button onClick={() => navigate(`/edit-client/${sessionPayment.attendeeId}`)} sx={{padding: 0, marginBottom: -1}}>
                                      <Typography variant="h6">
                                          {shrinkNameIfNecessary(clients.find(client => client.id === sessionPayment.attendeeId)?.name ?? '[UNKNOWN]')}
                                      </Typography>
                                  </Button>
                                  : 'ATTENDED'
                          )}
                        {!sessionPayment.dateAttended && <NotYetAttendedItem
                            isFirstNotAttended={isFirstNotAttended}
                            saveCallback={backfillPassForDate}
                        />}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Box display="flex" justifyContent="center" width={'100%'}>
                      <Button disabled={!sessionPayment.dateAttended} size="small" color="primary"
                        onClick={() => navigate('/session-details/' + sessionPayment.sessionId)}
                        aria-hidden={sessionPayment.dateAttended ? undefined : true}>
                        <Typography variant="h6">
                          {`${sessionPayment.dateAttended ? formatDate(new Date(sessionPayment.dateAttended), true) : ' '}`}
                        </Typography>
                      </Button>
                    </Box>
                  </CardActions>
                </PassCardItem>
              </Grid>
              {
                (index > 0 && (index + 1) % 5 === 0) && <React.Fragment>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={1}></Grid>
                </React.Fragment>
              }
            </React.Fragment>
          })}
        </Grid>
      </Box>
    </Grid >
    <Grid item columns={12}>
      <Divider></Divider>
    </Grid>
    <Grid item columns={12}>
      <Box display="flex" justifyContent="center">
        <Typography>Purchased {formatDate(new Date(payment.date), true)},
          expires {formatDate(new Date(paymentForDetails.expiryDate!), true)}.</Typography>
      </Box>
    </Grid>
  </Grid >
}

type DisplayMethod = "List" | "Card"

function MiniPassCard({ payment, clients, settings }: { settings: Settings, clients: Client[], payment: PassPayment }) {
  //A pass payment is only ever for one pass
  const paymentForDetails = payment.paymentFor[0]
  const client = clients.find(client => client.id === paymentForDetails.clientId)
  const sessionType = settings.sessionTypes.find(sessionType => sessionType.id = paymentForDetails.sessionTypeId)
  const passType = sessionType?.passTypes?.find(passType => passType.id === payment.pass?.passTypeId)

  let navigate = useNavigate();

  return <Grid container rowGap={2} spacing={0} padding={'5px'} direction={"column"} style={{ backgroundColor: 'white' }}>
    <Grid item columns={12}>
      <Divider></Divider>
    </Grid>
    <Grid item columns={12}>
      <Box display="flex" justifyContent="center">
        <TrainerLogo width={"100%"} maxWidth={"1768px"} />
      </Box>
    </Grid>
    <Grid item columns={12}>
      <Box display="flex" justifyContent="center">
        <Typography fontSize={{ xs: "36px", sm: "48px", md: "64px" }}>
          {client && client.name}
        </Typography>
      </Box>
    </Grid>
    <Grid item columns={12}>
      <Grid container spacing={1}>
        <Grid item xs={1}></Grid>
        {payment.sessionPayments.map((sessionPayment, index) => {
          return <React.Fragment key={index}>
            <Grid item
              xs={5}
              alignItems="center"
              justifyContent="center"
            >
              <Button disabled={!sessionPayment.dateAttended} size="small"
                color="primary" onClick={() => navigate('/session-details/' + sessionPayment.sessionId)}
              >
                <Avatar sx={sessionPayment.dateAttended ? { backgroundColor: green[400] } : { backgroundColor: 'gray' }}
                  aria-hidden>
                  {sessionPayment.dateAttended && <Done />}
                </Avatar>
                <Typography
                  fontSize={"14px"}>&nbsp;{sessionPayment.dateAttended ? formatDate(new Date(sessionPayment.dateAttended), true) : "Unused"}</Typography>
              </Button>
            </Grid>
            {(index > 0 && (index + 1) % 2 === 0) && <React.Fragment>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
            </React.Fragment>}
          </React.Fragment>
        })}
      </Grid>
    </Grid>
    <Grid item columns={12}>
      <Divider></Divider>
    </Grid>
    <Grid item columns={12}>
      <Box display="flex" justifyContent="center">
        <Typography>Purchased {formatDate(new Date(payment.date), true)},
          expires {formatDate(new Date(paymentForDetails.expiryDate!), true)}.</Typography>
      </Box>
    </Grid>
  </Grid>
}

export default function PassPaymentDetails() {
  const miniCard = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const { id } = useParams()
  const navigate = useNavigate()
  const {clients, lookupClient} = useClients()
  const {settings} = useSettings()
  const {sessions} = useSessions()
  const {getClientSessionPayments} = usePayments();

  let {getPassPayment, deletePayment} = usePayments()
  let [payment, setPayment] = useState<PassPayment>()
  let [error, setError] = useState<ApiError>()
  let [clientId, setClientId] = useState<string>('');
  let [client, setClient] = useState<Client|null>(null);
  let [overduePayments, setOverduePayments] = useState<SessionPayment[]>([]);

  let [sessionPayments, setSessionPayments] = useState<SessionPayment[]>([]);
  useEffect(() => {
    if (clientId) {
      getClientSessionPayments(clientId, (sp) => {
        setSessionPayments(sp.results);
      }, console.error);
    }
  }, [clientId]);

  useEffect(() => {
      if (sessionPayments) {
        // Find any session payments with a state of PaymentOutstanding (if the pass is full)
        let overdue = sessionPayments.filter(sp => sp.state === "PaymentOutstanding" && sp.sessionTypeId === payment?.paymentFor[0].sessionTypeId);
        setOverduePayments(overdue);
      }
  }, [sessionPayments]);

  async function updatePassPayment() {
    return new Promise<void>((resolve, reject) => {
      if (id) {
        getPassPayment(id, (p) => {
          // console.log(`Before payment had ${payment?.sessionPayments.filter(sp=>sp.dateAttended).length} sessions.`)
            setPayment(p)
            if (p && p.paymentFor && p.paymentFor.length > 0) {
              const cId = p.paymentFor[0].clientId;
              setClientId(cId);
              const c = lookupClient(cId);
              setClient(c);
            }
            resolve();
          // console.log(`Updated the payment with ${p?.sessionPayments.filter(sp=>sp.dateAttended).length} sessions`)
          // console.log(`After payment had ${payment?.sessionPayments.filter(sp=>sp.dateAttended).length} sessions.`)
        }, e=> {
            setError(e);
            reject(e);
        })
      } else {
        reject("No payment ID provided");
      }
    });
  }
  const updatePassPaymentCallback = useCallback(updatePassPayment, [id])
  useEffect( () => {
    (async () => {
      await updatePassPayment();
    })();
  }, [id, clients]);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  //Remember the last state of the display method radio button state in local storage
  const storedPassPaymentDisplayMethod = localStorage.getItem("passPaymentDisplayMethod")
  let initialDisplayMethod: DisplayMethod
  if (storedPassPaymentDisplayMethod === "List" || storedPassPaymentDisplayMethod === "Card") {
    initialDisplayMethod = storedPassPaymentDisplayMethod as DisplayMethod
  } else {
    initialDisplayMethod = "Card"
  }
  const [displayMethod, setDisplayMethod] = useState<DisplayMethod>(initialDisplayMethod)
  useEffect(() => {
    localStorage.setItem("passPaymentDisplayMethod", displayMethod);
  }, [displayMethod]);

  return (
    <EffortlessPTPageWrapper title={"Pass Payment Details"}>
      <Grid container spacing={2} padding={'10px'} direction={"column"}>

        {(payment && clients) &&
          <React.Fragment>
            <Grid item columns={12}>
              <FormControl>
                <FormLabel id="display-method-label" style={{ fontSize: '12px' }}>Display as</FormLabel>
                <RadioGroup
                  aria-labelledby="display-method-label"
                  name="display-method"
                  value={displayMethod}
                  onChange={(event, newValue) => {
                    setDisplayMethod(newValue as DisplayMethod);
                  }}
                  row={true}
                >
                  <FormControlLabel value="List" control={<Radio />} label="List" />
                  <FormControlLabel value="Card" control={<Radio />} label="Card" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item columns={12}>
              {displayMethod === "List" &&
                <PaymentDetailsGrid payment={payment} clients={clients} settings={settings} />}
              {(displayMethod === "Card" && !miniCard) &&
                <PassCard payment={payment} clients={clients} settings={settings} sessions={sessions} updatePassPaymentCallback={updatePassPaymentCallback} />}
              {(displayMethod === "Card" && miniCard) &&
                <MiniPassCard payment={payment} clients={clients} settings={settings} />}
            </Grid>
          </React.Fragment>
        }
        {(overduePayments && overduePayments.length > 0 && client) &&
          <React.Fragment>
            <Grid item columns={12}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography color={'red'}>Overdue payments for additional sessions:</Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item columns={12}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    {overduePayments.filter(sp=>sp.dateAttended && sp.sessionId).map((sp, index) => {
                      return <OverdueSession key={index} sessionDate={sp.dateAttended!} sessionId={sp.sessionId!}/>
                    })}
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Box>
            </Grid>
          </React.Fragment>
        }
        <Grid item columns={12}>
          <Typography>
            {error && `Error: ${error?.errorCode}: ${error?.message}`}
          </Typography>
          <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
            <DialogTitle>Delete Payment</DialogTitle>
            <DialogContent>
              Are you sure you would like to delete this payment? This cannot be undone.
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
              <Button onClick={() => {
                // TODO: Handle errors better.
                deletePayment(id!, () => navigate(-1), console.error);
              }} color="error">Delete</Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item columns={12}>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <Button onClick={() => navigate(-1)}>Back</Button>
            <Button onClick={() => navigate(`/client-payments/${clientId}`)}>View All Sessions</Button>
            <Button onClick={() => setConfirmDeleteOpen(true)} color="error" variant="outlined"
              endIcon={<Delete />}>Delete</Button>
          </Stack>
        </Grid>
      </Grid>
    </EffortlessPTPageWrapper>
  )
}
