import {InputProps, Input, InputLabel, FormHelperText, FormControl} from '@mui/material'
import * as React from 'react'

export default function FormField (props: {
  label: string;
  fieldName: string;
  inputType: string;
  object: any;
  helperText?: string;
  attributes: InputProps;
}) {
  const { label, fieldName, inputType, object, attributes, helperText } = props
  const [value, setValue] = React.useState(object[fieldName])
  React.useEffect(() => {
    object[fieldName] = value
  }, [value, object, fieldName])
  //TODO: Replace error false with real error handling
  return (
    <FormControl fullWidth={true} variant={'standard'}>
      <InputLabel htmlFor={fieldName}>{label}</InputLabel>
      <Input
        id={fieldName}
        name={fieldName}
        aria-describedby={`${fieldName}-text`}
        type={inputType}
        value={value}
        error={false}
        {...attributes}
        onChange={event => setValue(event.target.value)}
      />
      <FormHelperText id={`${fieldName}-text`}>{helperText}</FormHelperText>
    </FormControl>
  )
}
