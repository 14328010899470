import * as React from 'react'
import {Payment} from '../../data/payment'
import DataTable, {TableColumn} from './DataTable'
import {Box, Button, Grid, Hidden, Tooltip} from '@mui/material'
import {Add, House, Loyalty} from '@mui/icons-material'
import {useClients, usePayments, useSettings} from './EffortlessPTContext';
import {Link, useNavigate} from 'react-router-dom';
import {formatDate} from './date'
import {dineroFromScaledNumber, formatCurrency} from "./currency";
import {useEffect, useState} from "react";
import {ButtonPanel} from "./pages/Dashboard";

function comparePaymentDates(b: Payment, a: Payment) {
  if (a.date < b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return 1;
  }
  return 0;
}

export default function PaymentGrid() {
  let navigate = useNavigate();
  const {settings} = useSettings()
  const hasPasses = Boolean(settings.sessionTypes.find(sessionType => sessionType.passTypes && sessionType.passTypes.length > 0))
  const {clients} = useClients();
  const clientFromClientId = (clientId: string) => clients.find(c => c.id === clientId);

const [filter, setFilter] = useState("");

  const columns: Array<TableColumn<Payment>> = [
    {
      name: 'Date',
      getter: payment => <Link to={`/payment-details/${payment.paymentType.toLowerCase()}/${payment.id}`}>{formatDate(payment.date, true)}</Link>
    },
    {
      name: 'Client',
      getter: payment => {
        if (payment.paymentFor && payment.paymentFor.length > 0) {
          const client = clientFromClientId(payment.paymentFor[0].clientId);
          if (client) {
            return client.name
          }
        }
        return 'Unknown'
      }
    },
    {
      name: 'Amount',
      getter: payment => payment.amount && Number.isInteger(payment.amount) ? formatCurrency(dineroFromScaledNumber(payment.amount)) : ''
    }
  ]

  const {payments} = usePayments()
  const [maxLoadedPayments, setMaxLoadedPayments] = useState(5)
  function getFilteredPayments(filterText: string | undefined, maxToReturn: number): { hasMore: boolean, payments: Payment[] } {
    let result
    if (filterText) {
      const filterTextLowerCase = filterText.toLowerCase()
      result = payments.filter(payment => {
        if (formatDate(payment.date).indexOf(filterText) !== -1) {
          return true
        }
        if (payment.paymentType.toLowerCase().indexOf(filterTextLowerCase) !== -1) {
          return true
        }
        if (`${payment.amount}`.indexOf(filterText) !== -1) {
          return true
        }
        for (const paymentFor of payment.paymentFor) {
          const client = clientFromClientId(paymentFor.clientId)
          if (client) {
            const clientNameLowerCase = client.name.toLowerCase();
            if (clientNameLowerCase.indexOf(filterTextLowerCase) !== -1) {
              return true
            }
          }
        }
        return false
      })
    } else {
      result = payments
    }
    const sliced = result.sort(comparePaymentDates).slice(0, maxToReturn);
    return {hasMore: result.length > sliced.length, payments: sliced}
  }

  const [hasMore, setHasMore] = useState(false)
  const [paymentsToDisplay, setPaymentsToDisplay] = useState<Payment[]>([])
  function updateFilteredPayments(filter: string | undefined, maxToLoad: number) {
    const {hasMore, payments} = getFilteredPayments(filter, maxToLoad);
    setHasMore(hasMore)
    setPaymentsToDisplay(payments)
  }

  useEffect(()=>{
    if (payments && clients) {
      updateFilteredPayments(filter, maxLoadedPayments)
    }
  }, [payments, clients, filter])

  return (
    <Box>
      <Grid container spacing={{xs: 1, md: 2}} padding={{xs: 0, md: 0}} direction={"column"}>
        <Grid item columns={12}>
          <ButtonPanel filterTextState={filter} filterChanged={filterText => {
                setFilter(filterText)
              }}>
            <Tooltip title={"Add a session payment from a client"}><Button onClick={() => navigate(`/payment/session`)} endIcon={<Add/>}>Session Payment</Button></Tooltip>
            <Hidden smDown><Tooltip title={"Reconcile your bank transactions with payments from clients"}><Button onClick={() => navigate(`/reconcile-bank-transactions`)} endIcon={<House/>}>Bank Transaction</Button></Tooltip></Hidden>
            {hasPasses && <Tooltip title={"Add a pre-paid pass for a client entitling them to discounted sessions."}><Button
                onClick={() => navigate(`/payment/pass`)} endIcon={<Loyalty/>}>Pass Payment</Button></Tooltip>}
          </ButtonPanel>
        </Grid>
        <Grid item columns={12}>
          <DataTable name="Payments" columns={columns} rows={paymentsToDisplay}
           hasMore={hasMore}
           onMore={()=>{
             const maxToLoad = maxLoadedPayments + 5
             updateFilteredPayments(filter, maxToLoad)
             setMaxLoadedPayments(maxToLoad)
           }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
