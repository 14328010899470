import * as React from 'react'

import { Container, Input, Typography } from '@mui/material'
import FormField from '../FormField'
import { ApiDescription, callApi, useApi } from '../UseApi'
import { UserDetails } from '../../../data/user-details'

async function submitPassword (id: string, password: string, event: React.FormEvent<HTMLFormElement>, emailVerifiedApi: ApiDescription) {
  event.preventDefault()
  const response = await callApi<UserDetails>(emailVerifiedApi, {
    body: JSON.stringify({ id, password })
  }, '', false)
  if (!('message' in response)) {
    window.location.search = ''
    window.location.hash = '#/'
  } else {
    // TODO: Handle this better.
    console.error(JSON.stringify(response))
  }
}

export default function EmailVerified () {
  const emailVerifiedApi = useApi('/signup/email-verified', 'POST')
  // Get the id from the query params.
  const id = new URLSearchParams(window.location.search).get('id')
  if (id) {
    const passwordContainer: { password: string } = { password: '' }
    return (
      <Container>
        <form onSubmit={(event) => submitPassword(id, passwordContainer.password, event, emailVerifiedApi)}>
          <h2>Step 2</h2>
          <h3>Choose a password</h3>
          <FormField label="Password" inputType="password" fieldName="password" object={passwordContainer} attributes={{ required: true }} />
          <Input type="submit" value="Next" />
        </form>
      </Container>
    )
  } else {
    return (
      <Typography>
        We&apos;ve sent you an email to verify your email address. Please click the link in the email to complete your sign up.
      </Typography>
    )
  }
}
