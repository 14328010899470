import { Face, HelpOutlined } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, InputLabel, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { isPositive } from 'dinero.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Client } from '../../../data/client';
import { CsvFile } from '../CsvFile';
import { dineroFromString, formatCurrency, greaterThanZeroDollars } from '../currency';
import { parseDate, formatDate } from '../date';
import { useClients } from '../EffortlessPTContext';
import IconMenu from '../IconMenu';
import usePersistentState from '../PersistentStateContext';
import PhoneInput from '../PhoneInput';
import { ColumnRoles, ReconciliationColumnRole } from './BankStatementColumnRoles';
import { ReconciliationPayment } from './data';

function NewClientForPaymentDialog({ payment, open, onCreate, onClose }: {
    payment?: ReconciliationPayment
    open: boolean
    onCreate: (client: Client) => void
    onClose: () => void
}) {
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [eftMatch, setEftMatch] = useState(payment?.description ?? "")

    useEffect(() => {
        setName("")
        setPhone("")
        setEftMatch(payment?.description ?? "")
    }, [payment])

    return (
        <Dialog open={open} onClose={onClose}>
            <form onSubmit={(event) => {
                event.preventDefault();
                onCreate({
                    name,
                    phone,
                    eftMatch,
                    trainerId: ""
                })
            }}>
                <DialogTitle>Create New Client for Payment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter name and phone number to create a new client for this payment.
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        autoComplete={"off"}
                        fullWidth
                        variant="standard"
                        value={name}
                        required
                        onChange={e => setName(e.target.value)}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel htmlFor={phone} variant={"standard"} required={true}>{"Phone"}</InputLabel>
                        <PhoneInput
                            id="phone"
                            inputProps={{
                                margin: "dense",
                                type: "phone",
                                autoComplete: "off",
                                fullWidth: true,
                                required: true,
                            }}
                            value={phone}
                            required={true}
                            onChange={text => setPhone(text)}
                        />
                    </FormControl>
                    <TextField
                        margin="dense"
                        id="eft-match"
                        label="EFT Match"
                        autoComplete={"off"}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={eftMatch}
                        onChange={e => setEftMatch(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type={"submit"}>Create</Button>
                </DialogActions>
            </form>
        </Dialog>

    );
}

function UnknownClientActions(props: {
    payment: ReconciliationPayment
    index: number
    openNewClientDialog: (payment: ReconciliationPayment) => void
}) {
    const { payment, index, openNewClientDialog } = props;
    return <IconMenu
        icon={<HelpOutlined />}
        title="Unknown client"
        uniqueKey={`unknown-client-${index}`}
        onSelected={(action) => {
            switch (action) {
                case "create": {
                    //Show a mui dialog to create a new client
                    openNewClientDialog(payment)
                    break;
                }
            }
        }}
        options={[
            {
                name: "Create",
                value: "create",
                description: "Create a new client",
                icon: <Face />
            }
        ]}
    />
}

function createReconciliationPayments(csvFile: CsvFile | null, columnRoles: Array<ReconciliationColumnRole>) {
    const payments: ReconciliationPayment[] = []
    for (const row of csvFile!.rows) {
        const payment: ReconciliationPayment = {
            shouldCreatePayment: false,
            date: new Date(),
            description: "",
            amount: dineroFromString("0")
        }
        for (let i = 0; i < row.cells.length; i++) {
            const cell = row.cells[i]
            switch (columnRoles[i]) {
                case "date":
                    payment.date = parseDate(cell.value)
                    break
                case "description":
                    payment.description = cell.value
                    break
                case "amount":
                    payment.amount = dineroFromString(cell.value)
                    break
            }
        }
        if (greaterThanZeroDollars(payment.amount)) {
            payments.push(payment)
        }
    }
    return payments;
}

function matchClients(payments: ReconciliationPayment[], clients: Client[]) {
    for (const payment of payments) {
        // Ignore negative amounts (debits) and rows which are already selected.
        if (isPositive(payment.amount) && !payment.shouldCreatePayment) {
            //First pass look for EFT match
            let matchedClients = []
            for (const client of clients) {
                const descriptionLowerCase = payment.description.toLowerCase();
                if (client.eftMatch && descriptionLowerCase.includes(client.eftMatch.toLowerCase())) {
                    matchedClients.push(client)
                }
            }
            if (matchedClients.length > 1) {
                //TODO: add a helper string 'Could be x, y or z'
            } else if (matchedClients.length === 1) {
                payment.matchedClient = matchedClients[0]
            } else {
                //Second pass look for client name match
                for (const client of clients) {
                    const descriptionLowerCase = payment.description.toLowerCase();
                    if (descriptionLowerCase.includes(client.name.toLowerCase())) {
                        matchedClients.push(client)
                    }
                }
                if (matchedClients.length > 1) {
                    //TODO: add a helper string 'Could be x, y or z'
                } else if (matchedClients.length === 1) {
                    payment.matchedClient = matchedClients[0]
                }
            }
            if (payment.matchedClient) {
                payment.shouldCreatePayment = true
            }
        }
    }
}

function PaymentsTableRow({ payment, index, clients, setNewClientDialogPayment, setNewClientDialogOpen, setPaymentState, setClient }: {
    payment: ReconciliationPayment,
    index: number,
    clients: Array<Client>,
    setNewClientDialogPayment: (payment: ReconciliationPayment) => void,
    setNewClientDialogOpen: (open: boolean) => void
    setPaymentState: (index: number, state: boolean) => void
    setClient: (index: number, client: Client | null) => void
}) {
    const client = clients.find(client => client.id === payment.matchedClient?.id) ?? null;
    return <TableRow key={index}>
        <TableCell>
            <Checkbox checked={payment.shouldCreatePayment} onChange={(event, newValue) => {
                setPaymentState(index, newValue)
            }} />
        </TableCell>
        <TableCell><Typography color={payment.shouldCreatePayment ? 'success' : 'darkgrey'}>{formatDate(payment.date, true)}</Typography></TableCell>
        <TableCell><Typography color={payment.shouldCreatePayment ? 'success' : 'darkgrey'}>{payment.description}</Typography></TableCell>
        <TableCell><Typography color={payment.shouldCreatePayment ? 'success' : 'darkgrey'}>{formatCurrency(payment.amount)}</Typography></TableCell>
        <TableCell>
            {payment.shouldCreatePayment && <Stack direction={"row"}><FormControl fullWidth variant={"standard"}>
                <Autocomplete
                    id={`payment-client-autocomplete-${index}`}
                    options={clients}
                    value={client}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField variant={"standard"} {...params} id="payment-client-dropdown"
                            aria-describedby="payment-client-helper-text" placeholder="Client" />
                    )}
                    onChange={(event, newValue) => {
                        setClient(index, newValue)
                    }}
                />
                <FormHelperText
                    id="payment-client-helper-text">{payment.matchedClient ? `Create payment for ${payment.matchedClient.name}.` : `Select a client to create a payment for.`}</FormHelperText>
            </FormControl>
                {!client && <UnknownClientActions payment={payment} index={index} openNewClientDialog={() => {
                    setNewClientDialogPayment(payment)
                    setNewClientDialogOpen(true)
                }} />}
            </Stack>
            }
        </TableCell>
    </TableRow>
}

function PaymentsTable({ payments, clients, setPaymentState, setClient }: { payments: ReconciliationPayment[], clients: Client[], setPaymentState: (index: number, state: boolean) => void, setClient: (index: number, client: Client | null) => void }) {
    const [newClientDialogOpen, setNewClientDialogOpen] = useState(false);
    const [newClientDialogPayment, setNewClientDialogPayment] = useState<ReconciliationPayment>();
    const { addClient } = useClients();

    return <React.Fragment>
        <NewClientForPaymentDialog
            open={newClientDialogOpen}
            payment={newClientDialogPayment}
            onClose={() => setNewClientDialogOpen(false)}
            onCreate={(client) => {
                addClient(client, id => {
                    setNewClientDialogOpen(false)
                    if (newClientDialogPayment && payments.indexOf(newClientDialogPayment) !== -1) {
                        setClient(payments.indexOf(newClientDialogPayment), client)
                    }
                }, console.error) //TODO: Handle error
            }}
        />
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Create Payment</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Client</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payments.filter(payment => isPositive(payment.amount)).map((payment, index) => (
                        <PaymentsTableRow payment={payment} index={index} clients={clients} setNewClientDialogOpen={setNewClientDialogOpen} setNewClientDialogPayment={setNewClientDialogPayment} key={index} setPaymentState={setPaymentState} setClient={setClient} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </React.Fragment>
}

export default function BankStatementMatchClients() {
    const [payments, setPayments] = usePersistentState<ReconciliationPayment[]>("reconcileBankStatement.payments", [])
    const [csvFile] = usePersistentState<CsvFile>("reconcileBankStatement.csvFile")
    const [columnRoles] = usePersistentState<ColumnRoles>("reconcileBankStatement.columnRoles")
    const { clients } = useClients();

    function updatePayments() {
        const newPayments = payments?.length === 0 ? createReconciliationPayments(csvFile, columnRoles) : payments;
        matchClients(newPayments, clients)
        setPayments(newPayments)
    }

    useEffect(() => {
        updatePayments();
    }, [clients, csvFile, columnRoles]);

    function setPaymentState(index: number, state: boolean) {
        setPayments(payments.map((payment, mapIndex) => {
            if (mapIndex === index) {
                let result = { ...payment }
                result.shouldCreatePayment = state
                return result
            } else {
                return payment
            }
        }))
    }

    function setClient(index: number, client: Client | null) {
        setPayments(payments.map((payment, mapIndex) => {
            if (mapIndex === index) {
                let result = { ...payment }
                result.matchedClient = client ?? undefined
                return result
            } else {
                return payment
            }
        }))
    }

    return payments && <PaymentsTable payments={payments} clients={clients} setPaymentState={setPaymentState} setClient={setClient} />
}
