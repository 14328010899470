import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { AppBar, Avatar, Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { AccountTreeOutlined, Forest, ForestOutlined, ForestTwoTone, Login } from "@mui/icons-material"
import { Logout } from '@mui/icons-material'
import { Link } from "react-router-dom";
import { EFFORTLESS_PT_ENVIRONMENT } from "./index";
import AvatarButton from './AvatarButton'


export interface BannerContext {
  bannerShowing: boolean;
  setBannerShowing: (bannerShowing: boolean) => void;
}

const bannerContext = React.createContext<BannerContext>({
  bannerShowing: false,
  setBannerShowing: () => null
});

export function BannerContextProvider({ children }: { children: React.ReactNode }) {
  const [bannerShowing, setBannerShowing] = React.useState(true);
  return <bannerContext.Provider value={{ bannerShowing, setBannerShowing }}>{children}  </bannerContext.Provider>
}

export const useBanner = () => React.useContext(bannerContext);

interface LoginOrLogoutButtonParameters {
  marginTop: number
  marginRight: number
}

const LoginOrLogoutButton = ({ marginTop, marginRight }: LoginOrLogoutButtonParameters) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  if (isLoading) {
    return null
  } else {
    let style = {
      marginTop: `${marginTop}px`,
      marginRight: `${marginRight}px`
    };
    let textSx = {
      display: {
        xs: 'none',
        sm: 'inherit'
      }
    };
    let button;
    if (!isAuthenticated) {
      button = <Button aria-label={"Log in"}
        style={style}
        onClick={() => loginWithRedirect()}
        variant="text"
        color="inherit"
        endIcon={<Login />}>
        <Box sx={textSx}>Log in</Box>
      </Button>
    } else {
      button = <Box style={{ marginTop: `${marginTop - 4}px`, marginRight: `${marginRight}px` }}><AvatarButton /></Box>
    }
    return <Stack direction={'row'} spacing={1}>
      {(EFFORTLESS_PT_ENVIRONMENT !== 'prod' && isAuthenticated) &&
        <Tooltip title={"Branches"}>
          <Link to={"branches"}>
            <ForestTwoTone htmlColor={"white"} style={{ marginTop: `${marginTop}px`, width: 32, height: 32 }} />
          </Link>
        </Tooltip>
      }
      {button}
    </Stack>
  }
}

interface LogoParameters {
  effortlessFill: string
  ptFill: string
  fontSizePx: number
}

const Logo = ({ effortlessFill, ptFill, fontSizePx }: LogoParameters) => {
  return (
    <Box style={{ display: 'flex', marginLeft: '10px', marginBottom: '5px' }}>
      <Typography fontFamily={'Open Sans'} fontStyle={'italic'} fontWeight={300} fontSize={`${fontSizePx}px`}
        style={{ color: effortlessFill }} noWrap component="span">
        effortless
      </Typography>
      <Typography width={fontSizePx * 2} fontFamily={'Open Sans'} fontStyle={'italic'} fontWeight={700}
        fontSize={`${fontSizePx}px`} style={{ color: ptFill }} noWrap component="span">
        PT
      </Typography>
    </Box>
  )
}
export default function Banner() {
  const { bannerShowing } = useBanner();
  if (bannerShowing) {
    return (
      <AppBar position="static">
        <Box display="flex" justifyContent="space-between">
          <Box alignItems="center">
            <a href={`${process.env.PUBLIC_URL}/index.html`} style={{ textDecoration: 'none' }}>
              {<Logo effortlessFill='white' ptFill='#0f0449' fontSizePx={36} />}
            </a>
          </Box>
          <Box>
            <LoginOrLogoutButton marginTop={12} marginRight={5} />
          </Box>
        </Box>
      </AppBar>
    )
  } else {
    return null;
  }
}
