import { Autocomplete, Button, Checkbox, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { SessionClient } from '../../data/session';
import { PaymentMethod } from '../../data/payment';
import { Client } from '../../data/client';
import { useClients, useSettings, useTrainerPath } from './EffortlessPTContext';
import { Navigate, useNavigate } from 'react-router-dom';

export default function AddSessionClient(props: {
  sessionTypeId: string;
}) {
  const { sessionTypeId } = props;
  const { settings } = useSettings();
  const trainerPath = useTrainerPath();
  const { clients } = useClients();
  const sessionType = settings.sessionTypes.find((sessionType) => sessionType.id === sessionTypeId);
  const navigate = useNavigate();
  const [client, setClient] = React.useState<Client | null>(null);
  return (
    <Grid container spacing={2} padding='5px'>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant={"standard"}>
          <Autocomplete
            id={"manually-add-client-autocomplete"}
            options={clients}
            value={client}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField variant={"standard"} {...params} id="manually-add-client-dropdown" aria-describedby="manually-add-client-helper-text" placeholder="Manually sign in a client" />
            )}
            onChange={(event, newValue) => {
              setClient(newValue);
            }}
          />
          <FormHelperText id="manually-add-client-helper-text">{client ? `Sign in on behalf of ${client.name}.` : `Select a client to sign in on behalf of.`}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          variant="contained"
          color="primary"
          disabled={!client}
          onClick={() => {
            navigate(`/trainer-client-sign-in/${trainerPath}/${encodeURIComponent(client!.name)}/${encodeURIComponent(client!.phone)}`);
          }}>
          Sign in
        </Button>
      </Grid>
    </Grid>
  )
}
