import { Box, Input, InputProps } from '@mui/material';
import * as React from 'react';

function formatPhone(phone: string) {
    // Remove everything which isn't either a digit or a + at the start.
    phone = phone.replace(/[^\d+]/g, '');
    // Replace the +xx4 or +xx5 with 04 or 05.
    phone = phone.replace(/^\+\d{1,2}4/, '04');
    phone = phone.replace(/^\+\d{1,2}5/, '05');
    // Remove the spaces and put them back in the right places.
    phone = phone.replaceAll(/ /g, '');
    const firstFourDigits = phone.substring(0, 4);
    const middleThreeDigits = phone.substring(4, 7);
    const lastThreeDigits = phone.substring(7, 10);
    const trailingDigits = phone.substring(10);
    return `${firstFourDigits} ${middleThreeDigits} ${lastThreeDigits}${trailingDigits}`.trim();
}

export default function PhoneInput(props: {
    value: string;
    onChange: (newValue: string) => void;
    required: boolean;
    id: string;
    inputProps: InputProps;
}) {
    const { value, onChange, required, id, inputProps } = props;
    return (
        <Input
            onBlur={(event) =>onChange(formatPhone(value))}
            id={id}
            required={required}
            onChange={(event) => onChange(event.target.value)}
            value={value}
            inputProps={{
                pattern: '^0(4|5)[0-9]{2} [0-9]{3} [0-9]{3}$',
                title: "Phone must be a valid Australian mobile phone number (04xx xxx xxx)"
            }}
            {...inputProps}
        />
    )
}
