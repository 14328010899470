import { useEffect, useState } from "react";

export default function useTimer(periodMillis: number, callback: () => void) {
    const [timerTick, setTimerTick] = useState(0);
    useEffect(() => {
        // We can't access the timerTick variable properly because the lambda function only captures the initial value of the timerTick variable.
        // Every time the setTimerTick function is called, it creates a new variable. However, this effect only runs once, so the lambda passed to setInterval can only see the first instance of it.
        // The solution is to keep a counter here and increment it alongside the timerTick.
        let internalTimerTick = timerTick;
        const interval = setInterval(() => {
            setTimerTick(internalTimerTick++);
        }, periodMillis);
        // Return a clean up function to clear the timeout when the component is unmounted.
        return () => {
            clearInterval(interval);
        }
    }, []);
    useEffect(() => {
        if (timerTick > 0) {
            callback();
        }
    }, [timerTick]);
}
