import * as React from 'react'
import {
  Button,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'


export default function Welcome() {
  const theme = useTheme();
  const showButtons = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  return (
    <Container>
      <Grid container padding={1} spacing={2} sx={{marginTop: {xs: '50px', md: '100px'}}}>
        <Grid item sm={12} md={6}>
          <Paper elevation={0}>
            <Typography variant={"h2"} fontWeight={"900"} color={theme.palette.primary.light}>Personal
              training</Typography>
            <Typography variant={"h2"} fontWeight={"900"}>without the admin!</Typography>
          </Paper>
          <Button sx={{marginTop: '25px', maxWidth: '530px', width: '100%', height: '100px', fontSize: '36px'}} variant={"contained"}
                  href={process.env.REACT_APP_PAYMENT_LINK!}>
            Sign Up Now
          </Button>
        </Grid>
        <Grid item sm={12} md={6}>
          {showButtons && <Paper elevation={0}>
              <ImageList sx={{width: 500, height: 450}} variant="masonry" cols={2} gap={8}>
                  <ImageListItem>
                      <img src={"welcome.png?w=250&fit=crop&auto=format"} loading={"lazy"}/>
                      <img src={"sign-in-sheet.png?w=250&fit=crop&auto=format"} loading={"lazy"}/>
                  </ImageListItem>
                  <ImageListItem>
                      <img src={"client-list.png?w=250&fit=crop&auto=format"} loading={"lazy"}/>
                      <img src={"bank-statement.png?w=250&fit=crop&auto=format"} loading={"lazy"}/>
                  </ImageListItem>
              </ImageList>
          </Paper>}
        </Grid>
      </Grid>
    </Container>
  )
}
