import * as React from 'react'
import { Box, Stack } from '@mui/material'
import { useTrainerPath } from '../EffortlessPTContext'
import QRCode from 'qrcode-svg';
import { useBanner } from '../banner';
import TrainerLogo from '../TrainerLogo';

export function QRCodeSvg({ size = 256, link=true }: { size?: number, link?: boolean }) {
  const trainerPath = useTrainerPath();
  const url = `${process.env.PUBLIC_URL}/index.html#/client-sign-in/${trainerPath}`;
  const qrcode = new QRCode({
    content: url, join: true, height: size, width: size
  });
  const svg = qrcode.svg();
  // We have to wrap the QR code in another SVG to set the aria-label on it.
  const image = <svg height={size} width={size} aria-label="QR code" dangerouslySetInnerHTML={{ __html: svg }} />;
  if (link) {
    return <a style={{ textDecoration: 'none' }} href={url}>{image}</a>;
  } else {
    return image;
  }
}

export default function SignInSheet() {
  const { setBannerShowing } = useBanner();
  React.useEffect(() => {
    setBannerShowing(false);
    return () => setBannerShowing(true);
  }, []);
  return (
    <Stack padding='50px' spacing={2}>
      <Box display="flex" justifyContent="center">
        <TrainerLogo width="640px" />
      </Box>
      <Box display="flex" justifyContent="center">
        <QRCodeSvg size={640} link={false} />
      </Box>
      <Box display="flex" justifyContent="center">
        Powered by Effortless PT
      </Box>
    </Stack>
  )
}
