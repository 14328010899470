import * as React from 'react';
import {
  Autocomplete,
  Badge, BadgeProps,
  Box, BoxProps,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import DataTable from '../DataTable';

import {
  Session, SessionClient, SessionType
} from '../../../data/session';
import AddSessionClient from '../AddSessionClient';
import paymentMethodToString from '../PaymentMethodToString';
import { useClients, usePayments, useSessions, useSettings, useStats, useTrainerPath } from '../EffortlessPTContext';
import EffortlessPTPageWrapper from "../EffortlessPTPageWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate, getSessionDate } from '../date';
import { useEffect, useState } from 'react';
import { QRCodeSvg } from './SignInSheet';
import { AttachMoney, Check, DataObjectSharp, Done, DoneAll, Edit, Face, HelpOutlined, NotificationImportant, PersonRemove, QuestionMark, Remove, Warning } from '@mui/icons-material';
import useTimer from '../UseTimer';
import RefreshTimerButton from "../RefreshTimerButton";
import {
  CURRENCY_REGEX,
  dineroFromScaledNumber,
  dineroFromString,
  dineroToScaledNumber,
  formatCurrency,
  formatNumber
} from '../currency';
import { add, multiply } from 'dinero.js';
import IconMenu from '../IconMenu';
import { Payment } from '../../../data/payment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { preparePhoneForRendering } from '../phoneHelper';
import { Client } from '../../../data/client';
import IconContainer from '../IconContainer';
import { Link } from 'react-router-dom';
import DateHeading from '../DateHeading';

export function canCompleteSession(session: Session) {
  return session.clients.length > 0 && session.clients.every(client => client.clientId) && session.clients.every(client => client.paymentMethod !== "Friend" || client.payerClientId);
}

function UnknownClientActions(props: {
  client: SessionClient;
  sx?: SxProps<Theme>;
}) {
  const { client, sx } = props;

  const { addClient } = useClients();
  const { matchOpenSessionClient } = useSessions();

  return <IconMenu
    sx={sx}
    icon={<HelpOutlined />}
    title="Unknown client"
    uniqueKey={`${client.signInId}`}
    onSelected={(action) => {
      switch (action) {
        case "create": {
          addClient({ name: client.name, phone: client.phone, eftMatch: '', trainerId: '' }, id => {
            matchOpenSessionClient({ signInId: client.signInId, clientId: id }, result => {
              if (!result) {
                // TODO: Handle this better.
                console.error("Failed to update the session client");
              }
            }, error => {
              // TODO: Handle this better.
              console.error(error.message);
            })
          }, error => {
            // TODO: Handle this better.
            console.error(error.message);
          })
          break;
        }
      }
    }}
    options={[
      {
        name: "Create",
        value: "create",
        description: "Create a new client with these details.",
        icon: <Face />
      }
    ]}
  />
}

function UnpaidCashClientActions({
  client,
  setCashPaymentDialogClient,
  setCashPaymentDialogOpen,
  registerCashPayment,
  sessionType,
  defaultNumberOfSessions
}: {
  client: SessionClient;
  setCashPaymentDialogClient: (client: SessionClient) => void;
  setCashPaymentDialogOpen: (open: boolean) => void;
  registerCashPayment: (payment: Payment) => void;
  sessionType: SessionType;
  defaultNumberOfSessions: number;
}) {
  return <IconMenu
    icon={<AttachMoney style={{ color: 'orange' }} />}
    title="Register cash payment"
    uniqueKey={`${client.signInId}`}
    onSelected={(action) => {
      switch (action) {
        case "registerPayment": {
          setCashPaymentDialogClient(client);
          setCashPaymentDialogOpen(true);
          break;
        }
        case "registerPaymentDefault": {
          registerCashPayment({
            amount: sessionType.price * defaultNumberOfSessions,
            method: 'Cash',
            date: new Date(),
            paymentType: "Session",
            paymentFor: [
              {
                clientId: client.clientId,
                sessionCount: defaultNumberOfSessions,
                sessionTypeId: sessionType.id
              }
            ]
          })
          break;
        }
      }
    }}
    options={[
      {
        name: `Register payment for ${formatCurrency(dineroFromScaledNumber(sessionType.price * defaultNumberOfSessions))}.`,
        value: "registerPaymentDefault",
        description: `Register a payment for ${formatCurrency(dineroFromScaledNumber(sessionType.price * defaultNumberOfSessions))}.`,
        icon: <Check />
      },
      {
        name: "Register custom payment",
        value: "registerPayment",
        description: "Register a custom payment.",
        icon: <Check />
      }
    ]}
  />
}

function CashPaidDialog({ open, onClose, onSubmit, client, sessionType }: {
  open: boolean;
  onClose: () => void;
  onSubmit: (payment: Payment) => void;
  client: SessionClient;
  sessionType: SessionType;
}) {
  const [numberOfSessions, setNumberOfSessions] = React.useState(1);
  const [amountText, setAmountText] = React.useState('');
  useEffect(() => {
    const sessionTypePrice = dineroFromScaledNumber(sessionType.price);
    const defaultAmount = multiply(sessionTypePrice, numberOfSessions);
    setAmountText(formatNumber(defaultAmount));
  }, [sessionType, numberOfSessions]);

  return <Dialog open={open} onClose={() => {
    onClose();
    setNumberOfSessions(1);
  }}>
    <form onSubmit={event => {
      event.preventDefault();
      const amountDinero = dineroFromString(amountText);
      const amount = dineroToScaledNumber(amountDinero);
      onSubmit({
        amount,
        date: new Date(),
        method: "Cash",
        paymentType: "Session",
        paymentFor: [
          {
            clientId: client.clientId,
            sessionCount: numberOfSessions,
            sessionTypeId: sessionType.id
          }
        ]
      })
      setNumberOfSessions(1);
    }}>
      <DialogTitle>Register cash payment</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} padding={1}>
          <Grid item xs={12}>
            <DialogContentText>
              {`Register cash payment for ${client.name}.`}
            </DialogContentText>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              label="Number of Sessions"
              required
              type="number"
              value={numberOfSessions}
              inputProps={{ min: 1 }}
              onChange={event => setNumberOfSessions(Number(event.target.value))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Amount"
              required
              inputProps={{
                pattern: CURRENCY_REGEX.source,
                title: "Amount must be a valid currency amount.",
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={amountText}
              onChange={event => setAmountText(event.target.value)}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => {
                onClose();
                setNumberOfSessions(1);
              }}>Cancel</Button>
              <Button type="submit">Register</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </form>
  </Dialog>
}

function SessionBadge(props: BadgeProps) {
  const { sx, ...other } = props;
  return (
    <Badge
      sx={{
        margin: 1,
        ...sx,
      }}
      {...other}
    />
  );
}

function PayingFriendSelectionDialog({ open, onClose, onSubmit, clients }: {
  open: boolean;
  onClose: () => void;
  onSubmit: (payingClientId: string) => void;
  clients: Client[];
}) {
  const [selectedClient, setSelectedClient] = React.useState<Client | null>(null);

  return <Dialog open={open} onClose={() => {
    onClose();
    setSelectedClient(null);
  }}>
    <form onSubmit={event => {
      event.preventDefault();
      if (selectedClient) {
        onSubmit(selectedClient.id!);
        setSelectedClient(null);
      }
    }}>
      <DialogTitle>Who&apos;s paying?</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} padding={1}>
          <Grid item xs={12}>
            <DialogContentText>
              {`Select the client who is paying for the session.`}
            </DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={clients}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params}
                  label="Paying Client"
                  required
                />}
                onChange={(event, newValue) => {
                  setSelectedClient(newValue);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => {
                onClose();
                setSelectedClient(null);
              }} variant="outlined">Cancel</Button>
              <Button
                type="submit"
                disabled={selectedClient === null}
                variant="contained"
                color="primary"
              >
                Select
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </form>
  </Dialog>
}

function RemoveClientDialog({ open, onClose, onRemove, client, hasRedirect }: {
  open: boolean;
  onClose: () => void;
  onRemove: () => void;
  client: SessionClient;
  hasRedirect: boolean;
}) {
  return <Dialog open={open} onClose={onClose}>
    <DialogTitle>Remove client</DialogTitle>
    <DialogContent>
      {`Are you sure you would like to remove ${client.name} from this session? This cannot be undone.`}
      {hasRedirect && <Typography>* Note: client was redirected to an external site after sign in.</Typography>}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button color="error" onClick={onRemove}>Remove</Button>
    </DialogActions>
  </Dialog>
}

export default function ActiveSession() {
  const qrSize = useMediaQuery((theme: any) => theme.breakpoints.up('sm')) ? 256 : 128;
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const {
    completeSession,
    deleteSession,
    getSession,
    sessions,
    setClientPayerId,
    removeClientFromOpenSession,
    changeOpenSessionDate,
  } = useSessions();
  const { clients } = useClients();
  const navigate = useNavigate();
  const { settings, lookupSessionType } = useSettings();
  const { sessionIdPath } = useParams();

  const { registerPayment, payments } = usePayments();

  const { stats, refreshStats } = useStats();

  const [loading, setLoading] = useState(true);

  const [confirmCancelOpen, setConfirmCancelOpen] = React.useState(false);

  const [cashPaymentDialogOpen, setCashPaymentDialogOpen] = React.useState(false);
  const [cashPaymentDialogClient, setCashPaymentDialogClient] = React.useState<SessionClient | null>(null);

  const [payingFriendDialogOpen, setPayingFriendDialogOpen] = React.useState(false);
  const [payingFriendDialogClient, setPayingFriendDialogClient] = React.useState<SessionClient | null>(null);

  const [removeClientDialogOpen, setRemoveClientDialogOpen] = React.useState(false);
  const [removeClientDialogClient, setRemoveClientDialogClient] = React.useState<SessionClient | null>(null);

  useEffect(() => {
    if (sessionIdPath) {
      getSession(sessionIdPath, () => {
        setLoading(false);
      }, console.error);
    }
  }, [sessionIdPath]);

  const session = sessions.find(session => session.id === sessionIdPath);

  const refreshSession = () => {
    getSession(sessionIdPath!, () => null, (error) => {
      // TODO: Handle this better.
      console.error(error.message);
    });
  };

  const cancel = () => {
    deleteSession(sessionIdPath!, () => {
      navigate(-1);
    }, (error) => {
      // TODO: Handle this better.
      console.error(JSON.stringify(error));
    });
  };

  const complete = () => {
    completeSession(session!,
      () => {
        navigate(-1);
      },
      //TODO: Handle this better:
      console.error)
  };

  const registerCashPayment = (client: SessionClient, payment: Payment) => {
    registerPayment(payment, () => {
      setCashPaymentDialogOpen(false);
      setCashPaymentDialogClient(null);
      refreshStats();
    }, console.error); // TODO: Handle error.
  }

  const setClientPayer = (client: SessionClient, payerId: string) => {
    setClientPayerId(client.signInId, payerId, () => {
      setPayingFriendDialogOpen(false);
      setPayingFriendDialogClient(null);
    }, console.error); // TODO: Handle error.
  }

  // Counts the number of times the client is attending the session or is paying for someone else.
  const getRequiredPaymentCount = (clientId: string) => {
    return session?.clients.filter(client => (client.paymentMethod === "Friend") ? (client.payerClientId === clientId) : (client.clientId === clientId)).length ?? 0;
  };

  // Determines how many sessions a client has paid ahead for, as if this session was already registered.
  // If they are behind on payments, this function returns a negative number.
  const getPaidAheadCount = (clientId: string) => {
    const paidAheadCount = stats.sessionPaymentCountsBySessionTypeId.find(count => count.sessionTypeId === session?.sessionTypeId)?.clientsPaidAhead.filter(client => client == clientId).length ?? 0;
    if (paidAheadCount === 0) {
      const unpaidCount = stats.sessionPaymentCountsBySessionTypeId.find(count => count.sessionTypeId === session?.sessionTypeId)?.unpaidClients.filter(client => client == clientId).length ?? 0;
      return -unpaidCount - getRequiredPaymentCount(clientId);
    } else {
      return paidAheadCount - getRequiredPaymentCount(clientId);
    }
  };

  const willOweMoney = (clientId: string) => {
    return getPaidAheadCount(clientId) < 0;
  };

  if (!loading) {
    const sessionType = lookupSessionType(session?.sessionTypeId ?? '');
    if (session && sessionType && session.state === "Open") {
      const clientCount = session.clients.length;
      const cashClientCount = session.clients.filter(client => client.paymentMethod === 'Cash').length;
      const unpaidCashClients = session.clients.filter(client => client.clientId && client.paymentMethod === "Cash" && willOweMoney(client.clientId));
      const unpaidCashClientCount = unpaidCashClients.length;
      // REF: https://stackoverflow.com/a/14438954
      const uniqueUnpaidCashClientIds = unpaidCashClients.map(client => client.clientId).filter((value, index, array) => array.indexOf(value) === index);

      const requiredCashPayments = uniqueUnpaidCashClientIds.map(client => -getPaidAheadCount(client)).reduce((a, b) => a + b, 0);

      const sessionPrice = dineroFromScaledNumber(sessionType!.price);
      const owedCash = multiply(sessionPrice, requiredCashPayments);

      return (
        <EffortlessPTPageWrapper title={'Active Session'}>
          <Grid container spacing={2} padding={'5px'}>
            <Grid item xs={12}>
              <DateHeading session={session} changeDate={(date, success) => {
                changeOpenSessionDate(date, success, console.error);
              }} />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1, flexWrap: 'wrap', spacing: 2 }}>
                  <SessionBadge badgeContent={clientCount} color="info">
                    <Chip variant="outlined" color="info" icon={<Face />} label={"Head Count"} />
                  </SessionBadge>
                  {cashClientCount > 0 &&
                    <React.Fragment>
                      <SessionBadge badgeContent={cashClientCount} color="info">
                        <Chip variant="outlined" color="info" icon={<AttachMoney />} label={"Cash Clients"} />
                      </SessionBadge>
                      <SessionBadge badgeContent={formatCurrency(owedCash)} color="info">
                        <Chip variant="outlined" color="info" icon={<AttachMoney />} label={"Cash owed"} />
                      </SessionBadge>
                    </React.Fragment>
                  }
                </Box>
                <Box>
                  <QRCodeSvg size={qrSize} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                name="Session Clients"
                columns={[
                  {
                    name: "Name",
                    ...isMobile ? { headGetter: () => "Client" } : {},
                    fieldName: 'name',
                  },
                  {
                    name: "Payment Method",
                    ...isMobile ? { headGetter: () => "" } : {},
                    removePadding: isMobile,
                    getter: (row) => {
                      if (row.paymentMethod === "Friend") {
                        const payingClient = clients.find(client => client.id === row.payerClientId);
                        return <Typography>
                          Friend: <Button
                            onClick={() => {
                              setPayingFriendDialogClient(row);
                              setPayingFriendDialogOpen(true);
                            }}
                            aria-label={payingClient ? payingClient.name : "Select"}
                          >
                            {payingClient ? payingClient.name : <HelpOutlined />}
                          </Button>
                        </Typography>
                      } else if (row.paymentMethod == "Cash" || row.paymentMethod.startsWith("pass")) {
                        const paymentMethodString = paymentMethodToString(row.paymentMethod, sessionType);
                        let statusIndicator;
                        let link = null;
                        if (row.paymentMethod == "Cash") {
                          if (row.clientId) {
                            if (willOweMoney(row.clientId)) {
                              statusIndicator = <UnpaidCashClientActions client={row}
                                setCashPaymentDialogClient={setCashPaymentDialogClient}
                                setCashPaymentDialogOpen={setCashPaymentDialogOpen}
                                registerCashPayment={payment => registerCashPayment(row, payment)}
                                sessionType={sessionType}
                                defaultNumberOfSessions={-getPaidAheadCount(row.clientId)} />
                            } else {
                              statusIndicator = <IconContainer name="Up to date"><AttachMoney color='success' /></IconContainer>
                            }
                          } else {
                            statusIndicator = <IconContainer name="Unknown client"><AttachMoney color='disabled' /></IconContainer>
                          }
                        } else {
                          const lastPassPayment = payments.filter(payment => (
                            payment.paymentFor && payment.paymentFor.length > 0 && payment.paymentFor[0].clientId === row.clientId && payment.paymentType === "Pass" && payment.pass?.passTypeId === row.paymentMethod.substring("pass:".length)
                          )).sort((a, b) => a.date.getTime() - b.date.getTime()).pop();
                          if (lastPassPayment) {
                            link = "/payment-details/pass/" + lastPassPayment?.id;
                          }
                          const paidAheadCount = getPaidAheadCount(row.clientId);
                          if (!lastPassPayment) {
                            statusIndicator = <IconContainer name="No pass"><Warning color='error' /></IconContainer>
                          } else if (paidAheadCount > 1) {
                            statusIndicator = <IconContainer name="Ahead"><DoneAll color='success' /></IconContainer>
                          } else if (paidAheadCount === 1) {
                            statusIndicator = <IconContainer name="Up to date"><Done color='success' /></IconContainer>
                          } else if (paidAheadCount === 0) {
                            statusIndicator = <IconContainer name="Pass complete"><Done color="warning" /></IconContainer>
                          } else {
                            statusIndicator = <IconContainer name="Behind"><NotificationImportant color='error' /></IconContainer>
                          }
                        }
                        return <Stack direction="row" alignItems="center" justifyItems="centre" spacing={1}>
                          {link ? <Link to={link}>{paymentMethodString}</Link> : <Typography>{paymentMethodString}</Typography>}
                          {statusIndicator}
                        </Stack>
                      } else {
                        return paymentMethodToString(row.paymentMethod, sessionType);
                      }
                    }
                  },
                  {
                    name: "Phone",
                    ...isMobile ? { headGetter: () => "" } : {},
                    removePadding: isMobile,
                    getter: (client) => {
                      const statusIndicator = client.clientId
                        ? <IconContainer name="Matched" sx={isMobile ? { padding: 0 } : undefined}><Done color='success' /></IconContainer>
                        : <UnknownClientActions client={client} sx={isMobile ? { padding: 0 } : undefined} />;
                      if (isMobile) {
                        return statusIndicator;
                      } else {
                        const renderedPhone = preparePhoneForRendering(client.phone);
                        return <Stack direction="row" alignItems="center" justifyItems="centre">{renderedPhone}{statusIndicator}</Stack>
                      }
                    }
                  },
                  {
                    name: "Actions",
                    ...isMobile ? { headGetter: () => "" } : {},
                    removePadding: isMobile,
                    getter: client => {
                      return <Button aria-label="Remove client" onClick={() => {
                        setRemoveClientDialogClient(client);
                        setRemoveClientDialogOpen(true);
                      }} sx={isMobile ? { padding: 0 } : {}} startIcon={<PersonRemove />}>{!isMobile && 'Remove Client'}</Button>
                    }
                  }
                ]}
                rows={session.clients} />
              {cashPaymentDialogClient && <CashPaidDialog open={cashPaymentDialogOpen} client={cashPaymentDialogClient}
                sessionType={sessionType}
                onClose={() => setCashPaymentDialogOpen(false)}
                onSubmit={payment => registerCashPayment(cashPaymentDialogClient, payment)} />}
              {payingFriendDialogClient && <PayingFriendSelectionDialog open={payingFriendDialogOpen} clients={clients}
                onClose={() => setPayingFriendDialogOpen(false)}
                onSubmit={payerId => setClientPayer(payingFriendDialogClient, payerId)} />}
              {removeClientDialogClient && <RemoveClientDialog open={removeClientDialogOpen} client={removeClientDialogClient}
                onClose={() => setRemoveClientDialogOpen(false)}
                onRemove={() => {
                  removeClientFromOpenSession(removeClientDialogClient.signInId, () => {
                    setRemoveClientDialogOpen(false);
                  }, console.error);
                }}
                hasRedirect={!!sessionType.redirectAfterSignInUrl} />}
            </Grid>
            <Grid item xs={12}>
              <RefreshTimerButton intervalSeconds={5} onRefresh={refreshSession} />
            </Grid>
            <Grid item xs={12}>
              <AddSessionClient sessionTypeId={sessionType.id} />
            </Grid>
            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Stack direction={"row"} spacing={1}>
                  <Button variant={'outlined'} onClick={() => {
                    if (session.clients.length > 0) {
                      setConfirmCancelOpen(true);
                    } else {
                    cancel();
                    }
                  }}>Cancel</Button>
                  <Button type={"submit"}
                    disabled={!canCompleteSession(session)}
                    onClick={() => complete()} variant={'contained'} color={"primary"}>Complete</Button>
                </Stack>
                <Dialog
                  open={confirmCancelOpen}
                  onClose={() => setConfirmCancelOpen(false)}
                  aria-labelledby="confirm-cancel-title"
                  aria-describedby="confirm-cancel-description"
                >
                  <DialogTitle id="confirm-cancel-title">Cancel the session?</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="confirm-cancel-description">
                      This will remove all signed in clients and discard the session. This cannot be undone.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setConfirmCancelOpen(false)} color="primary">Cancel</Button>
                    <Button onClick={() => {
                      setConfirmCancelOpen(false);
                      cancel();
                    }} color="primary" autoFocus>
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Grid>
          </Grid>
        </EffortlessPTPageWrapper>
      )
    } else { // The session id is wrong or the session is not found for another reason.
      return <Typography>Session not found</Typography>
    }
  } else {
    return <Typography>Loading...</Typography>
  }
}
