import { PaymentMethod } from "../../data/payment";
import { SessionType } from "../../data/session";

export default function paymentMethodToString(paymentMethod: PaymentMethod, sessionType: SessionType | undefined): string {
    if (paymentMethod === "EFT" || paymentMethod === "Cash") {
        return paymentMethod;
    } else {
        // Pass: `pass:${string}`
        const passTypeId = paymentMethod.substring("pass:".length);
        const pass = sessionType?.passTypes?.find((passType) => passType.id === passTypeId);
        return pass?.name ?? "Unknown pass type";
    }
}
