import {dinero, Dinero, down, greaterThan, multiply, toFormat, toUnit} from "dinero.js";
import {Transformer} from "@dinero.js/core/dist/esm/types";
import {AUD} from "@dinero.js/currencies";

export const CURRENCY_REGEX = /^[\d,]+(\.\d{0,2})?$/;

export const ZERO_DOLLARS = dineroFromScaledNumber(0)

export function formatNumber(dinero: Dinero<number>) {
  const transformer: Transformer<number> = ({ amount, currency }) => {
    return amount.toLocaleString(currency.code)
  };
  return toFormat<number>(dinero, transformer)
}
export function formatCurrency(dinero: Dinero<number>) {
  const transformer: Transformer<number> = ({ amount, currency }) => {
    return amount.toLocaleString('en-AU', {currency: currency.code, style: 'currency'})
  };
  return toFormat<number>(dinero, transformer)
}
export function dineroFromScaledNumber(amountX1000: number): Dinero<number> {
  return dinero({ amount:amountX1000, currency:AUD, scale:3 })
}
export function dineroToScaledNumber(dinero: Dinero<number>): number {
  return toUnit(multiply(dinero, 1000), { digits: 0, round: down })
}
export function dineroFromString(amount: string): Dinero<number> {
  const thousandsSeparatorStripped = amount.replaceAll(',','')
  const amountX1000 = Math.round(Number(thousandsSeparatorStripped) * 1000)
  return dineroFromScaledNumber(amountX1000)
}
export function greaterThanZeroDollars(dinero: Dinero<number>): boolean {
  return greaterThan(dinero, ZERO_DOLLARS)
}
