import { Button, ListItemIcon, Menu, MenuItem, SxProps } from '@mui/material';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';

export interface Option<T> {
    label: string;
    icon: ReactNode;
    value: T;
    disabled?: boolean;
}

export default function ButtonMenu<T>({ label, options, onSelected, variant, icon, sx, disabled }: {
    label: string;
    options: Option<T>[];
    onSelected: (value: T) => void;
    variant?: 'text' | 'outlined' | 'contained';
    icon: ReactNode;
    sx?: SxProps;
    disabled?: boolean;
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseHandler = () => {
        setAnchorEl(null);
    };

    const open = anchorEl !== null;

    const onValueSelected = (value: T) => {
        onCloseHandler();
        onSelected(value);
    }

const uniqueKey = useMemo(() => Math.random().toString(36).substring(7), []);

    return <Fragment>
        <Button
            sx={sx}
            onClick={onClickHandler}
            disabled={disabled}
            variant={variant === 'text' ? undefined : variant}
            aria-controls={open ? `menu-${uniqueKey}` : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            startIcon={icon}
        >
            {label}
        </Button>
        <Menu
            anchorEl={anchorEl}
            id={`menu-${uniqueKey}`}
            open={open}
            onClose={onCloseHandler}
            onClick={onCloseHandler}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {options.map((option, index) => (
                <MenuItem
                    key={index}
                    onClick={() => onValueSelected(option.value)}
                    disabled={option.disabled}
                    sx={{
                        whiteSpace: "unset",
                        wordBreak: "break-word"
                    }}
                >
                    <ListItemIcon>
                        {option.icon}
                    </ListItemIcon>
                    {option.label}
                </MenuItem>
            ))}
        </Menu>
    </Fragment>
}
