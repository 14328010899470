import React, { useEffect, useState } from 'react';
import { Session } from '../../data/session';
import { useSessions, useSettings } from './EffortlessPTContext';
import { Group, Restore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getSessionDate } from './date';
import { canCompleteSession } from './pages/ActiveSession';
import { Button } from '@mui/material';
import IconMenu from './IconMenu';
import ButtonMenu from './ButtonMenu';

export default function ActiveSessionButton() {
    const navigate = useNavigate();
    const { settings, lookupSessionType } = useSettings();
    const { sessions, openSession, completeSession, deleteSession } = useSessions();

    const [currentlyOpenSession, setCurrentlyOpenSession] = useState<Session | undefined>(undefined);

    let groupSessionTypes = settings.sessionTypes.filter(sessionType => !sessionType.individual);

    const [sessionTypeToOpen, setSessionTypeToOpen] = useState<string | undefined>(localStorage.getItem('defaultSessionType') ?? groupSessionTypes.at(0)?.id);

    useEffect(() => {
        if (sessionTypeToOpen) {
            localStorage.setItem('defaultSessionType', sessionTypeToOpen);
        }
    }, [sessionTypeToOpen]);

    const [callingApi, setCallingApi] = useState(false);

    useEffect(() => {
        if (!callingApi) {
            setCurrentlyOpenSession(sessions.find(session => session.state === 'Open'));
        }
    }, [sessions, callingApi]);

    const startSession = (sessionTypeId: string) => {
        setCallingApi(true);
        openSession({
            state: "Open",
            sessionTypeId: sessionTypeId,
            date: getSessionDate(),
            clients: [],
            trainerId: ""
        }, id => {
            setCallingApi(false);
            navigate(`/active-session/${id}`);
        }, console.error);
    }

    if (currentlyOpenSession) {
        return <ButtonMenu
            label='Resume or start a session...'
            icon={<Restore />}
            variant='contained'
            options={[
                {
                    label: `Resume the ${lookupSessionType(currentlyOpenSession.sessionTypeId)?.name ?? 'unknown'} session`,
                    icon: <Restore />,
                    value: 'resume'
                },
                ...currentlyOpenSession.clients.length > 0
                    ? groupSessionTypes.map(sessionType => ({
                        label: `Complete active and start a new ${sessionType.name} session`,
                        disabled: !canCompleteSession(currentlyOpenSession!),
                        icon: <Group />,
                        value: sessionType.id
                    }))
                    : groupSessionTypes.map(sessionType => ({
                        label: `Start a new ${sessionType.name} session`,
                        icon: <Group />,
                        value: sessionType.id
                    }))
            ]}
            onSelected={(action) => {
                if (action === 'resume') {
                    navigate(`/active-session/${currentlyOpenSession?.id}`);
                } else if (currentlyOpenSession!.clients.length > 0) {
                    const sessionTypeId = action as string;
                    setCallingApi(true);
                    completeSession(currentlyOpenSession!, () => {
                        startSession(sessionTypeId);
                    }, console.error);
                } else {
                    const sessionTypeId = action as string;
                    // Delete it (since its empty anyway)
                    setCallingApi(true);
                    deleteSession(currentlyOpenSession!.id!, () => {
                        startSession(sessionTypeId);
                    }, console.error);
                }
            }}
            disabled={callingApi}
        />
    } else if (groupSessionTypes.length > 0) {
        if (groupSessionTypes.length > 1) {
            return <ButtonMenu
                label='Start a session...'
                variant='contained'
                icon={<Group />}
                disabled={callingApi}
                options={groupSessionTypes.map(sessionType => ({
                    label: `Start a ${sessionType.name} session`,
                    icon: <Group />,
                    value: sessionType.id
                }))}
                onSelected={(action) => {
                    const sessionTypeId = action as string;
                    startSession(sessionTypeId);
                }}
            />
        } else {
            return <Button
                variant="contained"
                startIcon={<Group />}
                disabled={callingApi}
                onClick={() => startSession(groupSessionTypes[0].id)}>
                {`Start a ${groupSessionTypes[0].name} session`}
            </Button>
        }
    } else {
        return <Button
            variant="contained"
            startIcon={<Group />}
            disabled>
            {`Start a session...`}
        </Button>
    }
}
