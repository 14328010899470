import { Box } from "@mui/material";
import {
    DatePicker,
    DateTimePicker, LocalizationProvider,
    renderDigitalClockTimeView
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";

export function EditableDate({ value, onChange, includeTime, autoFocus, onKeyDown }: {
    value: Date,
    onChange: (date: Date | null) => void,
    includeTime: boolean,
    autoFocus?: boolean,
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
}) {
    return <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {includeTime ?
                <DateTimePicker
                    autoFocus={autoFocus}
                    timeSteps={{
                        minutes: 30
                    }}
                    ampm={false}
                    views={['day', 'hours']}
                    viewRenderers={{
                        hours: renderDigitalClockTimeView,
                    }}
                    label="Date"
                    format="dd/MM/yyyy HH:mm"
                    value={value}
                    onChange={date => onChange(date)}
                    slotProps={{ textField: { variant: 'standard', sx: { width: '170px' }, onKeyDown } }}
                    enableAccessibleFieldDOMStructure
                />
                :
                <DatePicker
                    autoFocus={autoFocus}
                    views={['day']}
                    label="Date"
                    format="dd/MM/yyyy"
                    value={value}
                    onChange={date => onChange(date)}
                    slotProps={{ textField: { variant: 'standard', sx: { width: '170px' }, onKeyDown } }}
                    enableAccessibleFieldDOMStructure
                />
            }
        </LocalizationProvider>
    </Box>
}