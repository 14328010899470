import * as React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import NoPage from './pages/NoPage'
import PaymentSuccess from './pages/PaymentSuccess'
import EditClient from './pages/EditClient'
import ActiveSession from './pages/ActiveSession'
import SignInSheet from './pages/SignInSheet'
import EmailVerified from './pages/EmailVerified'
import SessionPaymentDetails from "./pages/SessionPaymentDetails"
import PassPaymentDetails from "./pages/PassPaymentDetails"
import RegisterPayment from "./pages/RegisterPayment"
import ClientSelfSignIn from './pages/ClientSelfSignIn'
import ReconcileBankTransactions from "./pages/ReconcileBankTransactions"
import Branches from "./pages/Branches"
import Settings from './pages/Settings'
import SessionDetails from './pages/SessionDetails'
import SampleData from './pages/SampleData'
import ClientPayments from "./pages/ClientPayments";

export default function App() {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="dashboard/:tabName" element={<Dashboard />} />
      <Route index element={<Navigate replace to="/dashboard" />} />
      <Route path="settings" element={<Settings />} />
      <Route path="client-sign-in/:trainerPath" element={<ClientSelfSignIn />} />
      <Route path="trainer-client-sign-in/:trainerPath/:clientName/:clientPhone" element={<ClientSelfSignIn trainerSignIn />} />
      <Route path="payment-success" element={<PaymentSuccess />} />
      <Route path="edit-client/:id" element={<EditClient />} />
      <Route path="client-payments/:clientId" element={<ClientPayments />} />
      <Route path="add-client" element={<EditClient />} />
      <Route path="active-session/:sessionIdPath" element={<ActiveSession />} />
      <Route path="session-details/:id" element={<SessionDetails />} />
      <Route path="sign-in-sheet" element={<SignInSheet />} />
      <Route path="email-verified" element={<EmailVerified />} />
      <Route path="payment-details/session/:id" element={<SessionPaymentDetails />} />
      <Route path="payment-details/pass/:id" element={<PassPaymentDetails />} />
      <Route path="payment/:paymentTypePath" element={<RegisterPayment />} />
      <Route path="payment/:paymentTypePath/:paymentIndexPath" element={<RegisterPayment />} />
      <Route path="reconcile-bank-transactions/:step" element={<ReconcileBankTransactions />} />
      <Route path="reconcile-bank-transactions" element={<ReconcileBankTransactions />} />
      <Route path="branches" element={<Branches />} />
      <Route path="sample-data" element={<SampleData />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  )
}
