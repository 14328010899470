import * as React from 'react';
import {Box} from "@mui/material";

export default function TrainerLogo({width, maxWidth}: {width?: string, maxWidth?: string}) {
    //TODO: Make a dynamic logo setting that lets trainers upload their logo
    return <Box
      component="img"
      sx={{
          width,
          maxWidth,
      }}
      alt="Chocolate Fitness Logo"
      src={"chocolate-logo-small.png"}
    />
}
