import { useAuth0 } from '@auth0/auth0-react';
import { Logout, Settings } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import IconMenu from './IconMenu';

export default function AvatarButton() {
    const { user, logout } = useAuth0();
    const navigate = useNavigate();
    if (user) {
        return (
            <IconMenu
                icon={<Avatar sx={{ width: 32, height: 32 }} src={user.picture}/>}
                title="Account menu"
                label=""
                uniqueKey="avatar-button"
                options={[
                    {
                        name: "Settings",
                        description: "Change your settings",
                        icon: <Settings />,
                        value: "settings"
                    },
                    {
                        name: "Log out",
                        description: "Log out of Effortless PT",
                        icon: <Logout />,
                        value: "logout"
                    }
                ]}
                onSelected={option => {
                    switch (option) {
                        case "settings": {
                            navigate("/settings");
                            break;
                        }
                        case "logout": {
                            logout({ returnTo: window.location.origin });
                            break;
                        }
                        default:
                            console.error(`Unknown option ${option}`);
                    }
                }}
            />
        )
    } else {
        return null;
    }
}
