import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    FormControl, FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    List,
    ListItem,
    Paper,
    Stack,
    Switch,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionType } from '../../../data/session';
import { PassType } from '../../../data/pass';
import { CURRENCY_REGEX, dineroFromScaledNumber, dineroFromString, formatNumber } from '../currency';
import { usePayments, useSessions, useSettings } from '../EffortlessPTContext';
import EffortlessPTPageWrapper from '../EffortlessPTPageWrapper';
import { Add, Cancel, Delete, Done, Edit, Save } from '@mui/icons-material';
import DataTable from '../DataTable';

function useCanDeleteSessionType() {
    const { sessions } = useSessions();
    const { payments } = usePayments();
    return useCallback((sessionType: SessionType) => {
        if (sessions.find(s => s.sessionTypeId === sessionType.id)) {
            return false;
        }
        for (const payment of payments) {
            for (const paymentFor of payment.paymentFor) {
                if (paymentFor.sessionTypeId === sessionType.id) {
                    return false;
                }
            }
        }
        return true;
    }, [sessions, payments]);
}
function useCanDeletePassType() {
    const { sessions } = useSessions();
    const { payments } = usePayments();

    return useCallback((passType: PassType) => {
        for (const session of sessions) {
            for (const sessionClient of session.clients) {
                if (sessionClient.paymentMethod === `pass:${passType.id}`) {
                    return false;
                }
            }
        }
        if (payments.find(payment => payment.pass?.passTypeId === passType.id)) {
            return false;
        }
        return true;
    }, [sessions, payments]);
}

function SessionTypeEditor({ sessionType, onSubmit }: {
    sessionType: SessionType;
    onSubmit: (sessionType: SessionType) => void;
}) {
    const [name, setName] = useState(sessionType.name);
    const [price, setPrice] = useState(sessionType.priceString !== '0' ? sessionType.priceString : '');
    const [individual, setIndividual] = useState(sessionType.individual);
    const [redirectAfterSignInUrl, setRedirectAfterSignInUrl] = useState(sessionType.redirectAfterSignInUrl);
    const [redirectAfterSignInUrlTemplated, setRedirectAfterSignInUrlTemplated] = useState(sessionType.redirectAfterSignInUrlTemplated);
    const [agreements, setAgreements] = useState(sessionType.agreements ?? []);

    const [nameError, setNameError] = useState(false);
    const [priceError, setPriceError] = useState(false);

    return (
        <Grid container spacing={2} padding={1}>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth variant='standard'>
                    <InputLabel htmlFor='session-type-name'>Name</InputLabel>
                    <Input
                        id='session-type-name'
                        error={nameError}
                        aria-describedby='session-type-name-helper-text'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        autoComplete="off"
                    />
                    <FormHelperText id='session-type-name-helper-text'>The session type&apos;s name.</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth variant='standard'>
                    <InputLabel htmlFor='session-type-price'>Price</InputLabel>
                    <Input
                        id='session-type-price'
                        startAdornment="$"
                        error={priceError}
                        aria-describedby='session-type-price-helper-text'
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                        inputProps={{
                            pattern: CURRENCY_REGEX.source,
                            title: 'Price must be a valid currency amount.',
                        }}
                        autoComplete="off"
                    />
                    <FormHelperText id='session-type-price-helper-text'>The session type&apos;s price.</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
                <FormControl fullWidth variant="standard">
                    <FormControlLabel control={<Switch checked={individual} onChange={(e) => setIndividual(e.target.checked)} id="session-type-individual" aria-describedby="session-type-individual-helper-text" />} label={"Individual"} />
                    <FormHelperText id="session-type-individual-helper-text">Whether this session type is for individual clients.</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
                <Box display="flex" justifyContent={{ xs: "flex-start", md: "flex-end" }}>
                    <Button
                        variant='outlined'
                        onClick={() => {
                            let valid = true;
                            if (name === '') {
                                setNameError(true);
                                valid = false;
                            } else {
                                setNameError(false);
                            }
                            if (price === '' || !CURRENCY_REGEX.test(price!)) {
                                setPriceError(true);
                                valid = false;
                            } else {
                                setPriceError(false);
                            }
                            if (valid) {
                                const newSessionType = {
                                    ...sessionType,
                                    name,
                                    price: dineroFromString(price!).toJSON().amount,
                                    priceString: price,
                                    individual,
                                    redirectAfterSignInUrl,
                                    redirectAfterSignInUrlTemplated,
                                    agreements,
                                };
                                onSubmit(newSessionType);
                            }
                        }}
                        endIcon={<Done />}
                    >
                        Done
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Accordion>
                    <AccordionSummary
                        aria-controls="advanced-content"
                        id="advanced-header"
                    >
                        <Typography variant="h5">Advanced</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} padding={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel htmlFor="redirect-after-client-sign-in">Redirect after client sign in</InputLabel>
                                    <Input
                                        id="redirect-after-client-sign-in"
                                        value={redirectAfterSignInUrl ?? ''}
                                        onChange={(event) => {
                                            setRedirectAfterSignInUrl(event.target.value || undefined);
                                            setRedirectAfterSignInUrlTemplated(undefined);
                                        }}
                                        aria-describedby="redirect-after-client-sign-in-helper-text"
                                        autoComplete="off"
                                    />
                                    <FormHelperText id="redirect-after-client-sign-in-helper-text">The URL to redirect to after a client signs in. This can be used to connect with an existing sign-in process.</FormHelperText>
                                </FormControl>
                            </Grid>
                            {agreements.map((agreement, index) => (
                                <Grid item xs={12} key={index}>
                                    <Grid component={Paper} container rowGap={2} direction={"column"} padding={1}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel htmlFor={`agreement-checkbox-${index}`}>Agreement {index + 1} Checkbox Text</InputLabel>
                                                <Input
                                                    id={`agreement-checkbox-${index}`}
                                                    value={agreement.checkboxText}
                                                    onChange={(event) => {
                                                        const newAgreements = [...agreements];
                                                        newAgreements[index].checkboxText = event.target.value;
                                                        setAgreements(newAgreements);
                                                    }}
                                                    aria-describedby={`agreement-${index}-checkbox-helper-text`}
                                                    autoComplete="off"
                                                />
                                                <FormHelperText id={`agreement-${index}-checkbox-helper-text`}>The short text to display on the checkbox.</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel htmlFor={`agreement-text-${index}`}>Agreement {index + 1} Text</InputLabel>
                                                <Input
                                                    id={`agreement-text-${index}`}
                                                    value={agreement.longText}
                                                    multiline
                                                    onChange={(event) => {
                                                        const newAgreements = [...agreements];
                                                        newAgreements[index].longText = event.target.value;
                                                        setAgreements(newAgreements);
                                                    }}
                                                    aria-describedby={`agreement-${index}-text-helper-text`}
                                                    autoComplete="off"
                                                />
                                                <FormHelperText id={`agreement-${index}-text-helper-text`}>The full text of the agreement.</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="outlined" onClick={() => {
                                                const newAgreements = [...agreements];
                                                newAgreements.splice(index, 1);
                                                setAgreements(newAgreements);
                                            }
                                            } endIcon={<Delete />}>
                                                Delete Agreement
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setAgreements([...agreements, { checkboxText: '', longText: '' }])}
                                    endIcon={<Add />}
                                >
                                    Add Agreement
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )
}

function PassTypeEditor({ passType, onSubmit }: {
    passType: PassType;
    onSubmit: (passType: PassType) => void;
}) {
    const [name, setName] = useState(passType.name);
    const [price, setPrice] = useState(passType.priceString !== '0' ? passType.priceString : '');
    const [sessionCount, setSessionCount] = useState(passType.sessionCount !== 0 ? passType.sessionCount.toString() : '');
    const [expiresAfterDays, setExpiresAfterDays] = useState(passType.expiresAfterDays !== 0 ? passType.expiresAfterDays.toString() : '');

    const [nameError, setNameError] = useState(false);
    const [priceError, setPriceError] = useState(false);
    const [sessionCountError, setSessionCountError] = useState(false);
    const [expiresAfterDaysError, setExpiresAfterDaysError] = useState(false);

    return (
        <Grid container spacing={2} padding="5px" alignItems={"center"}>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="pass-type-name">Name</InputLabel>
                    <Input
                        id="pass-type-name"
                        error={nameError}
                        autoComplete="off"
                        aria-describedby="pass-type-name-helper-text"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            if (/^\d*\s[Pp]ass$/.test(e.target.value)) {
                                setSessionCount(e.target.value.replace(/ [pP]ass/g, ""));
                            }
                        }}
                        required
                    />
                    <FormHelperText id="pass-type-name-helper-text">The pass type&apos;s name.</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="pass-type-price">Price</InputLabel>
                    <Input
                        id="pass-type-price"
                        aria-describedby="pass-type-price-helper-text"
                        startAdornment="$"
                        error={priceError}
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                        inputProps={{
                            pattern: CURRENCY_REGEX.source,
                            title: "Price must be a currency."
                        }}
                        autoComplete="off"
                    />
                    <FormHelperText id="pass-type-price-helper-text">The pass type&apos;s price.</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="pass-type-session-count">Session Count</InputLabel>
                    <Input
                        type="number"
                        id="pass-type-session-count"
                        aria-describedby="pass-type-session-count-helper-text"
                        error={sessionCountError}
                        value={sessionCount}
                        onChange={(e) => {
                            setSessionCount(e.target.value)
                            if (e.target.value !== '' && (name === '' || /^\d*\sPass$/.test(name))) {
                                setName(`${e.target.value} Pass`);
                            } else if (e.target.value !== '' && (/^\d*\spass$/.test(name))) {
                                setName(`${e.target.value} pass`);
                            }
                        }}
                        required
                    />
                    <FormHelperText id="pass-type-session-count-helper-text">The number of sessions on the pass.</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="pass-type-expires-after-days">Expires After Days</InputLabel>
                    <Input
                        type="number"
                        id="pass-type-expires-after-days"
                        aria-describedby="pass-type-expires-after-days-helper-text"
                        error={expiresAfterDaysError}
                        value={expiresAfterDays}
                        onChange={(e) => setExpiresAfterDays(e.target.value)}
                        required
                    />
                    <FormHelperText id="pass-type-expires-after-days-helper-text">The pass will expire after {expiresAfterDays || "x"} day{expiresAfterDays === "1" ? "" : "s"}.</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
                <Box display="flex" justifyContent={{ xs: "flex-start", md: "flex-end" }}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            let valid = true;
                            if (name === '') {
                                setNameError(true);
                                valid = false;
                            }
                            if (price === '' || !CURRENCY_REGEX.test(price!)) {
                                setPriceError(true);
                                valid = false;
                            }
                            if (sessionCount === '' || !/^\d+$/.test(sessionCount)) {
                                setSessionCountError(true);
                                valid = false;
                            }
                            if (expiresAfterDays === '' || !/^\d+$/.test(expiresAfterDays)) {
                                setExpiresAfterDaysError(true);
                                valid = false;
                            }
                            if (valid) {
                                const newPassType: PassType = {
                                    ...passType,
                                    name,
                                    price: dineroFromString(price!).toJSON().amount,
                                    priceString: undefined,
                                    sessionCount: parseInt(sessionCount),
                                    expiresAfterDays: parseInt(expiresAfterDays),
                                }
                                onSubmit(newPassType);
                            }
                        }}
                        endIcon={<Done />}
                    >
                        Done
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default function Settings() {
    const { settings, updateSettings } = useSettings();
    const navigate = useNavigate();
    const canDeleteSessionType = useCanDeleteSessionType();
    const canDeletePassType = useCanDeletePassType();

    const [settingsCopy, setSettingsCopy] = useState(settings);
    // Change our copy whenever the context changes.
    React.useEffect(() => {
        setSettingsCopy(settings);
    }, [settings]);

    const addSessionType = () => {
        setSettingsCopy({
            ...settingsCopy,
            sessionTypes: [...settingsCopy.sessionTypes, { name: '', price: 0, id: crypto.randomUUID(), individual: false }],
        });
    }

    const updateSessionType = (sessionType: SessionType) => {
        const newSessionTypes = settingsCopy.sessionTypes.map(otherSessionType => otherSessionType.id === sessionType.id ? sessionType : otherSessionType);
        setSettingsCopy({ ...settingsCopy, sessionTypes: newSessionTypes });
    }

    const deleteSessionType = (sessionType: SessionType) => {
        const newSessionTypes = settingsCopy.sessionTypes.filter(otherSessionType => otherSessionType.id !== sessionType.id);
        setSettingsCopy({ ...settingsCopy, sessionTypes: newSessionTypes });
    }

    const addPassType = (sessionType: SessionType) => {
        updateSessionType({ ...sessionType, passTypes: [...(sessionType.passTypes ?? []), { id: crypto.randomUUID(), name: '', price: 0, sessionCount: 0, expiresAfterDays: 365 }] });
    }

    const updatePassType = (sessionType: SessionType, passType: PassType) => {
        const newPassTypes = sessionType.passTypes?.map(otherPassType => otherPassType.id === passType.id ? passType : otherPassType);
        updateSessionType({ ...sessionType, passTypes: newPassTypes });
    }

    const deletePassType = (sessionType: SessionType, passType: PassType) => {
        const newPassTypes = sessionType.passTypes?.filter(otherPassType => otherPassType.id !== passType.id);
        updateSessionType({ ...sessionType, passTypes: newPassTypes });
    }

    // Add the priceString attributes to the session and pass types.
    useEffect(() => {
        const newSettings = settingsCopy;
        let changesMade = false;
        for (const sessionType of newSettings.sessionTypes) {
            if (sessionType.priceString === undefined) {
                sessionType.priceString = formatNumber(dineroFromScaledNumber(sessionType.price));
                changesMade = true;
            }
            for (const passType of sessionType.passTypes ?? []) {
                if (passType.priceString === undefined) {
                    passType.priceString = formatNumber(dineroFromScaledNumber(passType.price));
                    changesMade = true;
                }
            }
        }
        if (changesMade) {
            setSettingsCopy({ ...newSettings });
        }
    }, [settingsCopy]);

    const [selectedSessionType, setSelectedSessionType] = useState<SessionType | null>(null);
    const [selectedPassType, setSelectedPassType] = useState<PassType | null>(null);

    useEffect(() => {
        for (const sessionType of settingsCopy.sessionTypes) {
            if (sessionType.name === '') {
                editSessionType(sessionType);
                return;
            }
            for (const passType of sessionType.passTypes ?? []) {
                if (passType.name === '') {
                    editPassType(sessionType, passType);
                    return;
                }
            }
        }
    }, [settingsCopy.sessionTypes]);

    const editPassType = (sessionType: SessionType, passType: PassType) => {
        setSelectedSessionType(sessionType);
        setSelectedPassType(passType);
    }
    const editSessionType = (sessionType: SessionType) => {
        setSelectedSessionType(sessionType);
        setSelectedPassType(null);
    }

    return (
        <EffortlessPTPageWrapper title="Settings">
            <form onSubmit={(event) => {
                event.preventDefault();
                // Remove the priceString fields from the sessionTypes and passTypes.
                for (const sessionType of settingsCopy.sessionTypes) {
                    delete sessionType.priceString;
                    for (const passType of sessionType.passTypes ?? []) {
                        delete passType.priceString;
                    }
                }
                updateSettings(settingsCopy, () => {
                    navigate(-1);
                }, console.error);
            }}>
                <Grid component={Paper} container rowGap={2} direction={"column"} padding={1}>
                    <Grid item columns={12}>
                        <Typography variant={"h6"}>Session Types</Typography>
                    </Grid>
                    <Grid item columns={12}>
                        <DataTable name="Session types" rows={settingsCopy.sessionTypes.filter(sessionType => sessionType.name !== '')} columns={[
                            {
                                name: "Name",
                                fieldName: "name"
                            },
                            {
                                name: "Price",
                                getter: (sessionType) => `$${sessionType.priceString}`,
                            },
                            {
                                name: "Pass Types",
                                getter: (sessionType) => (
                                    <React.Fragment key={sessionType.id}>
                                        <List>
                                            {sessionType.passTypes?.map(passType => (
                                                passType.name !== '' && <ListItem key={passType.id}>
                                                    <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"flex-start"} spacing={1}>
                                                        <Typography marginLeft="-10px" paddingRight={{ xs: 0, md: 2 }} variant={"body2"}>{passType.name}</Typography>
                                                        <IconButton disabled={selectedSessionType !== null} onClick={() => editPassType(sessionType, passType)} aria-label="Edit">
                                                            <Tooltip title="Edit">
                                                                <Edit />
                                                            </Tooltip>
                                                        </IconButton>
                                                        <IconButton onClick={() => deletePassType(sessionType, passType)} disabled={!canDeletePassType(passType)} aria-label="Delete">
                                                            <Tooltip title="Delete">
                                                                <Delete />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Stack>
                                                </ListItem>
                                            ))}
                                        </List>
                                        <Button
                                            endIcon={<Add />}
                                            onClick={() => addPassType(sessionType)}
                                            disabled={selectedSessionType !== null}
                                        >
                                            Add
                                        </Button>


                                    </React.Fragment>
                                )
                            },
                            {
                                name: "Actions",
                                getter: (sessionType) => (
                                    <React.Fragment>
                                        <IconButton onClick={() => editSessionType(sessionType)} disabled={selectedSessionType !== null} aria-label="Edit">
                                            <Tooltip title="Edit">
                                                <Edit />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton onClick={() => deleteSessionType(sessionType)} disabled={!canDeleteSessionType(sessionType)} aria-label="Delete">
                                            <Tooltip title="Delete">
                                                <Delete />
                                            </Tooltip>
                                        </IconButton>
                                    </React.Fragment>
                                )
                            }
                        ]}
                            renderTableContents={(head, body) => (
                                <React.Fragment>
                                    <TableHead>
                                        {head}
                                    </TableHead>
                                    <TableBody>
                                        {body}
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Button
                                                    endIcon={<Add />}
                                                    onClick={addSessionType}
                                                    disabled={selectedSessionType !== null || settingsCopy.sessionTypes.find(sessionType => sessionType.name === '') !== undefined}
                                                >
                                                    Add
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        {(selectedSessionType && selectedPassType) && <TableRow>
                                            <TableCell colSpan={4}><PassTypeEditor passType={selectedPassType} onSubmit={(passType) => {
                                                updatePassType(selectedSessionType, passType);
                                                setSelectedSessionType(null);
                                                setSelectedPassType(null);
                                            }} /></TableCell>
                                        </TableRow>}
                                        {(selectedSessionType && !selectedPassType) && <TableRow>
                                            <TableCell colSpan={4}><SessionTypeEditor sessionType={selectedSessionType} onSubmit={(sessionType) => {
                                                updateSessionType(sessionType);
                                                setSelectedSessionType(null);
                                            }} /></TableCell>
                                        </TableRow>}

                                    </TableBody>
                                </React.Fragment>
                            )}
                        />
                    </Grid>
                    <Grid item columns={12}>
                        <Divider />
                    </Grid>
                    <Grid item columns={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Stack direction={"row"} spacing={1}>
                                <Button variant='outlined' onClick={() => navigate(-1)} endIcon={<Cancel />}>Cancel</Button>
                                <Button disabled={selectedSessionType !== null} type="submit" variant='contained' endIcon={<Save />}>Save</Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </EffortlessPTPageWrapper>
    )
}
