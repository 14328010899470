import React from "react";
import {Box, Paper, Typography, useTheme} from "@mui/material";
import {DashboardOverview} from "./dashboard-overview/DashboardOverview";

const paddingMargins = '10px';

interface EffortlessPTPageWrapperProps {
  /**
   * The title to display in the page header.
   */
  title?: string,
  /**
   * An optional widget node to display to the right of the page header.
   */
  widget?: React.ReactNode,
  /**
   * The page body node.
   */
  children: React.ReactNode
}

export default function EffortlessPTPageWrapper(props: EffortlessPTPageWrapperProps) {
  let {title, widget, children} = props;
  let theme = useTheme();
  return (
    <Box position={"static"}>
      <Box paddingLeft={paddingMargins} paddingRight={paddingMargins} bgcolor={theme.palette.primary.light}
           paddingTop={'32px'} paddingBottom={'48px'}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography color={theme.palette.primary.contrastText} variant={"h4"} gutterBottom={true}>{title}</Typography>
          </Box>
          <Box>
            {widget}
          </Box>
        </Box>
      </Box>
      <Box paddingLeft={paddingMargins} paddingRight={paddingMargins} position={'relative'} style={{top: '-20px'}}>
        <Paper elevation={2} square={false}>
          {children}
        </Paper>
      </Box>
    </Box>
  );
}
