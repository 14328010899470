import React, {Fragment, useEffect, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Autocomplete, Grid, Box, Stack, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, IconButton
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CheckIcon from '@mui/icons-material/Check';
import {useClients, usePayments, useSessions, useSettings} from "../EffortlessPTContext";
import {Link, useNavigate, useParams} from "react-router-dom";
import {SessionPayment} from "../../../data/session-payment";
import {formatDate} from "../date";
import {SessionType} from "../../../data/session";
import {Client} from "../../../data/client";
import EffortlessPTPageWrapper from "../EffortlessPTPageWrapper";
import {dineroFromScaledNumber, formatCurrency} from "../currency";
import IconContainer from '../IconContainer';

interface SessionTypeStat {
    id: string;
    name: string;
    price: number;
    attendedCount: number;
    paidCount: number;
}

export type SessionFilter = "All" | "Unpaid";

const ClientPaymentsView = () => {
    const { clientId } = useParams();
    const {getClientSessionPayments, lookupPayment} = usePayments();
    const {lookupSessionType, settings} = useSettings();
    const {registerSession} = useSessions();
    const {lookupClient, clients} = useClients();
    const [sessionPayments, setSessionPayments] = useState<SessionPayment[]>([]);
    const [sessionTypeStats, setSessionTypeStats] = useState<SessionTypeStat[]>([]);
    const [newSessionDate, setNewSessionDate] = useState<Date | null>(null);
    const [newSessionType, setNewSessionType] = useState<SessionType | null>(null);
    const [client, setClient] = useState<Client | null>(null);
    const navigate = useNavigate();
    const [sessionFilter, setSessionFilter] = useState<SessionFilter>('All');
    const [registeringSession, setRegisteringSession] = useState(false);

    function refreshPayments(clientId: string) {
        getClientSessionPayments(clientId, (sessionPayments) => {
            try {
                setSessionPayments(sessionPayments.results);
                const sessionTypeStats: SessionTypeStat[] = [];
                sessionPayments.results.forEach(sessionPayment => {
                    const sessionTypeStat = sessionTypeStats.find(stat => stat.id === sessionPayment.sessionTypeId);
                    if (sessionTypeStat) {
                        if (sessionPayment.sessionId) {
                            sessionTypeStat.attendedCount++;
                        }
                        if (sessionPayment.paymentId) {
                            sessionTypeStat.paidCount++;
                        }
                    } else {
                        const sessionType = lookupSessionType(sessionPayment.sessionTypeId!);
                        sessionTypeStats.push({
                            id: sessionPayment.sessionTypeId!,
                            name: sessionType?.name || '',
                            price: sessionType?.price || 0,
                            attendedCount: sessionPayment.sessionId ? 1 : 0,
                            paidCount: sessionPayment.paymentId ? 1 : 0,
                        });
                    }
                });
                setSessionTypeStats(sessionTypeStats);
            } catch (error) {
                console.error(`Error processing session payments data: ${error}`);
            }
        }, error => {
            console.error(`Error getting session payments: ${error}`);
        });
    }

    useEffect(() => {
        if (clientId && clients) {
            const lookedUpClient = lookupClient(clientId);
            setClient(lookedUpClient);
            refreshPayments(clientId);
        }
    }, [clientId, clients]);

    const handleRegisterSession = () => {
        if (newSessionDate && client && clientId && newSessionType) {
            setRegisteringSession(true);
            registerSession({
                    clients: [
                        {
                            clientId,
                            name: client.name,
                            phone: client.phone,
                            paymentMethod: "EFT",
                            signInId: crypto.randomUUID(),
                            signature: {
                                date: newSessionDate,
                                device: '',
                                selfSigned: false,
                                ip: ''
                            }
                        }
                    ],
                    date: newSessionDate,
                    sessionTypeId: newSessionType.id,
                    state: "Complete",
                    trainerId: ''
                },
                () => {
                    refreshPayments(clientId);
                    setNewSessionDate(null);
                    setRegisteringSession(false);
                },
                error => {
                    console.log(`${error} adding session for ${client.name}`)
                    setRegisteringSession(false);
                }
            )
        }
    };

    function isPassPayment(paymentId: string|null) {
        if (paymentId) {
            const payment = lookupPayment(paymentId);
            if (payment) {
                if (payment.paymentType === 'Pass') {
                    return true;
                }
            } else {
                console.error(`Payment not found for id ${paymentId}`);
            }
        }
        return false;
    }

    function calculateOwingAmountForSessionType(sessionTypeStat: SessionTypeStat) {
        let balance = sessionTypeStat.attendedCount * sessionTypeStat.price - sessionTypeStat.paidCount * sessionTypeStat.price;
        if (balance < 0) {
            return `$0 ( ${sessionTypeStat.paidCount-sessionTypeStat.attendedCount} ahead )`;
        } else {
            return formatCurrency(dineroFromScaledNumber(balance));
        }
    }

    function sessionPaymentFilter(payment: SessionPayment) {
        switch (sessionFilter) {
            case 'All': {
                return payment.state !== 'PaymentInAdvance';
            }
            case 'Unpaid': {
                return payment.state === 'PaymentOutstanding';
            }
        }
    }

    return (
        <EffortlessPTPageWrapper title={`Payments Summary for Client ${client ? client.name : ''}`}>
        <Grid container spacing={2} padding={1}>
            <Grid item xs={12}>
                <h4>Balance</h4>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Session Type</strong></TableCell>
                                <TableCell><strong>Attended</strong></TableCell>
                                <TableCell><strong>Paid</strong></TableCell>
                                <TableCell><strong>Price</strong></TableCell>
                                <TableCell><strong>Owing</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sessionTypeStats.map((sessionTypeStat) => (
                                <TableRow key={sessionTypeStat.id}>
                                    <TableCell>{sessionTypeStat.name}</TableCell>
                                    <TableCell>{sessionTypeStat.attendedCount}</TableCell>
                                    <TableCell>{sessionTypeStat.paidCount}</TableCell>
                                    <TableCell>{formatCurrency(dineroFromScaledNumber(sessionTypeStat.price))}</TableCell>
                                    <TableCell>{calculateOwingAmountForSessionType(sessionTypeStat)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>

                <Box display="flex" justifyContent="space-between">
                    <Box alignItems="center">
                        <h4>Sessions</h4>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel id="sessions-filter-label" style={{ fontSize: '12px' }}>Sessions Filter</FormLabel>
                            <RadioGroup
                                aria-labelledby="sessions-filter-label"
                                name="sessions-filter"
                                value={sessionFilter}
                                defaultValue={"All"}
                                onChange={(_, newValue) => {
                                    setSessionFilter(newValue as SessionFilter);
                                }}
                                row={true}
                            >
                                <FormControlLabel value="All" control={<Radio />} label="All" />
                                <FormControlLabel value="Unpaid" control={<Radio />} label="Unpaid" />
                            </RadioGroup>
                        </FormControl>

                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Session Date</strong></TableCell>
                                    <TableCell><strong>Session Type</strong></TableCell>
                                    <TableCell><strong>Paid</strong></TableCell>
                                    <TableCell><strong>Date Paid</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sessionPayments.filter(sessionPaymentFilter).map((sessionPayment) => (
                                    <TableRow key={sessionPayment.id}>
                                        <TableCell>{sessionPayment.dateAttended ? <Link to={`/session-details/${sessionPayment.sessionId}`}>{formatDate(sessionPayment.dateAttended, true)}</Link> : ''}</TableCell>
                                        <TableCell>{sessionPayment.sessionTypeId ? `${lookupSessionType(sessionPayment.sessionTypeId)?.name}` : ''}</TableCell>
                                        <TableCell>{sessionPayment.state === 'PaymentComplete' ? <IconContainer name="Paid"><CheckIcon /></IconContainer> : ''}</TableCell>
                                        <TableCell>
                                            <Typography>
                                            {sessionPayment.datePaid ? `${formatDate(sessionPayment.datePaid, true)}` : ''}
                                            {isPassPayment(sessionPayment.paymentId) &&
                                                <Fragment> (<Link to={`/payment-details/pass/${sessionPayment.paymentId}`}>view pass</Link>)</Fragment>
                                            }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell>
                                        <DatePicker
                                            label="New Session Date" openTo="day"
                                            format="dd/MM/yyyy"
                                            views={['year', 'month', 'day']}
                                            value={newSessionDate}
                                            onChange={(newValue) => setNewSessionDate(newValue)}
                                            slotProps={{textField: {variant: 'standard'}}}
                                            enableAccessibleFieldDOMStructure
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Autocomplete options={settings.sessionTypes}
                                                      value={newSessionType}
                                                      getOptionLabel={(option) => option.name}
                                                      renderInput={(params) => <TextField
                                                          {...params}
                                                          id="session-type"
                                                          name="session-type"
                                                          label="Session Type"
                                                          aria-describedby="session-type-text"
                                                          variant="standard"
                                                          required
                                                      />}
                                                      onChange={(_, newValue) => {
                                                          setNewSessionType(newValue);
                                                      }}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <Button variant="outlined" onClick={handleRegisterSession}
                                                disabled={!newSessionDate || !newSessionType || registeringSession}>
                                            Register New Session
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                    <Stack direction={"row"} spacing={1}>
                        <Button variant={'outlined'} onClick={() => navigate(-1)}>Close</Button>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
        </EffortlessPTPageWrapper>
    );
};

export default ClientPaymentsView;