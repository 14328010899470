import * as React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Stack, TableBody, Typography } from '@mui/material'
import { useClients, usePayments, useSettings } from "../EffortlessPTContext";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { ApiError } from "../UseApi";
import { Payment } from "../../../data/payment";
import { formatDate } from '../date';
import EffortlessPTPageWrapper from "../EffortlessPTPageWrapper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { dineroFromScaledNumber, formatCurrency } from "../currency";
import { Client } from "../../../data/client";
import { Settings } from "../../../data/settings";
import { useOpenSessionInfo } from "../UnauthenticatedApis";
import {Delete} from "@mui/icons-material";

function PaymentDetailsGrid(props: { payment: Payment, clients: Client[], settings: Settings }) {
  const { payment, clients, settings } = props;
  //TODO: Handle the case where a payment is for multiple things, and display the list of things being paid for here
  return <TableContainer component={Paper} style={{ minHeight: '300px', width: '100%' }}>
    <table aria-label={"Payment Details"} style={{ width: '100%' }}>
      <TableBody>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>{formatDate(payment.date, true)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Amount</TableCell>
          <TableCell>{payment.amount && Number.isInteger(payment.amount) ? formatCurrency(dineroFromScaledNumber(payment.amount)) : ''}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>{payment.paymentType}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={2}>
            <Typography fontWeight={"bold"}>Paying for</Typography>
          </TableCell>
        </TableRow>
        {payment.paymentFor && payment.paymentFor.map((paymentFor, index) => {
          const client = clients.find(client => client.id === paymentFor.clientId)
          let clientSuffix
          if (client) {
            clientSuffix = `, for client ${client.name}`
          } else {
            clientSuffix = ''
          }
          const sessionType = settings.sessionTypes.find(sessionType => sessionType.id === paymentFor.sessionTypeId)
          let sessionSuffix
          if (sessionType) {
            sessionSuffix = `, of session type ${sessionType.name}`
          } else {
            sessionSuffix = ''
          }
          return <TableRow key={index}>
            <TableCell colSpan={2}>
              <Typography>{`${paymentFor.sessionCount} session${paymentFor.sessionCount !== 1 ? 's' : ''}${sessionSuffix}${clientSuffix}`}</Typography>
            </TableCell>
          </TableRow>
        }
        )}
      </TableBody>
    </table>
  </TableContainer>
}

export default function SessionPaymentDetails() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { clients } = useClients()
  const { settings } = useSettings()
  const { getSessionPayment, deletePayment } = usePayments()
  let [payment, setPayment] = useState<Payment>()
  let [error, setError] = useState<ApiError>()

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  if (id) {
    getSessionPayment(id, setPayment, setError)
  }
  return (
    <EffortlessPTPageWrapper title={"Session Payment Details"}>
      <Grid container spacing={2} padding={'5px'} direction={"column"}>
        <Grid item columns={12}>
          {(payment && clients) && <PaymentDetailsGrid payment={payment} clients={clients} settings={settings} />}
          <Typography>
            {error && `Error: ${error?.errorCode}: ${error?.message}`}
          </Typography>
          <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
            <DialogTitle>Delete Payment</DialogTitle>
            <DialogContent>
              Are you sure you would like to delete this payment? This cannot be undone.
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
              <Button onClick={() => {
                // TODO: Handle errors better.
                deletePayment(id!, () => navigate(-1), console.error);
              }} color="error">Delete</Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item columns={12}>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <Button onClick={() => navigate(-1)}>Back</Button>
            <Button onClick={()=>setConfirmDeleteOpen(true)} color="error" variant="outlined" endIcon={<Delete />}>Delete</Button>
          </Stack>
        </Grid>
      </Grid>
    </EffortlessPTPageWrapper>
  )
}
