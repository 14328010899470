import React, { createContext, useState, useContext, useEffect } from 'react';
import { DeleteResult, InsertManyResult, ListResult, SaveResult } from '../../data/query-result';
import { Client } from '../../data/client';
import { Settings } from '../../data/settings';
import { Trainer } from '../../data/trainer';
import { ApiDescription, ApiError, callApi, isApiError, useApi } from './UseApi';
import { PassPayment, Payment } from "../../data/payment";
import { AddSessionClientDetails, Session, SessionClientIdAndSignInId, SessionType } from '../../data/session';
import { EffortlessPTStats } from "../../data/stats";
import { preparePhoneForAPI } from './phoneHelper';
import { sign } from 'crypto';
import { SessionPayment } from "../../data/session-payment";

export interface StatsContextValue {
  stats: EffortlessPTStats;
  refreshStats: () => void;
}
export interface SettingsContextValue {
  settings: Settings;
  updateSettings: (settings: Settings, success: () => void, error: (error: ApiError) => void) => void;
  lookupSessionType: (sessionTypeId: string) => SessionType | null
}

export interface ClientsContextValue {
  clients: Client[];
  addClient: (client: Client, success: (id: string) => void, error: (error: ApiError) => void) => void;
  updateClient: (client: Client, success: () => void, error: (error: ApiError) => void) => void;
  getClient: (id: string, success: (result: Client) => void, error: (error: ApiError) => void) => void;
  lookupClient: (id: string) => Client | null;
  createClients: (clients: Client[], success: (result: InsertManyResult) => void, error: (error: ApiError) => void) => void;
}

export interface PaymentsContextValue {
  payments: Payment[];
  getSessionPayment: (id: string, success: (result: Payment) => void, error: (error: ApiError) => void) => void;
  getPassPayment: (id: string, success: (result: PassPayment) => void, error: (error: ApiError) => void) => void;
  registerPayment: (payment: Payment, success: (result: SaveResult) => void, error: (error: ApiError) => void) => void;
  deletePayment: (paymentId: string, success: () => void, error: (error: ApiError) => void) => void;
  getClientSessionPayments: (clientId: string, success: (result: ListResult<SessionPayment>) => void, error: (error: ApiError) => void) => void;
  lookupPayment: (paymentId: string) => Payment | null;
}

export interface SessionsContextValue {
  sessions: Session[];
  openSession: (session: Session, success: (id: string) => void, error: (error: ApiError) => void) => void;
  completeSession: (session: Session, success: () => void, error: (error: ApiError) => void) => void;
  addClientToClosedSession: (sessionId: string, clientSessionDetails: AddSessionClientDetails, success: () => void, error: (error: ApiError) => void) => void;
  removeClientFromClosedSession: (sessionId: string, clientId: string, signInId: string, success: () => void, error: (error: ApiError) => void) => void;
  changeClosedSessionDate: (sessionId: string, newDate: Date, success: () => void, error: (error: ApiError) => void) => void;
  registerSession: (session: Session, success: (id: string) => void, error: (error: ApiError) => void) => void;
  deleteSession: (sessionId: string, success: () => void, error: (error: ApiError) => void) => void;
  getSession: (id: string, success: (result: Session) => void, error: (error: ApiError) => void) => void;
  matchOpenSessionClient: (ids: SessionClientIdAndSignInId, success: (result: boolean) => void, error: (error: ApiError) => void) => void;
  setClientPayerId: (signInId: string, payerId: string, success: () => void, error: (error: ApiError) => void) => void;
  removeClientFromOpenSession: (signInId: string, success: () => void, error: (error: ApiError) => void) => void;
  changeOpenSessionDate: (newDate: Date, success: () => void, error: (error: ApiError) => void) => void;
}

const StatsContext = createContext<StatsContextValue>({
  refreshStats: () => null,
  stats: {
    sessionPaymentCountsBySessionTypeId: []
  }
});
const SettingsContext = createContext<SettingsContextValue>({
  settings: {
    sessionTypes: []
  },
  updateSettings: () => null,
  lookupSessionType: sessionTypeId => null
});
const TrainerPathContext = createContext<string>('');
const ClientsContext = createContext<ClientsContextValue>({
  clients: [],
  addClient: () => null,
  updateClient: () => null,
  getClient: () => null,
  lookupClient: (clientId: string) => null,
  createClients: () => null
});
const PaymentsContext = createContext<PaymentsContextValue>({
  payments: [],
  getSessionPayment: () => null,
  getPassPayment: () => null,
  registerPayment: () => null,
  deletePayment: () => null,
  getClientSessionPayments: () => null,
  lookupPayment: (paymentId: string) => null,
});
const SessionsContext = createContext<SessionsContextValue>({
  sessions: [],
  openSession: () => null,
  completeSession: () => null,
  registerSession: () => null,
  addClientToClosedSession: () => null,
  removeClientFromClosedSession: () => null,
  changeClosedSessionDate: () => null,
  deleteSession: () => null,
  getSession: () => null,
  matchOpenSessionClient: () => null,
  setClientPayerId: () => null,
  removeClientFromOpenSession: () => null,
  changeOpenSessionDate: () => null,
});

export function EffortlessPtContextProvider(props: {
  children: React.ReactNode;
}) {
  const { children } = props;

  const [errors, setErrors] = useState<ApiError[]>([]);
  const [trainer, setTrainer] = useState<Trainer>();
  const [clients, setClients] = useState<Client[]>();
  const [payments, setPayments] = useState<Payment[]>();
  const [sessions, setSessions] = useState<Session[]>();
  const [stats, setStats] = useState<EffortlessPTStats>();

  const statsApi = useApi('/pt/stats', 'GET');
  const trainerApi = useApi('/pt/trainer', 'GET');
  const clientsApi = useApi('/pt/client', 'GET');
  const paymentsApi = useApi('/pt/payment', 'GET');
  const sessionsApi = useApi('/pt/session', 'GET');

  const saveSettingsApi = useApi('/pt/settings', 'POST');
  const saveClientApi = useApi('/pt/client', 'POST');
  const getClientApi = useApi('/pt/client', 'GET');
  const createClientsApi = useApi('/pt/client/many', 'POST');

  const generateTemplateRedirectUrlApi = useApi('/pt/settings/template-redirect-url', 'POST');

  const getSessionPaymentApi = useApi('/pt/payment/session', 'GET');
  const getPassPaymentApi = useApi('/pt/payment/pass', 'GET');
  const registerPaymentApi = useApi('/pt/payment/register', 'POST');
  const deletePaymentApi = useApi('/pt/payment', 'DELETE');

  const openSessionApi = useApi('/pt/session/open', 'POST');
  const completeSessionApi = useApi('/pt/session/complete', 'POST');
  const registerSessionApi = useApi('/pt/session/register', 'POST');
  const addClientToClosedSessionApi = useApi('/pt/session/add-client-to-closed', 'POST');
  const removeClientFromClosedSessionApi = useApi('/pt/session/remove-client-from-closed', 'DELETE');
  const changeClosedSessionDateApi = useApi('/pt/session/change-date-of-closed', 'POST');
  const matchOpenSessionClientApi = useApi('/pt/session/match-client', 'POST');
  const removeClientFromOpenSessionApi = useApi('/pt/session/remove-client-from-open', 'POST');
  const deleteSessionApi = useApi('/pt/session', 'DELETE');
  const getSessionApi = useApi('/pt/session', 'GET');
  const getClientPaymentsApi = useApi('/pt/client/payments', 'GET');
  const markClientAsPaidApi = useApi('/pt/session/mark-client-as-paid', 'POST');
  const setClientPayerIdApi = useApi('/pt/session/set-client-payer', 'POST');
  const changeOpenSessionDateApi = useApi('/pt/session/change-date-of-open', 'POST');

  useEffect(() => {
    const loadData = async function <T>(api: ApiDescription): Promise<T | null> {
      const response = await callApi<T>(api, {});
      if (isApiError(response)) {
        setErrors([...errors, response]);
        return null;
      } else {
        return response;
      }
    };
    (async () => {
      const trainerPromise = loadData<Trainer>(trainerApi);
      const clientsPromise = loadData<ListResult<Client>>(clientsApi);
      const paymentsPromise = loadData<ListResult<Payment>>(paymentsApi);
      const sessionsPromise = loadData<ListResult<Session>>(sessionsApi);
      const statsPromise = loadData<EffortlessPTStats>(statsApi);
      const trainer = await trainerPromise;
      const clients = await clientsPromise;
      const payments = await paymentsPromise;
      const sessions = await sessionsPromise;
      const stats = await statsPromise;
      if (trainer) {
        setTrainer(trainer);
      }
      if (clients) {
        setClients(clients.results.sort((a, b) => a.name.localeCompare(b.name)));
      }
      if (sessions) {
        setSessions(sessions.results);
      }
      if (payments) {
        setPayments(payments.results);
      }
      if (stats) {
        setStats(stats)
      }
    })();
  }, []);
  // TODO: Handle errors using the warning icon.
  return (
    <SettingsContext.Provider value={{
      settings: trainer?.settings ?? { sessionTypes: [] },
      updateSettings: (settings, success, error) => {
        callApi(saveSettingsApi, { body: JSON.stringify(settings) }).then(response => {
          if (isApiError(response)) {
            error(response);
          } else {
            if (settings.sessionTypes.some(sessionType => sessionType.redirectAfterSignInUrl && !sessionType.redirectAfterSignInUrlTemplated)) {
              // Refresh the trainer to get the templated URL (which is set on the API side).
              callApi<Trainer>(trainerApi).then(newTrainer => {
                if (!isApiError(newTrainer)) {
                  setTrainer(newTrainer);
                  success();
                } else {
                  error(newTrainer);
                }
              })
            } else {
              setTrainer({ ...trainer!, settings });
              success();
            }
          }
        })
      },
      lookupSessionType: sessionTypeId => {
        return trainer?.settings.sessionTypes.find(sessionType => sessionType.id === sessionTypeId) ?? null
      }
    }}>
      <TrainerPathContext.Provider value={trainer?.path ?? ''}>
        <ClientsContext.Provider value={{
          clients: clients ?? [],
          getClient: (id, success, error) => {
            (async () => {
              const response = await callApi<Client>(getClientApi, {}, `id=${id}`);
              if (isApiError(response)) {
                error(response);
              } else {
                success(response);
              }
            })();
          },
          addClient: (rawClient, success, error) => {
            // We need to prepare the phone number for the API (remove whitespace). Rather than doing it in all the places which call us, we just do it here.
            const client: Client = { ...rawClient, phone: preparePhoneForAPI(rawClient.phone) };
            callApi<SaveResult>(saveClientApi, { body: JSON.stringify(client) }).then(saveResult => {
              if (!isApiError(saveResult) && saveResult.id) {
                client.id = saveResult.id;
                setClients([...clients ?? [], client]);
                success(saveResult.id);
              } else if (isApiError(saveResult)) {
                error(saveResult);
              } else {
                error({
                  errorCode: -127, message: 'Missing id on save client api'
                });
              }
            });
          },
          updateClient: (rawClient, success, error) => {
            const client: Client = { ...rawClient, phone: preparePhoneForAPI(rawClient.phone) };
            if (client.id) {
              callApi<SaveResult>(saveClientApi, { body: JSON.stringify(client) }).then(saveResult => {
                if (!isApiError(saveResult)) {
                  const newClients = clients?.map(c => c.id === client.id ? client : c);
                  setClients(newClients);
                  success();
                } else {
                  error(saveResult);
                }
              });
            } else {
              error({ errorCode: -127, message: 'Missing ID' });
            }
          },
          lookupClient: (id) => {
            if (id) {
              return clients?.find(client => client.id === id) ?? null
            } else {
              return null
            }
          },
          createClients: (clients, success, error) => {
            callApi<InsertManyResult>(createClientsApi, { body: JSON.stringify(clients) }).then(response => {
              if (!isApiError(response)) {
                response.ids?.forEach((id, index) => clients[index].id = id);
                setClients(oldClients => [...oldClients ?? [], ...clients]);
                success(response);
              } else {
                error(response);
              }
            });
          }
        }}>
          <PaymentsContext.Provider value={{
            payments: payments ?? [],
            getSessionPayment: (id: string, success: (result: Payment) => void, error: (error: ApiError) => void) => {
              useEffect(() => {
                (async () => {
                  const result = await callApi<Payment>(getSessionPaymentApi, {}, `id=${id}`);
                  if (isApiError(result)) {
                    error(result)
                  } else {
                    success(result)
                  }
                })();
              }, [])
            },
            getPassPayment: (id, success, error) => {
              callApi<PassPayment>(getPassPaymentApi, {}, `id=${id}`).then(
                result => {
                  if (!isApiError(result)) {
                    success(result)
                  } else {
                    error(result)
                  }
                }
              );
            },
            registerPayment: (payment, success, error) => {
              callApi<SaveResult>(registerPaymentApi, { body: JSON.stringify(payment) }).then(saveResult => {
                if (!isApiError(saveResult)) {
                  payment.id = saveResult.id;
                  setPayments(payments => [...payments ?? [], payment]);
                  success(saveResult)
                } else {
                  error(saveResult)
                }
              });
            },
            deletePayment: (id, success, error) => {
              callApi<DeleteResult>(deletePaymentApi, {}, `id=${id}`).then(response => {
                if (!isApiError(response)) {
                  if (response.count > 0) {
                    setPayments(payments => payments?.filter(payment => payment.id !== id));
                    success();
                  } else {
                    error({
                      message: `Payment ${id} not found`,
                      errorCode: -1
                    });
                  }
                } else {
                  error(response);
                }
              })
            },
            getClientSessionPayments: (clientId, success, error) => {
              (async () => {
                const response = await callApi<ListResult<SessionPayment>>(getClientPaymentsApi, {}, `clientId=${clientId}`);
                if (isApiError(response)) {
                  error(response);
                } else {
                  success(response);
                }
              })();
            },
            lookupPayment: (paymentId: string) => {
              return payments?.find(payment => payment.id === paymentId) ?? null;
            },
          }}>
            <SessionsContext.Provider value={{
              sessions: sessions ?? [],
              openSession: (session, success, error) => {
                callApi<SaveResult>(openSessionApi, { body: JSON.stringify(session) }).then(saveResult => {
                  if (!isApiError(saveResult)) {
                    session.id = saveResult.id;
                    setSessions(sessions => [...sessions ?? [], session]);
                    success(saveResult.id!);
                  } else {
                    error(saveResult);
                  }
                });
              },
              completeSession: (session, success, error) => {
                session.state = "Complete";
                callApi<SaveResult>(completeSessionApi, {
                  body: JSON.stringify({
                    id: session.id!
                  })
                }).then(completeResult => {
                  if (!isApiError(completeResult)) {
                    // Update our copy of the session.
                    const newSessions = sessions?.map(s => s.id === session.id ? session : s);
                    setSessions(newSessions);
                    success();
                  } else {
                    error(completeResult);
                  }
                });
              },
              registerSession: (session, success, error) => {
                callApi<SaveResult>(registerSessionApi, { body: JSON.stringify(session) }).then(saveResult => {
                  if (!isApiError(saveResult)) {
                    if (saveResult.id) {
                      session.id = saveResult.id;
                      session.state = "Complete";
                      setSessions([...sessions ?? [], session]);
                      success(saveResult.id);
                    } else {
                      error({ errorCode: -127, message: 'Missing id on save session api' });
                    }
                  } else {
                    error(saveResult);
                  }
                });
              },
              addClientToClosedSession: (sessionId, addSessionClientDetails, success, error) => {
                callApi<SaveResult>(addClientToClosedSessionApi, { body: JSON.stringify(addSessionClientDetails) }, `sessionId=${sessionId}`).then(saveResult => {
                  if (!isApiError(saveResult)) {
                    if (saveResult.count === 1) {
                      // TODO: Update the in-memory copy of the session.
                      success();
                    } else {
                      error({ errorCode: -1, message: 'Failed to add client to session.' });
                    }
                  } else {
                    error(saveResult);
                  }
                });
              },
              removeClientFromClosedSession: (sessionId, clientId, signInId, success, error) => {
                callApi<SaveResult>(removeClientFromClosedSessionApi, {}, `sessionId=${sessionId}&clientId=${clientId}&signInId=${signInId}`).then(saveResult => {
                  if (!isApiError(saveResult)) {
                    if (saveResult.count === 1) {
                      // TODO: Update the in-memory copy of the session.
                      success();
                    } else {
                      error({ errorCode: -1, message: `Failed to from client ${clientId} (sign in id ${signInId}) from session ${sessionId}.` });
                    }
                  } else {
                    error(saveResult);
                  }
                });
              },
              changeClosedSessionDate: (sessionId, newDate, success, error) => {
                callApi<SaveResult>(changeClosedSessionDateApi, { body: JSON.stringify({ newDate }) }, `id=${sessionId}`).then(saveResult => {
                  if (!isApiError(saveResult)) {
                    if (saveResult.count === 1) {
                      const newSessions = sessions?.map(s => s.id === sessionId ? { ...s, date: newDate } : s);
                      setSessions(newSessions);
                      success();
                    } else {
                      error({ errorCode: -1, message: 'Failed to change date of session.' });
                    }
                  } else {
                    error(saveResult);
                  }
                })
              },
              matchOpenSessionClient: (ids, success, error) => {
                callApi<boolean>(matchOpenSessionClientApi, {
                  body: JSON.stringify(ids)
                }).then(matchResult => {
                  if (!isApiError(matchResult)) {
                    const openSession = sessions?.find(session => session.state === "Open");
                    const client = openSession?.clients.find(client => client.signInId === ids.signInId);
                    if (client) {
                      client.clientId = ids.clientId;
                    }
                    setSessions([...sessions ?? []]);
                    success(matchResult);
                  } else {
                    error(matchResult);
                  }
                })
              },
              deleteSession: (sessionId, success, error) => {
                callApi<DeleteResult>(deleteSessionApi, {}, `id=${sessionId}`).then(deleteResult => {
                  if (!isApiError(deleteResult)) {
                    if (deleteResult.count > 0) {
                      setSessions(sessions?.filter(s => s.id !== sessionId));
                      success();
                    } else {
                      error({
                        errorCode: -127,
                        message: 'Session not found'
                      });
                    }
                  } else {
                    error(deleteResult);
                  }
                });
              },
              getSession: (id, success, error) => {
                (async () => {
                  const response = await callApi<Session>(getSessionApi, {}, `id=${id}`);
                  if (isApiError(response)) {
                    error(response);
                  } else {
                    if (response !== null) {
                      // Update our in-memory copy of the session with the new version.
                      const newSessions = sessions?.map(s => s.id === id ? response : s);
                      setSessions(newSessions);
                      success(response);
                    } else {
                      error({
                        errorCode: -1,
                        message: 'Session not found'
                      })
                    }
                  }
                })();
              },
              setClientPayerId: (signInId, payerId, success, error) => {
                callApi<SaveResult>(setClientPayerIdApi, { body: JSON.stringify({ clientId: payerId, signInId }) }).then(saveResult => {
                  if (!isApiError(saveResult)) {
                    const openSession = sessions?.find(session => session.state === "Open");
                    const client = openSession?.clients.find(client => client.signInId === signInId);
                    if (client) {
                      client.payerClientId = payerId;
                    } else {
                      // Should never happen.
                      console.error(`Client with sign in ID ${signInId} not found in open session`);
                    }
                    setSessions([...sessions ?? []]);
                    success();
                  } else {
                    error(saveResult);
                  }
                });
              },
              removeClientFromOpenSession: (signInId, success, error) => {
                callApi<SaveResult>(removeClientFromOpenSessionApi, { body: JSON.stringify({ signInId }) }).then(saveResult => {
                  if (!isApiError(saveResult)) {
                    if (saveResult.count === 1) {
                      const newSessions = sessions?.map(s => s.state === "Open" ? { ...s, clients: s.clients.filter(c => c.signInId !== signInId) } : s);
                      setSessions(newSessions);
                      success();
                    } else {
                      error({ errorCode: -1, message: 'Failed to remove client from open session.' });
                    }
                  } else {
                    error(saveResult);
                  }
                });
              },
              changeOpenSessionDate: (newDate, success, error) => {
                callApi<SaveResult>(changeOpenSessionDateApi, { body: JSON.stringify({ newDate }) }).then(saveResult => {
                  if (!isApiError(saveResult)) {
                    if (saveResult.count === 1) {
                      const newSessions = sessions?.map(s => s.state === "Open" ? { ...s, date: newDate } : s);
                      setSessions(newSessions);
                      success();
                    } else {
                      error({ errorCode: -1, message: 'Failed to change date of open session.' });
                    }
                  } else {
                    error(saveResult);
                  }
                });
              },
            }}>
              <StatsContext.Provider value={{
                refreshStats: () => {
                  callApi<EffortlessPTStats>(statsApi).then(saveResult => {
                    if (!isApiError(saveResult)) {
                      setStats(saveResult)
                    } else {
                      //TODO: Record error
                    }
                  })
                },
                stats: stats ?? { sessionPaymentCountsBySessionTypeId: [] }
              }}>
                {children}
              </StatsContext.Provider>
            </SessionsContext.Provider>
          </PaymentsContext.Provider>
        </ClientsContext.Provider>
      </TrainerPathContext.Provider>
    </ SettingsContext.Provider >
  )
}

export const useSettings = () => useContext(SettingsContext);
export const useTrainerPath = () => useContext(TrainerPathContext);
export const useClients = () => useContext(ClientsContext);
export const usePayments = () => useContext(PaymentsContext);
export const useSessions = () => useContext(SessionsContext);
export const useStats = () => useContext(StatsContext);
