import * as React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Stack,
  Typography
} from '@mui/material'
import { Client } from '../../../data/client'
import FormField from '../FormField'
import { useEffect, useState } from 'react'
import { useClients, usePayments } from '../EffortlessPTContext'
import { ApiError } from '../UseApi'
import EffortlessPTPageWrapper from "../EffortlessPTPageWrapper";
import PhoneInput from '../PhoneInput'
import { preparePhoneForRendering } from '../phoneHelper'
import {getActivePass} from "../client";

export default function EditClient() {
  const { id } = useParams();
  const navigate = useNavigate();
  // We use this page for both edit and add client.
  const location = useLocation();
  const isAdding = location.pathname === "/add-client";

  const { getClient, updateClient, addClient } = useClients();
  const { payments } = usePayments();

  const [client, setClient] = useState<Client>();
  const [error, setError] = useState<ApiError>();

  const activePass = getActivePass(client, payments);

  useEffect(() => {
    if (isAdding) {
      setClient({
        name: '',
        phone: '',
        eftMatch: '',
        trainerId: ''
      });
    } else {
      if (id) {
        getClient(id, (client) => setClient({ ...client, phone: preparePhoneForRendering(client.phone) }), setError);
      } else {
        // Should never happen, but just in case.
        setError({ errorCode: -2, message: "No client ID provided." });
      }
    }
  }, [id, isAdding]);

  if (client) {
    return (
      <EffortlessPTPageWrapper title={`${isAdding ? "Add" : "Edit"} Client`}>
        <form onSubmit={(event) => {
          event.preventDefault();
          if (isAdding) {
            addClient(client, () => {
              navigate(-1);
            }, setError);
          } else {
            updateClient(client, () => {
              navigate(-1);
            }, setError);
          }
        }}>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={12}>
              <FormControl fullWidth={true} variant={"standard"}>
                <InputLabel htmlFor="name">Name</InputLabel>
                <Input
                  id="name"
                  value={client.name}
                  onChange={(event) => setClient({ ...client, name: event.target.value })}
                  required
                  aria-describedby="name-text"
                  autoComplete={"off"}
                />
                <FormHelperText id="name-text">The client&apos;s name</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth={true} variant={"standard"}>
                <InputLabel htmlFor="phone">Phone</InputLabel>
                <PhoneInput
                  id="phone"
                  required
                  onChange={(phone) => setClient({ ...client, phone })}
                  value={client.phone}
                  inputProps={{ "aria-describedby": "phone-text", autoComplete: "off" }}
                />
                <FormHelperText id="phone-text">The client&apos;s phone number</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormField label="EFT Match" fieldName="eftMatch" inputType="text" object={client} attributes={{ autoComplete: "off" }} />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Stack direction={"row"} spacing={1}>
                  {!isAdding && <Button variant="outlined" onClick={() => navigate(`/client-payments/${id}`)}>View payments</Button>}
                  {activePass && <Button variant="outlined" onClick={() => navigate(`/payment-details/pass/${activePass.id}`)}>View active pass</Button>}
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Button variant={'outlined'} onClick={() => navigate(-1)}>Cancel</Button>
                  <Button type={"submit"} variant={'contained'}>{isAdding ? "Add" : "Save"}</Button>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </form>
      </EffortlessPTPageWrapper>
    )
  } else if (error) {
    // TODO: Handle this properly when the warning icon is a thing.
    console.error(error);
    return (
      <Typography>
        An error occured: {error?.message}
      </Typography>
    );
  } else {
    return null;
  }
}
