import React, {useEffect, useState} from "react";
import {
  Typography,
  Grid, List, ListItem, Box,
} from "@mui/material";
import EffortlessPTPageWrapper from "../EffortlessPTPageWrapper";
import {callApi, isApiError, useApi} from "../UseApi";

export default function Branches() {
  const [branches, setBranches] = useState<string[]>([])
  const getBranchesApi = useApi('/pt/branches', 'GET');
  useEffect(() => {
    (async () => {
      const response = await callApi<string[]>(getBranchesApi, {});
      if (isApiError(response)) {
        console.log(response)
      } else {
        setBranches(response)
      }
    })();
  }, []);

  return (
    <EffortlessPTPageWrapper title={'Branches'}>
      <Grid container spacing={2} padding={'5px'}>
        <Grid item xs={12}>
          <Typography>Branches:</Typography>
          <List>
            {branches.map((branch, index) => <ListItem key={index}>
              <a href={`https://dev.effortlesspt.com/branches/${branch}/index.html`} style={{textDecoration: 'none'}}>
                {branch}
              </a>
            </ListItem>)}
          </List>
        </Grid>
      </Grid>
    </EffortlessPTPageWrapper>
  );
}

