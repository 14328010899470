import {format, isMatch, parse} from "date-fns";
import {enAU} from "date-fns/locale";

//TODO: based on the loaded trainer, set the locale. maybe make this set of functions a useDates() react component that comes from the EffortlessPTContext so we can get the locale as it loads in

export function formatDate(date: Date, dateOnly: boolean = false): string {
  const dateString = format(date, 'P', {locale: enAU})
  if (dateOnly) {
    return dateString
  }
  const timeString = format(date, 'HH:mm', {locale: enAU})
  return `${dateString} ${timeString}`;
}

export function parseDate(dateString: string): Date {
  return parse(dateString, 'P', new Date(), {locale: enAU})
}

export function isDate(value: string): boolean {
  return isMatch(value, 'P', {locale: enAU})
}

/**
 * Get the current date with the minutes rounded down to the nearest 30 minutes, and the seconds and milliseconds zero'd out.
 */
export function getSessionDate() {
  const date = new Date();
  // Round to the nearest half-hour.
  date.setMinutes(Math.round(date.getMinutes() / 30) * 30);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}