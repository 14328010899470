import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputLabel, Radio, RadioGroup, Stack, Switch, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PaymentMethod } from '../../../data/payment';
import { SessionClient, SessionType } from '../../../data/session';
import EffortlessPTPageWrapper from '../EffortlessPTPageWrapper';
import PhoneInput from '../PhoneInput';
import createRedirectUrlFromTemplate from '../RedirectTemplate';
import { useOpenSessionInfo, useSubmitSessionClient } from '../UnauthenticatedApis';
import { ApiError } from '../UseApi';
import { preparePhoneForRendering } from '../phoneHelper';

export const LOCAL_STORAGE_SELF_SIGN_IN_NAME_KEY = 'self-sign-in-name';
export const LOCAL_STORAGE_SELF_SIGN_IN_PHONE_KEY = 'self-sign-in-phone';
export const LOCAL_STORAGE_SELF_SIGN_IN_PAYMENT_METHOD_KEY = 'self-sign-in-payment-method';

export interface OpenSession {
    sessionType?: SessionType;
    submit?: (client: SessionClient, success: () => void, error: (error: ApiError) => void) => void;
}

export default function ClientSelfSignIn({ trainerSignIn }: { trainerSignIn?: boolean }) {
    const { trainerPath, clientName, clientPhone } = useParams();

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const [name, setName] = useState(localStorage.getItem(LOCAL_STORAGE_SELF_SIGN_IN_NAME_KEY) ?? '');
    const [phone, setPhone] = useState(localStorage.getItem(LOCAL_STORAGE_SELF_SIGN_IN_PHONE_KEY) ?? '');
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | "Friend">(localStorage.getItem(LOCAL_STORAGE_SELF_SIGN_IN_PAYMENT_METHOD_KEY) as PaymentMethod ?? 'EFT');

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_SELF_SIGN_IN_NAME_KEY, name);
    }, [name]);
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_SELF_SIGN_IN_PHONE_KEY, phone);
    }, [phone]);
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_SELF_SIGN_IN_PAYMENT_METHOD_KEY, paymentMethod);
    }, [paymentMethod]);

    const { sessionInfo, error } = useOpenSessionInfo(trainerPath!);
    const submitSessionClient = useSubmitSessionClient(trainerPath!);

    useEffect(() => {
        if (sessionInfo && paymentMethod.startsWith("pass:")) {
            if (!sessionInfo.sessionType.passTypes?.some(passType => paymentMethod === `pass:${passType.id}`)) {
                setPaymentMethod("EFT");
            }
        }
    }, [paymentMethod, sessionInfo]);

    const [completed, setCompleted] = useState(false);

    const [hasAgreed, setHasAgreed] = useState<boolean[]>([]);

useEffect(() => {
    if (sessionInfo) {
        if (hasAgreed.length !== sessionInfo.sessionType.agreements?.length ?? 0) {
            setHasAgreed(sessionInfo.sessionType.agreements?.map(() => false) ?? []);
        }
    }
}, [sessionInfo]);

    if (!completed) {
        if (sessionInfo) {
            const { sessionType: { passTypes, redirectAfterSignInUrlTemplated, agreements }, date } = sessionInfo;
            return (
                <EffortlessPTPageWrapper title="Sign in">
                    <form onSubmit={(event) => {
                        event.preventDefault();
                        submitSessionClient({
                            clientId: '',
                            signInId: '',
                            name: clientName ?? name,
                            phone: clientPhone ?? phone,
                            paymentMethod,
                            signature: {
                                date: new Date(),
                                device: '',
                                ip: '',
                                selfSigned: !trainerSignIn
                            }
                        }, () => {
                            setCompleted(true);
                            if (redirectAfterSignInUrlTemplated) {
                                const finalRedirectUrl = createRedirectUrlFromTemplate(redirectAfterSignInUrlTemplated, date, clientPhone ? preparePhoneForRendering(clientPhone) : phone, clientName ?? name);
                                window.location.href = finalRedirectUrl;
                            } else if (trainerSignIn) {
                                navigate(-1);
                            }
                        }, (error) => {
                            // TODO: Handle this better.
                            console.error(error.message);
                        })
                    }}>
                        <Grid container spacing={2} padding="5px">
                            {agreements && agreements.length > 0 &&
                            <Grid item xs={12}>
                                <Stack spacing={2} direction={isMobile ? 'column' : 'row'} flexWrap="wrap">
                                    {agreements.map((agreement, index) => (
                                        <FormControl fullWidth key={index} variant="standard">
                                                <FormControlLabel control={
                                                    <Switch required checked={hasAgreed[index]} onChange={(e) => {
                                                        const newHasAgreed = [...hasAgreed];
                                                        newHasAgreed[index] = e.target.checked;
                                                        setHasAgreed(newHasAgreed);
                                                }} id={`agreement-${index}`} aria-describedby={`agreement-${index}-helper-text`} />
                                                    } label={agreement.checkboxText} />
                                                <Accordion>
                                                    <AccordionSummary>
                                                        <Typography>view agreement</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>{agreement.longText}</Typography>
                                                    </AccordionDetails>
                                                    </Accordion>
                                        </FormControl>
                                    ))}
                                </Stack>
                                </Grid>
                                }
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth={true} variant="standard">
                                    <InputLabel htmlFor='name'>Name</InputLabel>
                                    <Input
                                        id='name'
                                        aria-describedby='name-text'
                                        autoComplete='name'
                                        required
                                        value={clientName ?? name}
                                        disabled={clientName !== undefined}
                                        onChange={(event) => {
                                            setName(event.target.value);
                                        }}
                                    />
                                    <FormHelperText id='name-text'>Your <strong>full</strong> name</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth={true} variant="standard">
                                    <InputLabel htmlFor='phone'>Phone</InputLabel>
                                    <PhoneInput
                                        id="phone"
                                        required
                                        value={clientPhone ? preparePhoneForRendering(clientPhone) : phone}
                                        onChange={setPhone}
                                        inputProps={{
                                            "aria-describedby": "phone-text",
                                            autoComplete: "tel",
                                            disabled: clientPhone !== undefined
                                        }}
                                    />
                                    <FormHelperText id='phone-text'>Your phone number</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth={true} variant="standard">
                                    <FormLabel htmlFor='payment-method'>Payment method</FormLabel>
                                    <RadioGroup
                                        id="payment-method"
                                        aria-describedby='payment-method-text'
                                        value={paymentMethod}
                                        onChange={(event) => {
                                            setPaymentMethod(event.target.value as PaymentMethod);
                                        }}
                                        row={true}
                                    >
                                        <FormControlLabel value="EFT" control={<Radio />} label="EFT" />
                                        <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                                        {passTypes?.map(passType => (
                                            <FormControlLabel key={passType.id} value={`pass:${passType.id}`} control={<Radio />} label={passType.name} />
                                        ))}
                                        <FormControlLabel value="Friend" control={<Radio />} label="Paid by a friend" />
                                    </RadioGroup>
                                    <FormHelperText id="payment-method-text">How you will pay for your session</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                {redirectAfterSignInUrlTemplated &&
                                    <Typography><em>After pressing Next, you will be required to fill out the
                                        waiver.</em></Typography>
                                }
                                <Button type="submit" variant="contained" color="primary">{
                                    redirectAfterSignInUrlTemplated ? 'Next' : 'Sign in'}</Button>
                            </Grid>
                        </Grid>
                    </form>
                </EffortlessPTPageWrapper>
            );
        } else if (error) {
            if (error.errorCode === 404) {
                return (
                    <EffortlessPTPageWrapper title="Sign in">
                        <Grid container spacing={2} padding="5px">
                            <Grid item columns={12}>
                                <Typography>
                                    No open session. Please try again when the session is open.
                                </Typography>
                            </Grid>
                        </Grid>
                    </EffortlessPTPageWrapper>
                )
            } else {
                return (
                    <Box>
                        <Typography>
                            Unknown error, please contact support.
                        </Typography>
                        <Typography>
                            Error message: {error.message}
                        </Typography>
                    </Box>
                )
            }
        } else {
            // Still loading.
            return null;
        }
    } else {
        return (
            <EffortlessPTPageWrapper title="Sign in">
                <Grid container spacing={2} padding="5px">
                    <Grid item columns={12}>
                        <Typography>
                            Success!
                        </Typography>
                    </Grid>
                </Grid>
            </EffortlessPTPageWrapper>
        )
    }
}
