import * as React from 'react';
import { Box, IconButton, ListItemIcon, Menu, MenuItem, SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { useState, ReactNode, Fragment } from 'react';

export interface MenuOption<T> {
    value: T;
    description: string;
    icon: React.ReactNode;
    name: string;
}

export default function OptionMenu<T>(props: {
    title: string;
    label?: string;
    options: MenuOption<T>[];
    onSelected: (value: T) => void;
    icon: ReactNode;
    uniqueKey: string;
    sx?: SxProps<Theme>;
}) {
    const { title, label, options, onSelected, icon, uniqueKey, sx } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseHandler = () => {
        setAnchorEl(null);
    };

    const open = anchorEl !== null;

    const onValueSelected = (value: T) => {
        onCloseHandler();
        onSelected(value);
    }

    return <Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
            {label && <Typography>{label}</Typography>}
            <Tooltip title={title}>
                <IconButton
                    sx={sx}
                    onClick={onClickHandler}
                    size="small"
                    aria-controls={open ? `menu-${uniqueKey}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        </Box >
        <Menu
            anchorEl={anchorEl}
            id={`menu-${uniqueKey}`}
            open={open}
            onClose={onCloseHandler}
            onClick={onCloseHandler}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {options.map((option, index) => (
                <Tooltip title={option.description} key={index} placement="right">
                    <MenuItem onClick={() => onValueSelected(option.value)}>
                        <ListItemIcon>
                            {option.icon}
                        </ListItemIcon>
                        {option.name}
                    </MenuItem>
                </Tooltip>
            ))}
        </Menu>
    </Fragment>
}
