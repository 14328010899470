import * as React from 'react';
import {
  Grid,
  List,
  Accordion,
  ListItem, AccordionDetails, useTheme, Typography, useMediaQuery
} from "@mui/material";
import { useEffect } from "react";
import { useSettings, useStats } from "../EffortlessPTContext";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';


/**
 * The DashboardOverview will top-right of the Dashbord title on the Dashboard page and display stats about the trainer
 * sessions and payments, giving them an instant view into their activity and any actions they need to take
 * i.e. to chase up payments.
 */
export function DashboardOverview() {
  const navigate = useNavigate();
  const { lookupSessionType } = useSettings()
  const { stats, refreshStats } = useStats()
  const theme = useTheme()

  useEffect(() => {
    refreshStats()
  }, [])

  const showStats = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  return showStats ? <Grid container padding={1} spacing={1}>
    <Grid item columns={12}>
      {stats.sessionPaymentCountsBySessionTypeId.length > 0 &&
        <Accordion style={{ backgroundColor: theme.palette.primary.light }} elevation={0}>
          <AccordionDetails sx={{ padding: 0 }}>
            <List dense={true}>
              {stats.sessionPaymentCountsBySessionTypeId.sort((a, b) => (lookupSessionType(a.sessionTypeId)?.name ?? '').localeCompare(lookupSessionType(b.sessionTypeId)?.name ?? ''))
                .map((stat, index) => {
                  const sessionType = lookupSessionType(stat.sessionTypeId)
                  if (sessionType) {
                    return <ListItem key={index}>
                      <Typography
                        sx={{ color: theme.palette.primary.contrastText }}
                      >
                        {`${sessionType.name} (${stat.count - stat.clientsPaidAhead.length - stat.unpaidClients.length}/${stat.count - stat.clientsPaidAhead.length} sessions paid)`}
                        {stat.unpaidClients.length !== 0 &&
                          <React.Fragment> (<Link to={`/dashboard/clients?filter=${encodeURIComponent(`unpaid:${lookupSessionType(stat.sessionTypeId)?.name}`)}`}>view unpaid</Link>)</React.Fragment>
                        }
                      </Typography>
                    </ListItem>
                  } else {
                    return null
                  }
                }
                )}
            </List>
          </AccordionDetails>
        </Accordion>
      }
    </Grid>
  </Grid> : null
}
