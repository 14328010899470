import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {CsvFile, csvInit} from "./CsvFile";
import {Box, Typography} from "@mui/material";


interface CsvDragDropProps {
  csvFile: CsvFile | null
  onError: (error: any) => void
  onLoad: (csv: CsvFile) => void
  onReset: () => void
}

export function CsvDragDrop(props: CsvDragDropProps) {
  const [csvFile, setCsvFile] = useState<CsvFile | null>(props.csvFile)
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const firstFile = acceptedFiles[0];
    try {
      const csvData = await firstFile.text()
      let csvFile: CsvFile = csvInit(firstFile.name, csvData)
      setCsvFile(csvFile)
      props.onLoad(csvFile)
    } catch (e) {
      props.onError(e)
    }
  }, [])
  const {getRootProps, getInputProps, acceptedFiles, fileRejections} = useDropzone({
    onDrop, accept: {
      'text/csv': ['.csv']
    },
    multiple: false
  })
  const fileRejectionItems = fileRejections.map(({file, errors}) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  function handleReset() {
    setCsvFile(null)
    props.onReset()
  }

  return (
    <section className="container">
      {!csvFile &&
          <Box {...getRootProps({className: 'dropzone'})} sx={{
            width: {
              sm: "100%",
              md: "80%"
            }
          }} style={{
            textAlign: "center",
            padding: "40px",
            border: "1px blue dashed",
            margin: "auto",
            cursor: "pointer"
          }}>
              <input {...getInputProps()} />
              <p>Drop a CSV file here, or click to import a file.</p>
          </Box>
      }
      {
        csvFile &&
          <aside>
              <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                  <Typography paragraph={true}>File loaded: {csvFile.name} ({csvFile.rows.length} row{csvFile.rows.length === 1 ? "" : "s"})</Typography>
                  &nbsp;<a href="#" onClick={event=>{event.preventDefault(); handleReset()}}>choose a different file</a>
              </Box>
          </aside>
      }
      {
        fileRejections.length > 0 &&
          <aside>
              <Typography variant={'h4'}>Rejected files:</Typography>
              <ul>{fileRejectionItems}</ul>
          </aside>
      }
    </section>
  )
}
