import { LocalStorageCache } from '@auth0/auth0-react';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { dinero } from 'dinero.js';
import * as React from 'react';
import { useCallback, useEffect } from 'react';

export type PersistentStateKey =
    "reconcileBankStatement.csvFile"
    | "reconcileBankStatement.columnRoles"
    | "reconcileBankStatement.allColumnRolesSelected"
    | "reconcileBankStatement.payments"
    | "reconcileBankStatement.paymentsToRegister"
    | "reconcileBankStatement.currentStep";

interface PersistantContextValue {
    value: any;
}

const persistentStateContext = React.createContext<[PersistantContextValue, React.Dispatch<React.SetStateAction<PersistantContextValue>>]>([{ value: {} }, () => null]);

export function PersistentStateContextProvider({ children }: { children: React.ReactNode }) {
    const [value, setValue] = React.useState<PersistantContextValue>({ value: {} });
    useEffect(() => {
        const storedValue = localStorage.getItem("persistentState");
        if (storedValue) {
            setValue(JSON.parse(storedValue, (key, value) => {
                // Dineros contain amount, currency and scale.
                if (value && typeof (value) === 'object' && "amount" in value && "currency" in value && "scale" in value) {
                    return dinero(value);
                } else if (key.endsWith("date") || key.endsWith("Date")) {
                    try {
                        return new Date(value)
                    } catch (err) {
                        //TODO: report errors and replace console error
                        console.error(err)
                        return value
                    }
                } else {
                    return value;
                }
            }));
        }
    }, []);
    useEffect(() => {
        localStorage.setItem("persistentState", JSON.stringify(value));
    }, [value]);
    return <persistentStateContext.Provider value={[value, setValue]}>{children}</persistentStateContext.Provider>;
}

export default function usePersistentState<T>(key: PersistentStateKey, defaultValue?: T): [T, (value: T | null) => void] {
    const [{ value: contextValue }, setContextValue] = React.useContext(persistentStateContext);
    return [contextValue[key] ?? defaultValue, value => {
        setContextValue(({ value: oldValue }) => ({ value: { ...oldValue, [key]: value } }));
    }];
}

export function useClearPersistantStates(prefix: string) {
    const [{ value: contextValue }, setContextValue] = React.useContext(persistentStateContext);
    return useCallback(() => {
        setContextValue(({ value: oldContextValue }) => {
            const newContextValue = { ...oldContextValue };
            for (const key in oldContextValue) {
                if (key in newContextValue && key.startsWith(prefix)) {
                    delete newContextValue[key];
                }
            }
            return { value: newContextValue };
        });
    }, [setContextValue]);
}
