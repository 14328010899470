import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Grid,
  Stepper,
  StepLabel,
  Step,
  StepContent
} from "@mui/material";
import EffortlessPTPageWrapper from "../EffortlessPTPageWrapper";
import { CsvFile } from "../CsvFile";
import { ReconciliationPaymentFinalise, RegisterPayments, RegisterPaymentsButton } from "../reconcile-bank-transactions/RegisterPayments";
import ReconciliationIntroduction from "../reconcile-bank-transactions/Introduction";
import BankStatementCsv from "../reconcile-bank-transactions/BankStatementCsv";
import usePersistentState, { useClearPersistantStates } from "../PersistentStateContext";
import BankStatementColumnRoles from "../reconcile-bank-transactions/BankStatementColumnRoles";
import BankStatementMatchClients from "../reconcile-bank-transactions/BankStatementMatchClients";
import { useNavigate, useParams } from "react-router-dom";
import { ReconciliationPayment } from "../reconcile-bank-transactions/data";

export default function ReconcileBankTransactions() {
  const { step } = useParams();
  const [currentStep, setCurrentStep] = usePersistentState("reconcileBankStatement.currentStep", 0);
  const activeStep = Number(step ?? currentStep);
  const navigate = useNavigate();
  const [csvFile] = usePersistentState<CsvFile>("reconcileBankStatement.csvFile");
  const [allColumnRolesSelected] = usePersistentState<boolean>("reconcileBankStatement.allColumnRolesSelected");
  const [paymentsToRegister, setPaymentsToRegister] = usePersistentState<ReconciliationPaymentFinalise[]>("reconcileBankStatement.paymentsToRegister", [])
  const [payments, setPayments] = usePersistentState<ReconciliationPayment[]>("reconcileBankStatement.payments", [])
  const clearAllStates = useClearPersistantStates("reconcileBankStatement");

  useEffect(() => {
    if (activeStep === 5) {
      clearAllStates();
    }
  }, [clearAllStates, activeStep]);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
    navigate(`/reconcile-bank-transactions/${activeStep + 1}`, { replace: true });
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
    navigate(`/reconcile-bank-transactions/${activeStep - 1}`, {replace: true});
  };

  const handleReset = () => {
    clearAllStates();
    navigate(`/reconcile-bank-transactions/0`);
  };

  useEffect(() => {
    if (activeStep !== 5 && activeStep > currentStep) {
      navigate(`/reconcile-bank-transactions/${currentStep}`);
    }
    // Failsafe if the step is above the total number of steps.
    if (activeStep > 5) {
      navigate(`/reconcile-bank-transactions/5`);
    }
  }, [activeStep, currentStep]);

  if (activeStep !== 5 && activeStep > currentStep) {
    return null;
  }

  return (
    <EffortlessPTPageWrapper title={'Reconcile Bank Transaction Payments'}>
      <form>
        <Grid container spacing={2} padding={'5px'}>
          <Grid item xs={12}>
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep} orientation={"vertical"}>
                <Step key={"export-instructions"}>
                  <StepLabel>Export Transactions as CSV</StepLabel>
                  <StepContent>
                    {activeStep === 0 && <ReconciliationIntroduction />}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button onClick={handleNext}>Next</Button>
                    </Box>
                  </StepContent>
                </Step>
                <Step key={"import-csv"}>
                  <StepLabel>Import the CSV File</StepLabel>
                  <StepContent>
                    {activeStep === 1 && <BankStatementCsv />}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button onClick={handleBack} sx={{ mr: 1 }}>Back</Button>
                      <Button disabled={!csvFile} onClick={handleNext}>Next</Button>
                    </Box>
                  </StepContent>
                </Step>
                <Step key={"select-column-roles"}>
                  <StepLabel>Select CSV Column Roles</StepLabel>
                  <StepContent>
                    {activeStep === 2 && <BankStatementColumnRoles />}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button onClick={handleBack} sx={{ mr: 1 }}>Back</Button>
                      <Button onClick={handleNext} disabled={!allColumnRolesSelected}>Next</Button>
                    </Box>
                  </StepContent>
                </Step>
                <Step key={"match-transactions"}>
                  <StepLabel>Match Transactions with Client Sessions</StepLabel>
                  <StepContent>
                    {activeStep === 3 && <BankStatementMatchClients />}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button onClick={() => {
                        setPayments([])
                        handleBack()
                      }} sx={{ mr: 1 }}>Back</Button>
                      <Button
                        disabled={payments?.some(payment => payment.shouldCreatePayment && !payment.matchedClient)}
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    </Box>
                  </StepContent>
                </Step>
                <Step key={"review-and-finalise"}>
                  <StepLabel>Review and Finalise Payments</StepLabel>
                  <StepContent>
                    {activeStep === 4 && <RegisterPayments />}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button onClick={() => {
                        setPaymentsToRegister([])
                        handleBack()
                      }} sx={{ mr: 1 }}>Back</Button>
                      <RegisterPaymentsButton text="Finish" onDone={handleNext} />
                    </Box>
                  </StepContent>
                </Step>
              </Stepper>
              {activeStep === 5 &&
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={() => navigate(-1)}>Back to Dashboard</Button>
                  <Button onClick={handleReset}>Start Over</Button>
                </Box>
              }
            </Box>
          </Grid>
        </Grid>
      </form>
    </EffortlessPTPageWrapper>
  );
}
