import { ArrowBack, Delete, PersonAdd, PersonRemove, Remove } from '@mui/icons-material';
import {
    Autocomplete,
    Backdrop,
    Button,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormControlLabel, FormLabel,
    Grid, Radio, RadioGroup,
    Stack, TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Session, SessionClient } from '../../../data/session';
import DataTable from '../DataTable';
import { formatDate } from '../date';
import { useClients, usePayments, useSessions, useSettings } from '../EffortlessPTContext';
import EffortlessPTPageWrapper from '../EffortlessPTPageWrapper';
import paymentMethodToString from '../PaymentMethodToString';
import { Client } from "../../../data/client";
import { PaymentMethod } from "../../../data/payment";
import { EditableDate } from './EditableDate';
import { preparePhoneForRendering } from '../phoneHelper';
import { resolve } from 'path';
import DateHeading from '../DateHeading';

export default function SessionDetails() {
    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    const { id } = useParams();
    const navigate = useNavigate();
    const { getSession, deleteSession, removeClientFromClosedSession, addClientToClosedSession, changeClosedSessionDate } = useSessions();
    const { settings, lookupSessionType } = useSettings();
    const { payments, getClientSessionPayments } = usePayments();
    const [session, setSession] = useState<Session | null>(null);

    async function updateSession(sessionId: string) {
        return new Promise((resolve, reject) => {
            // TODO: Handle error better.
            getSession(sessionId, session => {
                setSession(session);
                resolve(session);
            }, error => {
                console.error(error);
                reject(error);
            });
        });
    }

    useEffect(() => {
        if (id) {
            updateSession(id);
        }
    }, [id]);

    const { clients } = useClients();

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [confirmRemoveClientOpen, setConfirmRemoveClientOpen] = useState(false);
    const [clientToRemove, setClientToRemove] = useState<SessionClient | null>(null);

    function removeClient(client: SessionClient) {
        setClientToRemove(client);
        setConfirmRemoveClientOpen(true);
    }

    async function isPassFull(clientId: string, paymentId: string) {
        return new Promise<boolean>((resolve, reject) => {
            getClientSessionPayments(clientId, (sessionPayments) => {
                //search the payments for the payment id looking for SessionPayment records with no sessionId
                const countSessionPaymentsLeft = sessionPayments.results.filter((sessionPayment) => {
                    return sessionPayment.paymentId === paymentId
                        && sessionPayment.state === "PaymentInAdvance";
                }).length;
                resolve(countSessionPaymentsLeft === 0);
            }, error => {
                console.error(error);
                reject(error);
            });
        });
    }

    const [clientToAdd, setClientToAdd] = useState<Client | null>(null);
    const [clientToAddPaymentMethod, setClientToAddPaymentMethod] = useState<PaymentMethod>("EFT");
    const [addingClient, setAddingClient] = useState(false);
    async function addClient() {
        //Adding client ${clientToAdd?.name} with payment method ${clientToAddPaymentMethod} to session
        if (!session || !session.id) {
            throw new Error("Session not loaded");
        }
        if (!clientToAdd || !clientToAdd.id) {
            throw new Error("Client not selected");
        }

        let paymentId: string | undefined; // If the client has a pass, automatically add the session to their pass. If not, just register their attendance to the session with no payment.
        const mostRecentPaymentForSessionType = getMostRecentPaymentForSessionType(clientToAdd.id, session.sessionTypeId);
        if (mostRecentPaymentForSessionType && mostRecentPaymentForSessionType.pass && mostRecentPaymentForSessionType.id) {
            //check if the pass has sessions left
            const passIsFull = await isPassFull(clientToAdd.id, mostRecentPaymentForSessionType.id);
            if (passIsFull) {
                paymentId = undefined;
            } else {
                paymentId = mostRecentPaymentForSessionType.id;
            }
        } else {
            paymentId = undefined;
        }

        setAddingClient(true);
        const sessionId = session.id;
        addClientToClosedSession(sessionId, {
            clientId: clientToAdd.id,
            paymentMethod: clientToAddPaymentMethod,
            paymentId,
        }, async () => {
            setAddingClient(false);
            updateSession(sessionId);
        }, error => {
            console.log(`${error} adding session for ${clientToAdd.name}`)
            setAddingClient(false);
        });
    }

    function getMostRecentPaymentForSessionType(clientId: string, sessionTypeId: string) {
        //sort descending by date so we find the most recent payment first
        const sortedPayments = payments.sort(
            (a, b) => (a.date || new Date()).getTime() - (b.date || new Date()).getTime()
        );
        const paymentForSessionType = sortedPayments.find((payment) => {
            return payment.paymentFor.find((paymentFor) => {
                return paymentFor.clientId === clientId && paymentFor.sessionTypeId === sessionTypeId;
            });
        });
        return paymentForSessionType;
    }

    function updateClientToAdd(client: Client | null) {
        if (client && client.id && payments && session) {
            const paymentForSessionType = getMostRecentPaymentForSessionType(client.id, session.sessionTypeId);
            if (paymentForSessionType && paymentForSessionType.pass) {
                setClientToAddPaymentMethod(`pass:${paymentForSessionType.pass.passTypeId}`);
            } else if (paymentForSessionType?.method) {
                setClientToAddPaymentMethod(paymentForSessionType?.method);
            }
        }
        setClientToAdd(client);
    }

    if (session) {
        const sessionType = settings.sessionTypes.find((sessionType) => sessionType.id === session.sessionTypeId);

        return (
            <EffortlessPTPageWrapper title="Session details">
                <Grid container spacing={2} padding='5px'>
                    <Grid item xs={12}>
                        <DateHeading session={session} changeDate={(date, success) => {
                            changeClosedSessionDate(session.id!, date, () => {
                                updateSession(session.id!);
                                success();
                            }, console.error);
                        }} />
                        <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
                            <DialogTitle>Delete session</DialogTitle>
                            <DialogContent>
                                Are you sure you would like to delete this session? This cannot be undone.
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
                                <Button color="error" onClick={() => {
                                    // TODO: Handle error better.
                                    deleteSession(session.id!, () => navigate(-1), console.error);
                                }}>Delete</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={confirmRemoveClientOpen} onClose={() => setConfirmRemoveClientOpen(false)}>
                            <DialogTitle>Remove Client</DialogTitle>
                            <DialogContent>
                                {`Are you sure you would like to remove ${clientToRemove?.name} from this session? This cannot be undone.`}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setConfirmRemoveClientOpen(false)}>Cancel</Button>
                                <Button color="error" onClick={() => {
                                    // TODO: Handle error better.
                                    removeClientFromClosedSession(session.id!, clientToRemove!.clientId, clientToRemove!.signInId,
                                        () => {
                                            updateSession(session.id!);
                                            setConfirmRemoveClientOpen(false)
                                        },
                                        console.error
                                    )
                                }}>Remove</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable name="Clients" rows={[...session.clients, {
                            clientId: 'new',
                            signInId: '',
                            name: '',
                            phone: '',
                            paymentMethod: 'Cash',
                        } as SessionClient]} columns={[
                            {
                                name: "Name",
                                getter: (client) =>
                                    client.clientId !== 'new'
                                        ?
                                        <Link to={`/edit-client/${client.clientId}`}>{client.name}</Link>
                                        :
                                        <FormControl fullWidth variant="standard">
                                            <Autocomplete
                                                options={clients}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <TextField {...params}
                                                    label="Client"
                                                    required
                                                />}
                                                onChange={(event, newValue) => {
                                                    updateClientToAdd(newValue);
                                                }}
                                            />
                                        </FormControl>

                            },
                            {
                                name: "Phone",
                                getter: (client) => preparePhoneForRendering(
                                    client.clientId !== 'new'
                                        ?
                                        client.phone
                                        :
                                        clientToAdd?.phone ?? ""
                                )
                            },
                            {
                                name: "Payment method",
                                getter: (client) => {
                                    if (client.clientId !== 'new') {
                                        if (client.paymentMethod === "Friend") {
                                            const payingClient = clients.find((c) => c.id === client.payerClientId);
                                            return `Friend: ${payingClient?.name ?? "[unknown]"}`;
                                        } else {
                                            return paymentMethodToString(client.paymentMethod, sessionType);
                                        }
                                    } else {
                                        return <FormControl component="fieldset" disabled={clientToAdd === null}>
                                            <RadioGroup
                                                aria-label="payment method"
                                                name="paymentMethod"
                                                value={clientToAddPaymentMethod ?? ""}
                                                onChange={(event) => {
                                                    const method = event.target.value as PaymentMethod;
                                                    setClientToAddPaymentMethod(method);
                                                }}
                                            >
                                                <FormControlLabel value="EFT" control={<Radio />} label="EFT" />
                                                <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                                                {
                                                    sessionType?.passTypes?.map((passType) => {
                                                        return <FormControlLabel key={passType.id} value={`pass:${passType.id}`} control={<Radio />} label={passType.name} />
                                                    })
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    }
                                }
                            },
                            {
                                name: "Actions",
                                getter: (client) => {
                                    return <Stack justifyContent={"space-between"} direction={"row"}>
                                        {client.clientId !== 'new'
                                            ?
                                            <Button onClick={() => removeClient(client!)} startIcon={<PersonRemove />}>{!isMobile && 'Remove Client'}</Button>
                                            :
                                            <Button disabled={addingClient || clientToAdd === null} onClick={() => addClient()} startIcon={<PersonAdd />}>Add Client</Button>
                                        }
                                    </Stack>
                                }
                            }
                        ]} />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack justifyContent={"space-between"} direction={"row"}>
                            <Button onClick={() => navigate(-1)} endIcon={<ArrowBack />}>Back</Button>
                            <Button variant={"outlined"} color="error"
                                onClick={() => setConfirmDeleteOpen(true)}
                                endIcon={<Delete />}
                            >
                                Delete
                            </Button>

                        </Stack>
                    </Grid>
                </Grid>
            </EffortlessPTPageWrapper>
        )
    } else {
        return null;
    }
}
