import * as React from 'react'
import { Client } from '../../data/client'
import DataTable, { TableColumn } from './DataTable'
import {Box, Button, Grid, Stack, Tooltip, useMediaQuery} from '@mui/material'
import {
  AccountBalance,
  Add,
  Check
} from '@mui/icons-material'
import {useClients, usePayments, useSettings, useStats} from './EffortlessPTContext';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { ButtonPanel } from "./pages/Dashboard";
import { EffortlessPTStats, SessionPaymentCountsBySessionTypeId } from '../../data/stats'
import { preparePhoneForRendering } from './phoneHelper'
import PassIcon from "./icons/PassIcon";
import {getActivePass} from "./client";

function compareClientNames(a: Client, b: Client) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function clientBehindCount(client: Client, stats: EffortlessPTStats) {
  return stats.sessionPaymentCountsBySessionTypeId.flatMap(stat => stat.unpaidClients).filter(unpaidClientId => unpaidClientId === client.id).length;
}

export default function ClientGrid() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { clients } = useClients();
  const { stats } = useStats();
  const { payments } = usePayments();

  const [filter, setFilter] = useState("");

  const columns: Array<TableColumn<Client>> = [
    {
      name: 'Name',
      getter: client => <Link to={`/edit-client/${client.id}`}>{client.name}</Link>
    },
    {
      name: 'Phone',
      getter: client => <a href={`sms:${client.phone}`}>{preparePhoneForRendering(client.phone)}</a>
    },
    {
      name: 'Payments',
      getter: client => {
        const paymentsBehind = clientBehindCount(client, stats);
        const activePass = getActivePass(client, payments);
        let tooltip;
        let isBehind;
        if (paymentsBehind > 0) {
            tooltip = `${paymentsBehind} behind`;
            isBehind = true;
        } else {
            tooltip = "Up to date";
            isBehind = false;
        }
        return <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Tooltip title={tooltip}>
            <Button onClick={() => navigate(`/client-payments/${client.id}`)} endIcon={
              isBehind ?
                  <AccountBalance sx={{ color: 'orange' }}>{paymentsBehind}</AccountBalance>
                  :
                  <Check sx={{color: 'green'}}/>
            }>Payments</Button>
          </Tooltip>
          {activePass && <Button onClick={() => navigate(`/payment-details/pass/${activePass.id}`)}
                           endIcon={<PassIcon sx={{color: 'green'}}/>}>Pass</Button>}
        </Stack>;
      }
    }
  ]

  const { settings } = useSettings();

  const [maxLoaded, setMaxLoaded] = useState(5)
  function getFilteredClients(filterText: string | undefined, maxToReturn: number): { hasMore: boolean, clients: Client[] } {
    let result
    if (filterText) {
      let unpaidSessionTypeStats: SessionPaymentCountsBySessionTypeId[] = [];
      if (/unpaid:.*/i.test(filterText)) {
        const unpaidSessionTypeName = (filterText.match(/unpaid:(\s?.*)/i) ?? [])[1].trim();

        if (unpaidSessionTypeName) {
          const matchedSessionTypes = settings.sessionTypes.filter(sessionType => sessionType.name.toLowerCase().indexOf(unpaidSessionTypeName.toLowerCase()) !== -1);
          for (const sessionPaymentCountsBySessionTypeId of stats.sessionPaymentCountsBySessionTypeId) {
            for (const matchedSessionType of matchedSessionTypes) {
              if (sessionPaymentCountsBySessionTypeId.sessionTypeId === matchedSessionType.id) {
                unpaidSessionTypeStats.push(sessionPaymentCountsBySessionTypeId)
              }
            }
          }
        } else {
          unpaidSessionTypeStats.push(...stats.sessionPaymentCountsBySessionTypeId)
        }

      }
      const filterTextLowerCase = filterText.toLowerCase()
      result = clients.filter(client => {
        for (const unpaidSessionTypeStat of unpaidSessionTypeStats) {
          if (unpaidSessionTypeStat.unpaidClients.some(unpaidClientId => unpaidClientId === client.id)) {
            return true
          }
        }
        if (client.name.toLowerCase().indexOf(filterTextLowerCase) !== -1) {
          return true
        }
        if (client.phone.indexOf(filterTextLowerCase) !== -1) {
          return true
        }
        if (client.eftMatch && client.eftMatch.indexOf(filterTextLowerCase) !== -1) {
          return true
        }
        return false
      })
    } else {
      result = clients
    }
    const sliced = result.sort(compareClientNames).slice(0, maxToReturn);
    return { hasMore: result.length > sliced.length, clients: sliced }
  }

  const [hasMore, setHasMore] = useState(false)
  const [clientsToDisplay, setClientsToDisplay] = useState<Client[]>([])
  function updateFilteredClients(filter: string | undefined, maxToLoad: number) {
    const { hasMore, clients } = getFilteredClients(filter, maxToLoad);
    setHasMore(hasMore)
    setClientsToDisplay(clients)
  }

  useEffect(() => {
    if (clients) {
      updateFilteredClients(filter, maxLoaded)
    }
  }, [clients, filter])

  return (
    <Box>
      <Grid container spacing={{ xs: 1, md: 2 }} padding={{ xs: 0, md: 0 }} direction={"column"}>
        <Grid item columns={12}>
          <ButtonPanel filterTextState={filter} filterChanged={filterText => {
            setFilter(filterText)
          }}>
            <Button onClick={() => navigate('/add-client')} endIcon={<Add />}>Add Client</Button>
          </ButtonPanel>
        </Grid>
        <Grid item columns={12}>
          <DataTable name="Clients" columns={columns} rows={clientsToDisplay}
            hasMore={hasMore}
            onMore={() => {
              const maxToLoad = maxLoaded + 5
              updateFilteredClients(filter, maxToLoad)
              setMaxLoaded(maxToLoad)
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
