import { CalendarMonthOutlined, DescriptionOutlined, HelpOutlined, RemoveOutlined, SavingsOutlined } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, FormGroup, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CsvFile, csvHasHeader, csvInit } from '../CsvFile';
import { dineroFromString } from '../currency';
import { isDate } from '../date';
import IconMenu, { MenuOption } from '../IconMenu';
import usePersistentState from '../PersistentStateContext';

export type ReconciliationColumnRole = "date" | "description" | "amount" | "ignored" | "unknown"
export type ColumnRoles = Array<ReconciliationColumnRole>

function ReconciliationRowCell(props: {
    value: string
    columnRole: ReconciliationColumnRole
}) {
    const { value } = props
    return (
        <Typography>{value}</Typography>
    )
}

const DESCRIPTION_PATTERNS: RegExp[] = [
    /description/ig
]
const DATE_PATTERNS: RegExp[] = [
    /date/ig
]
const AMOUNT_PATTERNS: RegExp[] = [
    /credit/ig
]

const columnRoleIconMenuOptions: MenuOption<ReconciliationColumnRole>[] = [
    {
        value: "date",
        description: "This column is the date of payment.",
        name: "Date",
        icon: <CalendarMonthOutlined color="primary" />
    },
    {
        value: "description",
        description: "This column is the payment description. We will search the description to try to determine which client made the payment.",
        name: "Description",
        icon: <DescriptionOutlined color="primary" />
    },
    {
        value: "amount",
        description: "This column is the payment amount.",
        name: "Amount",
        icon: <SavingsOutlined color="primary" />
    },
    {
        value: "ignored",
        description: "Ignore this column.",
        name: "Ignored",
        icon: <RemoveOutlined />
    }
]

function getReconciliationRoleIcon(columnRole: ReconciliationColumnRole) {
    // To avoid code duplication, we use the icon menu options.
    return columnRoleIconMenuOptions.find(option => option.value === columnRole)?.icon ?? <HelpOutlined />;
}
function getReconciliationRoleLabel(columnRole: ReconciliationColumnRole) {
    return columnRoleIconMenuOptions.find(option => option.value === columnRole)?.name ?? "Select column role";
}

function HeaderCell(props: {
    index: number;
    columnRoles: Array<ReconciliationColumnRole>;
    handleCsvColumnRoleChanged: (role: ReconciliationColumnRole, index: number) => void;
    label?: string;
}) {
    const { index, columnRoles, handleCsvColumnRoleChanged, label } = props;
    const value: ReconciliationColumnRole = columnRoles.length > index ? columnRoles[index] : "unknown";

    return <TableCell key={index}>
        <IconMenu
            icon={getReconciliationRoleIcon(value)}
            label={label}
            onSelected={(newValue) => handleCsvColumnRoleChanged(newValue, index)}
            uniqueKey={`role-menu-${index}`}
            title={getReconciliationRoleLabel(value)!}
            options={columnRoleIconMenuOptions}
        />
    </TableCell>
}

function checkRolesSet(newColumnRoles: Array<ReconciliationColumnRole>) {
    let amountSet = false
    let dateSet = false
    let descriptionSet = false
    for (const newColumnRole of newColumnRoles) {
        switch (newColumnRole) {
            case "date":
                dateSet = true
                break
            case "description":
                descriptionSet = true
                break
            case "amount":
                amountSet = true
                break
        }
    }
    return { amountSet, dateSet, descriptionSet };
}

export default function BankStatementColumnRoles() {
    const [csvFileHasHeaderRow, setCsvFileHasHeaderRow] = useState(true)
    const [columnRoles, setColumnRoles] = usePersistentState<ColumnRoles>("reconcileBankStatement.columnRoles", [])
    const [allColumnRolesSelected, setAllColumnRolesSelected] = usePersistentState<boolean>("reconcileBankStatement.allColumnRolesSelected")
    const [csvFile, setCsvFile] = usePersistentState<CsvFile>("reconcileBankStatement.csvFile")

    function updateColumnRoles(newColumnRoles: Array<ReconciliationColumnRole>) {
        //Check if all the column roles have been selected. If so, ignore other columns.
        let { amountSet, dateSet, descriptionSet } = checkRolesSet(newColumnRoles)
        if (amountSet && descriptionSet && dateSet) {
            for (let i = 0; i < newColumnRoles.length; i++) {
                const newColumnRole = newColumnRoles[i]
                if (newColumnRole === "unknown") {
                    newColumnRoles[i] = "ignored"
                }
            }
            setAllColumnRolesSelected(true)
        } else {
            setAllColumnRolesSelected(false)
        }
        setColumnRoles(newColumnRoles)
    }

    /**
     * Look at the header description and rows to try to guess the column roles.
     */
    function guessColumnRoles(csvFileToGuess: CsvFile) {
        try {
            if (csvFileToGuess) {
                let length
                if (csvFileToGuess.header) {
                    length = csvFileToGuess.header.length
                } else {
                    length = csvFileToGuess.rows[0].cells.length
                }
                let newColumnRoles = new Array<ReconciliationColumnRole>(length)

                //First pass just look at headers
                let amountSetByHeader = new Array<boolean>(length)
                for (let columnIndex = 0; columnIndex < length; columnIndex++) {
                    let role: ReconciliationColumnRole = "unknown"
                    if (csvFileToGuess.header && csvFileToGuess.header.length > columnIndex && csvFileToGuess.header[columnIndex].name) {
                        const headerText = csvFileToGuess.header[columnIndex].name
                        for (const descriptionPattern of DESCRIPTION_PATTERNS) {
                            if (descriptionPattern.test(headerText)) {
                                role = "description"
                            }
                        }
                        for (const datePattern of DATE_PATTERNS) {
                            if (datePattern.test(headerText)) {
                                role = "date"
                            }
                        }
                        for (const amountPattern of AMOUNT_PATTERNS) {
                            if (amountPattern.test(headerText)) {
                                amountSetByHeader[columnIndex] = true
                                role = "amount"
                            }
                        }
                    }
                    newColumnRoles[columnIndex] = role
                }

                //If we didn't match all of the columns by their header values, try to guess using the data type of the row cells for this column
                let { amountSet, dateSet, descriptionSet } = checkRolesSet(newColumnRoles)
                if (!(amountSet && dateSet && descriptionSet)) {
                    for (let columnIndex = 0; columnIndex < length; columnIndex++) {
                        let { dateSet, descriptionSet } = checkRolesSet(newColumnRoles)
                        let role: ReconciliationColumnRole = newColumnRoles[columnIndex]
                        if (role === "unknown") {
                            const rowsToTest = Math.min(csvFileToGuess.rows.length, 2);
                            for (let rowIndex = 0; rowIndex < rowsToTest; rowIndex++) {
                                const row = csvFileToGuess.rows[rowIndex]
                                if (row.cells.length > columnIndex) {
                                    const cell: string = row.cells[columnIndex].value

                                    if (!amountSetByHeader[columnIndex]) {
                                        try {
                                            dineroFromString(cell)
                                            role = "amount"
                                            break
                                        } catch (e) {
                                        }
                                    }

                                    if (!dateSet && isDate(cell)) {
                                        role = "date"
                                        break
                                    }

                                    if (!descriptionSet && rowIndex === rowsToTest - 1) {
                                        if (/^[\s0-9a-zA-Z]+$/.test(cell)) {
                                            role = "description"
                                            break
                                        }
                                    }
                                }
                            }
                        }
                        newColumnRoles[columnIndex] = role
                    }

                    //If more than one column was assigned the amount type, make them all unknown so the user has to choose
                    let amountCount = 0
                    for (let columnIndex = 0; columnIndex < newColumnRoles.length; columnIndex++) {
                        const newColumnRole = newColumnRoles[columnIndex];
                        if (newColumnRole === "amount") {
                            amountCount++
                        }
                    }
                    if (amountCount > 1) {
                        for (let columnIndex = 0; columnIndex < newColumnRoles.length; columnIndex++) {
                            const newColumnRole = newColumnRoles[columnIndex];
                            if (newColumnRole === "amount") {
                                newColumnRoles[columnIndex] = "unknown"
                            }
                        }
                    }

                    //If there are exactly 3 columns, and both amount and date are set, and there is only one column left, make it the description
                    if (newColumnRoles.length === 3) {
                        let { amountSet, dateSet, descriptionSet } = checkRolesSet(newColumnRoles)
                        if (amountSet && dateSet && !descriptionSet) {
                            for (let columnIndex = 0; columnIndex < newColumnRoles.length; columnIndex++) {
                                const workingColumnRole = newColumnRoles[columnIndex];
                                if (workingColumnRole === "unknown") {
                                    newColumnRoles[columnIndex] = "description"
                                }
                            }
                        }
                    }
                }

                updateColumnRoles(newColumnRoles)
            }
        } catch (e) {
            //TODO: show the error somehow
            console.error("error occurred guessing column roles")
            console.error(e)
        }
    }


    function handleCsvColumnRoleChanged(role: ReconciliationColumnRole, index: number) {
        if (csvFile) {
            let length;
            if (csvFile.header) {
                length = csvFile.header.length
            } else {
                length = csvFile.rows[0].cells.length
            }
            let newColumnRole = new Array<ReconciliationColumnRole>(length)
            for (let i = 0; i < length; i++) {
                const columnRole = columnRoles.length > i ? columnRoles[i] : "unknown";
                if (i === index) {
                    newColumnRole[i] = role
                } else if (columnRole === role && columnRole !== "ignored") {
                    newColumnRole[i] = "unknown"
                } else {
                    newColumnRole[i] = columnRole
                }
            }
            updateColumnRoles(newColumnRole)
        }
    }

    function handleCsvFileHasHeaderRowChanged(event: any) {
        const hasHeaderRow: boolean = event.target.checked;
        setCsvFile(csvInit(csvFile.name, csvFile.csvData, hasHeaderRow));
    }

    // Update the column roles and the hasHeaderRow when the CSV file changes.
    useEffect(() => {
        if (csvFile) {
            guessColumnRoles(csvFile)
            setCsvFileHasHeaderRow(csvHasHeader(csvFile))
        }
    }, [csvFile])

    return <React.Fragment>
        <Typography sx={{ mt: 2, mb: 1 }}>Let us know whether there is a header row in your CSV and choose the Date, Amount and Description columns:</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, alignItems: 'left' }}>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={csvFileHasHeaderRow}
                    onChange={handleCsvFileHasHeaderRowChanged} />}
                    label={"Has Header Row"} />
            </FormGroup>
        </Box>

        <TableContainer component={Paper} style={{ minHeight: '300px', width: '100%' }}>
            <table>
                <TableHead>
                    <TableRow>
                        {
                            csvFile.header ?
                                csvFile.header.map((csvHeader, index) => <HeaderCell key={index} index={index} columnRoles={columnRoles} handleCsvColumnRoleChanged={handleCsvColumnRoleChanged} label={csvHeader.name} />)
                                :
                                csvFile.rows[0].cells.map((cell, index) => <HeaderCell key={index} index={index} columnRoles={columnRoles} handleCsvColumnRoleChanged={handleCsvColumnRoleChanged} />)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        csvFile.rows.slice(0, 3).map((row, rowIndex) =>
                            <TableRow key={rowIndex}>
                                {
                                    row.cells.map((cell, cellIndex) =>
                                        <TableCell key={cellIndex}>
                                            <ReconciliationRowCell
                                                value={cell.value}
                                                columnRole={columnRoles.length > cellIndex ? columnRoles[cellIndex] : "unknown"}
                                            />
                                        </TableCell>
                                    )
                                }
                            </TableRow>
                        )
                    }
                    <TableRow key={"elipses"}>
                        {
                            columnRoles.map((role, cellIndex) =>
                                <TableCell key={cellIndex}>...</TableCell>
                            )
                        }
                    </TableRow>
                </TableBody>
            </table>
        </TableContainer>
    </React.Fragment>
}
