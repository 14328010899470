import React from 'react';
import {SvgIcon, SxProps, Theme} from "@mui/material";

export default function PassIcon({sx}:{sx?: SxProps<Theme>}) {
  return (
      <SvgIcon sx={sx}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <rect x="2" y="4" width="20" height="16" rx="2" ry="2" fill="none" stroke="currentColor" strokeWidth="1"/>
                  <path
                      d="M6 9c0.3-0.5 0.6-0.7 0.9-0.5s0.3 0.5 0.6 0.5c0.3 0 0.3-0.3 0.6-0.3s0.3 0.3 0.6 0.3c0.3 0 0.3-0.2 0.5-0.2s0.2 0.2 0.4 0.2c0.3 0 0.3-0.3 0.6-0.3s0.3 0.3 0.6 0.3"
                      fill="none" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
                  <path
                      d="M12 9c0.2 0 0.3-0.2 0.5-0.2s0.3 0.2 0.5 0.2c0.2 0 0.3-0.1 0.4-0.1s0.2 0.1 0.4 0.1c0.3 0 0.3-0.2 0.5-0.2s0.2 0.2 0.4 0.2c0.3 0 0.3-0.3 0.6-0.3s0.3 0.3 0.6 0.3c0.3 0 0.3-0.2 0.5-0.2s0.2 0.2 0.4 0.2"
                      fill="none" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
                  <line x1="6" y1="10" x2="18" y2="10" stroke="black" strokeWidth="0.2"/>

                  <circle cx="6" cy="13" r="1.25" fill="#4CAF50"/>
                  <path d="M5.4 13L5.9 13.5 6.8 12.6" stroke="white" strokeWidth="0.3" fill="none"/>

                  <circle cx="9" cy="13" r="1.25" fill="#4CAF50"/>
                  <path d="M8.4 13L8.9 13.5 9.8 12.6" stroke="white" strokeWidth="0.3" fill="none"/>

                  <circle cx="12" cy="13" r="1.25" fill="#4CAF50"/>
                  <path d="M11.4 13L11.9 13.5 12.8 12.6" stroke="white" strokeWidth="0.3" fill="none"/>

                  <circle cx="15" cy="13" r="1.25" fill="#4CAF50"/>
                  <path d="M14.4 13L14.9 13.5 15.8 12.6" stroke="white" strokeWidth="0.3" fill="none"/>

                  <circle cx="18" cy="13" r="1.25" fill="#4CAF50"/>
                  <path d="M17.4 13L17.9 13.5 18.8 12.6" stroke="white" strokeWidth="0.3" fill="none"/>

                  <circle cx="6" cy="16" r="1.25" fill="#4CAF50"/>
                  <path d="M5.4 16L5.9 16.5 6.8 15.6" stroke="white" strokeWidth="0.3" fill="none"/>

                  <circle cx="9" cy="16" r="1.25" fill="#4CAF50"/>
                  <path d="M8.4 16L8.9 16.5 9.8 15.6" stroke="white" strokeWidth="0.3" fill="none"/>

                  <circle cx="12" cy="16" r="1.25" fill="#9E9E9E"/>
                  <path d="M11.8 15.4Q12 15 12.2 15.1Q12.4 15.2 12.2 15.6Q12.15 15.7 12.15 15.8L12.15 15.9"
                        stroke="white" strokeWidth="0.2" fill="none"/>
                  <circle cx="12.15" cy="16.3" r="0.1" fill="white"/>

                  <circle cx="15" cy="16" r="1.25" fill="#9E9E9E"/>
                  <path d="M14.8 15.4Q15 15 15.2 15.1Q15.4 15.2 15.2 15.6Q15.15 15.7 15.15 15.8L15.15 15.9"
                        stroke="white" strokeWidth="0.2" fill="none"/>
                  <circle cx="15.15" cy="16.3" r="0.1" fill="white"/>

                  <circle cx="18" cy="16" r="1.25" fill="#9E9E9E"/>
                  <path d="M17.8 15.4Q18 15 18.2 15.1Q18.4 15.2 18.2 15.6Q18.15 15.7 18.15 15.8L18.15 15.9"
                        stroke="white" strokeWidth="0.2" fill="none"/>
                  <circle cx="18.15" cy="16.3" r="0.1" fill="white"/>
              </svg>
      </SvgIcon>
);
};