const papa = require('papaparse');

export interface CsvCell {
  value: string;
  columnIndex: number;
}

export interface CsvRow {
  index: number;
  cells: CsvCell[];
}

export interface CsvHeaderCell {
  name: string;
  index: number;
}

export interface CsvFile {
  header?: CsvHeaderCell[];
  rows: CsvRow[];

  name: string;
  csvData: string;
  hasHeaderRowSetting?: boolean;
}

export function csvInit(name: string, csvData: string, hasHeaderRowSetting?: boolean) {
  const csvFile: CsvFile = {
    csvData,
    name,
    hasHeaderRowSetting,
    rows: []
  };
  loadCsvData(csvFile, csvData);
  return csvFile;
}

export function loadCsvData(csvFile: CsvFile, csvData: string) {
  let parsedCsv: { data: string[][] } = papa.parse(csvData, { skipEmptyLines: true });

  let readHeaderRow: boolean
  if (csvFile.hasHeaderRowSetting === undefined) {
    readHeaderRow = guessWhetherRowIsHeader(parsedCsv.data[0])
  } else {
    readHeaderRow = csvFile.hasHeaderRowSetting
  }

  parsedCsv = cleanParsedCsv(readHeaderRow, parsedCsv)

  let rowsStartFrom
  if (readHeaderRow) {
    csvFile.header =
      parsedCsv.data[0]
        .map((value, index) => {
          return {
            index,
            name: value,
            type: "text"
          }
        })

    rowsStartFrom = 1
  } else {
    csvFile.header = undefined;
    rowsStartFrom = 0
  }
  csvFile.rows = parsedCsv.data
    .splice(rowsStartFrom)
    .map((columnData, rowIndex) => {
      return {
        index: rowIndex,
        cells: columnData.map((value, index) => <CsvCell>{ value, columnIndex: index })
      }
    })
}

function guessWhetherRowIsHeader(firstRowData: string[]) {
  for (const cell of firstRowData) {
    if (cell.trim().length > 0 && /^[\s0-9a-zA-Z]+$/.test(cell) === false) {
      return false
    }
  }
  return true
}

export function csvHasHeader(csvFile: CsvFile) {
  if (csvFile.header) {
    return true
  } else {
    return false
  }
}

function cleanParsedCsv(hasHeader: boolean, parsedCsv: { data: string[][] }): { data: string[][] } {
  let result = {
    data: new Array<string[]>()
  }
  if (hasHeader) {
    let header = parsedCsv.data[0]
    while (header[header.length - 1].trim().length === 0) {
      header = header.slice(0, header.length - 1)
    }
    result.data.push(header)
    for (let rowIndex = 1; rowIndex < parsedCsv.data.length; rowIndex++) {
      let row = parsedCsv.data[rowIndex]
      row = row.slice(0, header.length)
      result.data.push(row)
    }
  } else {
    let minEmptyTrailingColumns = parsedCsv.data[0].length
    for (let rowIndex = 0; rowIndex < parsedCsv.data.length; rowIndex++) {
      let row = parsedCsv.data[rowIndex]
      const emptyTrailingColumns = countEmptyTrailingColumns(row)
      minEmptyTrailingColumns = Math.min(minEmptyTrailingColumns, emptyTrailingColumns)
    }
    for (let rowIndex = 0; rowIndex < parsedCsv.data.length; rowIndex++) {
      let row = parsedCsv.data[rowIndex];
      row = row.slice(0, row.length - minEmptyTrailingColumns)
      result.data.push(row)
    }
  }
  return result
}

function countEmptyTrailingColumns(row: string[]): number {
  let emptyCount = 0
  for (let columnIndex = row.length - 1; columnIndex >= 0; columnIndex--) {
    const cell = row[columnIndex]
    if (cell.trim().length == 0) {
      emptyCount++
    } else {
      break
    }
  }
  return emptyCount
}
