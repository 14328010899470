export default function createRedirectUrlFromTemplate(templateUrl: string, date: Date, phone: string, name: string) {
    return encodeURI(
        templateUrl.replaceAll('{name}', name)
            .replaceAll('{phone}', phone)
            .replaceAll('{hour}', date.getHours().toString().padStart(2, '0'))
            .replaceAll('{minute}', date.getMinutes().toString().padStart(2, '0'))
            .replaceAll('{year}', date.getFullYear().toString())
            .replaceAll('{month}', (date.getMonth() + 1).toString().padStart(2, '0'))
            .replaceAll('{day}', date.getDate().toString().padStart(2, '0'))
    );
}