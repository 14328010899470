import {Client} from "../../data/client";
import {Payment} from "../../data/payment";

export const getActivePass = (client?: Client, payments?: Payment[]): Payment | undefined => {
    if (client && payments) {
        const sortedPassPaymentsForClient = payments
            .filter(payment =>
                payment.paymentFor.length !== 0
                && payment.paymentFor[0].clientId === client.id
                && payment.paymentType === "Pass"
            )
            .sort((a, b) =>
                a.date.getTime() - b.date.getTime()
            );
        return sortedPassPaymentsForClient.pop();
    } else {
        return undefined;
    }
}
