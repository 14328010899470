import * as React from 'react'
import App from './App'
import { Auth0Context, Auth0Provider } from '@auth0/auth0-react'
import CssBaseline from '@mui/material/CssBaseline'
import Banner, { BannerContextProvider } from './banner'
import { createRoot } from 'react-dom/client'
import { EffortlessPtContextProvider } from './EffortlessPTContext'
import { Box, createTheme, ThemeProvider } from "@mui/material";
import Welcome from "./pages/Welcome";
import { HashRouter, Route, Routes } from 'react-router-dom'
import ClientSelfSignIn from './pages/ClientSelfSignIn'
import { PersistentStateContextProvider } from './PersistentStateContext'

const container = document.getElementById('root')
const root = createRoot(container!)

export type EffortlessPTEnvironmentType = 'prod' | 'dev' | 'branch' | 'local'
export const EFFORTLESS_PT_ENVIRONMENT = process.env.REACT_APP_EFFORTLESS_PT_ENV || 'prod' as EffortlessPTEnvironmentType

let appBar
switch (EFFORTLESS_PT_ENVIRONMENT) {
  //TODO: Uncomment after demo
  // case 'dev': {
  //     appBar = '#ce93d8'
  //     break
  // }
  // case 'branch': {
  //     appBar = '#6e0b31'
  //     break
  // }
  // case 'local': {
  //     appBar = '#ffa726'
  //     break
  // }
  default: {
    appBar = undefined
    break
  }
}
const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: appBar
        }
      }
    }
  }
});

root.render(
  <ThemeProvider theme={theme}>
    <HashRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
        responseType="token"
        cacheLocation="localstorage"
      >
        <CssBaseline />
        <PersistentStateContextProvider>
        <BannerContextProvider>
          <Banner />
          <Auth0Context.Consumer>
            {({ isAuthenticated, isLoading }) => {
              if (isAuthenticated) {
                return (
                  <EffortlessPtContextProvider>
                    <App />
                  </EffortlessPtContextProvider>
                )
              } else if (!isLoading) {
                return (
                  <Routes>
                    <Route index element={<Welcome />} />
                    <Route path="client-sign-in/:trainerPath" element={<ClientSelfSignIn />} />
                    <Route path="*" element={<Welcome />} />
                  </Routes>
                );
              } else {
                return null;
              }
            }}
          </Auth0Context.Consumer>
          </BannerContextProvider>
          </PersistentStateContextProvider>
      </Auth0Provider>
    </HashRouter>
  </ThemeProvider>
)
