import { Button, Grid, TextField } from '@mui/material';
import * as React from 'react';
import { Client } from '../../../data/client';
import { Payment } from '../../../data/payment';
import { Session } from '../../../data/session';
import { useClients, usePayments, useSessions, useSettings, useStats } from '../EffortlessPTContext';
import EffortlessPTPageWrapper from '../EffortlessPTPageWrapper';
import { expiryDateFromExpiryDays } from './RegisterPayment';

export default function SampleData() {
    const { settings: { sessionTypes }, lookupSessionType } = useSettings();
    const { clients, createClients } = useClients();
    const { registerPayment } = usePayments();
    const { registerSession } = useSessions();
    const { stats, refreshStats } = useStats();

    const [clientsText, setClientsText] = React.useState('');

    const [generating, setGenerating] = React.useState(false);

    return (
        <EffortlessPTPageWrapper title="Add sample data">
            <Grid container spacing={2} padding={1}>
                <Grid item xs={12}>
                    <form>
                        <TextField label="Clients JSON" value={clientsText} onChange={event => setClientsText(event.target.value)} />
                        <Button onClick={() => {
                            createClients(JSON.parse(clientsText), response => {
                                setClientsText('');
                            }, console.error)
                        }}>Add clients</Button>
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={() => {
                        setGenerating(true);
                        const sessions: Session[] = [];
                        const startDate = new Date();
                        startDate.setMonth(startDate.getMonth() - 3);
                        startDate.setHours(7);
                        startDate.setMinutes(0);
                        startDate.setSeconds(0);
                        startDate.setMilliseconds(0);
                        if (sessionTypes.length !== 2) {
                            throw new Error('Expected 2 session types');
                        }
                        const clientsForSessionType = [clients.slice(0, clients.length / 2), clients.slice(clients.length / 2, clients.length)];
                        for (let i = 0; i < 30; i++) {
                            const sessionTypeIndex = Math.random() < 0.5 ? 0 : 1;
                            const sessionType = sessionTypes[sessionTypeIndex];
                            const sessionTypeId = sessionType.id;
                            const clientCount = sessionType.individual ? 1 : Math.floor(Math.random() * 17 + 3);
                            let sessionDate = new Date(startDate.getTime() + 1000 * 60 * 90 * Math.floor(Math.random() * 16 * 90));
                            if (sessionDate.getHours() > 13 || sessionDate.getHours() < 7) {
                                sessionDate.setHours(sessionDate.getHours() % 6 + 7);
                            }
                            const clients: Client[] = [];
                            for (let clientIndex = 0; clientIndex < clientCount; clientIndex++) {
                                clients.push(clientsForSessionType[sessionTypeIndex][Math.floor(Math.random() * clientsForSessionType[sessionTypeIndex].length)]);
                            }
                            sessions.push({
                                sessionTypeId,
                                clients: clients.map(client => ({
                                    clientId: client.id!,
                                    name: client.name,
                                    phone: client.phone,
                                    paymentMethod: Math.random() < 0.5 ? "EFT" : "Cash",
                                    signInId: crypto.randomUUID(),
                                    signature: {
                                        date: new Date(),
                                        device: '',
                                        ip: '',
                                        selfSigned: true
                                    }
                                })),
                                date: sessionDate,
                                state: "Complete",
                                trainerId: ''
                            });
                        }
                        (async () => {
                            for (const session of sessions) {
                                await new Promise((resolve, reject) => {
                                    registerSession(session, resolve, reject);
                                })
                            }
                            setGenerating(false);
                            refreshStats();
                        })();
                    }}
                        disabled={generating}
                    >Generate random sessions</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={() => {
                            setGenerating(true);
                            const payments: Payment[] = [];
                            stats.sessionPaymentCountsBySessionTypeId.forEach(sessionCountsForSessionType => {
                                const sessionTypeId = sessionCountsForSessionType.sessionTypeId;
                                const sessionType = lookupSessionType(sessionTypeId);
                                const unpaidCountForClient: any = {};
                                sessionCountsForSessionType.unpaidClients.forEach(clientId => unpaidCountForClient[clientId] = (unpaidCountForClient[clientId] ?? 0) + 1);
                                for (const clientId in unpaidCountForClient) {
                                    let remainingSessionsOwed = unpaidCountForClient[clientId];
                                    const paymentDate = new Date(Date.now() - 1000 * 60 * 60 * 24 * 90 * Math.random());
                                    const maxFinalSessionsOwed = Math.floor(Math.random() * 3);
                                    while (remainingSessionsOwed > maxFinalSessionsOwed) {
                                        if (sessionType?.passTypes && sessionType.passTypes.length > 0 && Math.random() < 0.25) {
                                            const passType = sessionType.passTypes[0];
                                            payments.push({
                                                date: paymentDate,
                                                amount: passType.price,
                                                paymentType: "Pass",
                                                method: Math.random() < 0.5 ? "EFT" : "Cash",
                                                paymentFor: [
                                                    {
                                                        clientId,
                                                        sessionTypeId,
                                                        expiryDate: expiryDateFromExpiryDays(new Date(), passType),
                                                        sessionCount: passType.sessionCount
                                                    }
                                                ],
                                                pass: {
                                                    passTypeId: passType.id
                                                }
                                            })
                                            remainingSessionsOwed -= passType.sessionCount;
                                        } else {
                                            const sessionCount = Math.floor(Math.random() * 5) + 1;
                                            payments.push({
                                                date: paymentDate,
                                                amount: sessionCount * sessionType!.price,
                                                paymentType: "Session",
                                                method: Math.random() < 0.5 ? "EFT" : "Cash",
                                                paymentFor: [
                                                    {
                                                        clientId,
                                                        sessionTypeId,
                                                        sessionCount
                                                    }
                                                ]
                                            })
                                            remainingSessionsOwed -= sessionCount;
                                        }
                                    }
                                }
                            });
                            (async () => {
                                console.log(`Registering ${payments.length} payments`);
                                let registeredPayments = 0;
                                for (const payment of payments) {
                                    await new Promise((resolve, reject) => {
                                        registerPayment(payment, resolve, reject);
                                    });
                                    registeredPayments++;
                                    console.log(`Registered ${registeredPayments} payments`);
                                }
                                console.log("Finally done!");
                                setGenerating(false);
                                refreshStats();
                            })();
                        }}
                        disabled={generating}>
                        Generate random payments
                    </Button>
                </Grid>
            </Grid >
        </EffortlessPTPageWrapper >
    )
}
