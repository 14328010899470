// payment-success?session_id=cs_test_a1ighyE2pSbo3NWKRtQ9fx7skK3l9e3mcb53bH0TxXVmwJjzhtaSaQUFP7
import * as React from 'react'
import { Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

export default function PaymentSuccess () {
  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('session_id')
  return (
        <Typography>
            Payment Successful (Session id: {sessionId})!
        </Typography>
  )
}
