import { useCallback, useEffect, useState } from "react";
import { SessionClient, SessionInfo, SessionType } from "../../data/session";
import { ApiError, callApi, isApiError, useApi } from "./UseApi";
import { preparePhoneForAPI } from "./phoneHelper";

export function useOpenSessionInfo(trainerPath: string) {
    const getOpenSessionInfoApi = useApi("/session/info", "GET");
    const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(null);
    const [error, setError] = useState<ApiError | null>(null);
    useEffect(() => {
        (async () => {
            const response = await callApi<SessionInfo>(getOpenSessionInfoApi, {}, `path=${encodeURIComponent(trainerPath)}`, false);
            if (!isApiError(response)) {
                setSessionInfo(response);
            } else {
                setError(response);
            }
        })();
    }, [trainerPath]);
    return { sessionInfo, error };
}
export function useSubmitSessionClient(trainerPath: string) {
    const submitSessionClientApi = useApi("/session/client", "POST");
    return useCallback((rawSessionClient: SessionClient, success: () => void, error: (error: ApiError) => void) => {
        const sessionClient = { ...rawSessionClient, phone: preparePhoneForAPI(rawSessionClient.phone) };
        (async () => {
            const response = await callApi<boolean>(submitSessionClientApi, { body: JSON.stringify(sessionClient) }, `path=${encodeURIComponent(trainerPath)}`, false);
            if (!isApiError(response)) {
                success();
            } else {
                error(response);
            }
        })();
    }, [trainerPath]);
}
