import * as React from 'react'
import { Session } from '../../data/session'
import DataTable, { TableColumn } from './DataTable'
import { Box, Button, Grid, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { AccessTime, Add, DisabledByDefault, FilterAlt, Group, QrCode, Restore } from '@mui/icons-material'
import { useSessions, useSettings } from './EffortlessPTContext';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate, getSessionDate } from './date'
import { ChangeEvent, useEffect, useState } from "react";
import { Payment } from "../../data/payment";
import { ButtonPanel } from "./pages/Dashboard";
import { FilterInput } from "./FilterInput";
import IconContainer from './IconContainer'
import { canCompleteSession } from './pages/ActiveSession'
import ActiveSessionButton from './ActiveSessionButton'

function compareSessionDates(b: Session, a: Session) {
  if (a.date < b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return 1;
  }
  return 0;
}


const columns: Array<TableColumn<Session>> = [
  {
    name: 'Date',
    getter: session => <Link to={session.state === "Complete" ? `/session-details/${session.id}` : `/active-session/${session.id}`}>
      <Stack direction="row" spacing={1} alignItems="center" justifyItems="center">
        <Typography>{formatDate(session.date)}</Typography>
        {session.state === "Open" && <IconContainer name='in progress'><Restore /></IconContainer>}
      </Stack>
    </Link>
  },
  {
    name: 'Clients',
    getter: session => session.clients.map(sessionClient => sessionClient.name).join(', ')
  }
]

export default function SessionGrid() {
  let navigate = useNavigate();
  const { settings, lookupSessionType } = useSettings();
  const { sessions } = useSessions();

  const [filter, setFilter] = useState("");

  const [maxLoaded, setMaxLoaded] = useState(5)
  function getFilteredSessions(filterText: string | undefined, maxToReturn: number): { hasMore: boolean, sessions: Session[] } {
    let result
    if (filterText) {
      const filterTextLowerCase = filterText.toLowerCase()
      result = sessions.filter(session => {
        if (formatDate(session.date).indexOf(filterText) !== -1) {
          return true;
        }
        let clientNames = "";
        for (const client of session.clients) {
          clientNames += client.name.toLowerCase()
        }
        if (clientNames.indexOf(filterTextLowerCase) !== -1) {
          return true
        }
        return false
      })
    } else {
      result = sessions
    }
    const sliced = result.sort(compareSessionDates).slice(0, maxToReturn);
    return { hasMore: result.length > sliced.length, sessions: sliced }
  }

  const [hasMore, setHasMore] = useState(false)
  const [sessionsToDisplay, setSessionsToDisplay] = useState<Session[]>([])
  function updateFilteredSessions(filter: string | undefined, maxToLoad: number) {
    const { hasMore, sessions } = getFilteredSessions(filter, maxToLoad);
    setHasMore(hasMore)
    setSessionsToDisplay(sessions)
  }

  useEffect(() => {
    if (sessions) {
      updateFilteredSessions(filter, maxLoaded)
    }
  }, [sessions, filter])

  return (
    <Box>
      <Grid container spacing={{ xs: 1, md: 2 }} padding={{ xs: 0, md: 0 }} direction={"column"}>
        <Grid item columns={12}>
          <ButtonPanel filterTextState={filter} filterChanged={filterText => {
            setFilter(filterText)
          }}>
            <ActiveSessionButton />
            <Button onClick={() => navigate(`/sign-in-sheet`)} endIcon={<QrCode />}>Sign-In Sheet</Button>
          </ButtonPanel>
        </Grid>
        <Grid item columns={12}>
          <DataTable name="Sessions" columns={columns} rows={sessionsToDisplay}
            hasMore={hasMore}
            onMore={() => {
              const maxToLoad = maxLoaded + 5
              updateFilteredSessions(filter, maxToLoad)
              setMaxLoaded(maxToLoad)
            }}

          />
        </Grid>
      </Grid>
    </Box>
  )
}
